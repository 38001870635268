import { Injectable } from '@angular/core';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { isEmpty, pickBy } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import {
    CustomerExpenseSettings,
    ICustomerExpenseSettingsRequestUpdate
} from '../../models';
import { User } from '@appRoot/core/user/models';
import { CustomerExpenseSettingsHttpService } from '../../services/customer-expense-settings-http.service';
import { UserService } from '@appRoot/core/user/services/user.service';
import * as customerExpenseSettingsActions from '../actions/customer-expense-settings.actions';
import * as customerExpenseSettingsSelectors from '../selectors/customer-expense-settings.selectors';


@Injectable()
export class CustomerExpenseSettingsEffects {

    constructor(
        private actions$: Actions,
        private httpService: CustomerExpenseSettingsHttpService,
        private userService: UserService,
        private store: Store<any>,
    ) {}

    @Effect()
    load$: Observable<Action> = this.actions$.pipe(
        ofType(customerExpenseSettingsActions.ActionTypes.LOAD),
        withLatestFrom(
            this.userService.activeUser$,
            this.store.select(customerExpenseSettingsSelectors.getLoaded),
            this.store.select(customerExpenseSettingsSelectors.getData)
        ),
        filter(([action, user, loaded, data]: [customerExpenseSettingsActions.Load, User, boolean, CustomerExpenseSettings]) => !!user),
        mergeMap(([action, user, loaded, data]) => {
            if (loaded) {
                return [
                    new customerExpenseSettingsActions.LoadSuccess(data),
                ];
            }


            return this.httpService.fetch({ uid: user.id }).pipe(
                switchMap(response => {
                    return [
                        new customerExpenseSettingsActions.LoadSuccess(response),
                    ];
                }),
                catchError(error => [
                    new customerExpenseSettingsActions.Error(new NGRXError(action, error)),
                    new customerExpenseSettingsActions.LoadFailed
                ]),
            );
        })
    );

    @Effect()
    update$: Observable<Action> = this.actions$.pipe(
        ofType(customerExpenseSettingsActions.ActionTypes.UPDATE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerExpenseSettingsActions.Update, User]) => !!user),
        switchMap(([action, user]) => {
            let data: ICustomerExpenseSettingsRequestUpdate = {};

            if (action instanceof customerExpenseSettingsActions.Update) {
                data.use_median = (action.payload.settings.use_median !== action.payload.changes.settings.use_median)
                    ? action.payload.changes.settings.use_median
                    : undefined;
                data.max_disc_perc = action.payload.changes.settings.max_disc_perc && (action.payload.settings.max_disc_perc !== action.payload.changes.settings.max_disc_perc)
                    ? action.payload.changes.settings.max_disc_perc
                    : undefined;
                data.min_markup_perc = action.payload.changes.settings.min_markup_perc && (action.payload.settings.min_markup_perc !== action.payload.changes.settings.min_markup_perc)
                    ? action.payload.changes.settings.min_markup_perc
                    : undefined;
            }

            const cleanedData = pickBy(data, v => v !== undefined);
            if (isEmpty(cleanedData)) {
                return [new customerExpenseSettingsActions.UpdateFailed];
            }

            return this.httpService.update({uid: user.id}, cleanedData).pipe(
                switchMap((response) => {
                    let settings = new CustomerExpenseSettings(response);

                    return [
                        new customerExpenseSettingsActions.UpdateSuccess({ settings: settings }),
                    ];
                }),
                catchError(error => [
                    new customerExpenseSettingsActions.Error(new NGRXError(action, error)),
                    new customerExpenseSettingsActions.UpdateFailed,
                ])
            );
        }),
    );
}