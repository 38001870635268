import { AfterViewInit, Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';


@Component({
    selector: 'app-preloader',
    templateUrl: 'preloader.component.html',
    styleUrls: ['preloader.component.scss'],
    inputs: ['type', 'overlay', 'class'],
    host: {
        '[class.preloader-shade]': 'overlay',
    }
})
export class PreloaderComponent implements AfterViewInit {
    type: 'sk-cube' | 'sk-fading-circle' = 'sk-cube';
    overlay: boolean = true;
    class: string = '';

    @ViewChild('container', { read: ViewContainerRef, static: true }) _vcr: ViewContainerRef;
    @ViewChild('skCube', { static: true }) skCube: TemplateRef<any>;
    @ViewChild('skFadingCircle', { static: true }) skFadingCircle: TemplateRef<any>;

    constructor() { }

    get template(): TemplateRef<any> {
        switch (this.type) {
            case "sk-cube":
                return this.skCube;

            case "sk-fading-circle":
                return this.skFadingCircle;

            default:
                return this.skCube;
        }
    }

    ngAfterViewInit() {
        this._vcr.createEmbeddedView(this.template);
    }

}
