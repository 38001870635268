import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { NGRXError } from '../../../ngrx-store/models/NGRXError';
import { User } from '../../../user/models';
import { UserService } from '../../../user/services/user.service';
import { IPermissionsGroupRequestStoreParams, IPermissionsGroupRequestUpdateParams } from '../../models';
import { PermissionHttpService } from '../../services/permission-http.service';
import * as permGroupActions from '../actions/permission-group.actions';
import * as permissionActions from '../actions/permission.actions';
import * as permissionSelectors from '../selectors/permission.selectors';

@Injectable()
export class PermissionGroupEffects {

    constructor(
        private store: Store<any>,
        private actions$: Actions,
        private httpService: PermissionHttpService,
        private userService: UserService,
    ) {}

    @Effect()
    load$: Observable<Action> = this.actions$.pipe(
        ofType(permGroupActions.ActionTypes.LOAD),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [permGroupActions.Load, User]) => !!user),
        switchMap(([action, user]) =>
            this.httpService.fetchGroup({uid: user.id}).pipe(
                switchMap(response => {
                    return [
                        new permGroupActions.LoadSuccess({permissionGroups: response}),
                    ];
                }),
                catchError(error => [
                    new permGroupActions.Error(new NGRXError(action, error)),
                    new permGroupActions.LoadFailed,
                ]),
            )),
    );

    @Effect()
    create$: Observable<Action> = this.actions$.pipe(
        ofType(permGroupActions.ActionTypes.CREATE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [permGroupActions.Create, User]) => !!user),
        switchMap(([action, user]) => {
            let request: IPermissionsGroupRequestStoreParams = {
                uid: user.id,
                label: action.payload.permissionGroup.label,
            };
            return this.httpService.storeGroup(request)
            .pipe(
                switchMap(response => {
                    return [
                        new permGroupActions.CreateSuccess({permissionGroup: response}),
                    ];
                }),
                catchError(error => [
                    new permGroupActions.Error(new NGRXError(action, error)),
                ]),
            );
        }),
    );

    @Effect()
    remove$: Observable<Action> = this.actions$.pipe(
        ofType(permGroupActions.ActionTypes.REMOVE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [permGroupActions.Remove, User]) => !!user),
        switchMap(([action, user]) => {
            return this.httpService.destroyGroup(action.payload.permissionGroup.id, {uid: user.id})
            .pipe(
                switchMap((response) => [
                    new permGroupActions.RemoveSuccess({permissionGroup: action.payload.permissionGroup}),
                ]),
                catchError(error => [
                    new permGroupActions.RemoveFailed({permissionGroup: action.payload.permissionGroup}),
                    new permGroupActions.Error(new NGRXError(action, error)),
                ]),
            );
        })
    );

    removeSuccess$: Observable<Action> = this.actions$.pipe(
        ofType(permGroupActions.ActionTypes.REMOVE_SUCCESS),
        switchMap((action: permGroupActions.RemoveSuccess) =>
            this.store.pipe(select(permissionSelectors.getEntitiesByGroup(action.payload.permissionGroup.id)),)
        ),
        filter(permissions => !!permissions.length),
        map(permissions => permissions.map(permission => permission.id)),
        map(ids => new permissionActions.Load({ids: ids})),
    );

    @Effect()
    update$: Observable<Action> = this.actions$.pipe(
        ofType(permGroupActions.ActionTypes.UPDATE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [permGroupActions.Update, User]) => !!user),
        switchMap(([action, user]) => {
            let request: IPermissionsGroupRequestUpdateParams = {
                uid: user.id,
                label: action.payload.permissionGroup.label,
            };
            return this.httpService.updateGroup(action.payload.permissionGroup.id, request)
            .pipe(
                switchMap(response => {
                    return [
                        new permGroupActions.UpdateSuccess({permissionGroup: response})
                    ];
                }),
                catchError(error => [
                    new permGroupActions.Error(new NGRXError(action, error)),
                ]),
            );
        }),
    );

}
