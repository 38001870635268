import { Injectable } from '@angular/core';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { isEmpty, pickBy } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import {
    ICustomerRequestDestroyParams,
    ICustomerRequestImportFormDK,
    ICustomerRequestStoreParams,
    ICustomerRequestUpdateWithPermission,
    Customer,
    CustomerAdminMessages,
    CustomerSystemMessages,
    CustomerComments,
    ICustomerCommentsRequestCreate,
    ICustomerCommentsRequestUpdate,
    ICustomerCommentsRequestDelete,
    ICustomerAdminMessagesRequestCreate,
    ICustomerAdminMessagesRequestUpdate,
    ICustomerAdminMessagesRequestDelete,
    ICustomerRequestAddUser,
    ICustomerRequestRemoveUser,
    ICustomerResponse,
    ICustomerRequestChangeUserRole,
    ICustomerRequestUpdatePaymentMode,
    ICustomerRequestUpdateCreditCard,
    ICustomerRequestSendEmail,
    ICustomerResendNewUserMail,
    CustomerActionHistory,
    CustomerContactActionHistory,
    ICustomerRequestEmailInvoiceParams,
    getCustomerContactsRequest,
    CustomerExpense,
    ICustomerEmailLogsRequestResendParams
} from '../../models';
import { User } from '@appRoot/core/user/models';
import { CustomerHttpService } from '../../services/customer-http.service';
import { UserService } from '@appRoot/core/user/services/user.service';
import { CustomerService } from '../../services/customer.service';
import * as customerCommentsActions from '../actions/customer-comments.actions';
import * as customerAdminMessagesActions from '../actions/customer-admin-messages.actions';
import * as customerSystemMessagesActions from '../actions/customer-system-messages.actions';
import * as customerStorageActions from '../actions/customer-storage.actions';
import * as customerActions from '../actions/customer.actions';
import * as customerHistoryAction from "../actions/customer-history.action";
import * as customerContactHistoryAction from "../actions/customer-contact-history.action";


@Injectable()
export class CustomerEffects {

    constructor(
        private actions$: Actions,
        private httpService: CustomerHttpService,
        private customerService: CustomerService,
        private userService: UserService,
        private store: Store<any>,
    ) {}

    @Effect()
    load$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.LOAD),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerActions.Load, User]) => !!user),
        switchMap(([action, user]) =>
            this.httpService
            .fetch({...action.payload, uid: user.id }).pipe(
                switchMap(response => {
                    let customers: Customer[] = [];
                    let customerAdminMessages: CustomerAdminMessages[] = [];
                    let customerSystemMessages: CustomerSystemMessages[] = [];
                    let customerComments: CustomerComments[] = [];

                    response.data.forEach(customer => {
                        customers.push(new Customer(customer));

                        let messages = customer.customer_admin_messages.map(data => (new CustomerAdminMessages(data)));
                        customerAdminMessages = [...customerAdminMessages, ...messages];

                        let sys_messages = customer.customer_system_messages.map(data => (new CustomerSystemMessages(data)));
                        customerSystemMessages = [...customerSystemMessages, ...sys_messages];

                        let comments = customer.customer_comments.map(data => (new CustomerComments(data)));
                        customerComments = [...customerComments, ...comments];
                    });

                    let history: CustomerActionHistory[] = response.data.reduce((prev, cur) => {
                        return [...prev, ...cur.action_history.map(h => new CustomerActionHistory(h))];
                    }, []);

                    let contact_history: CustomerContactActionHistory[] = response.data.reduce((prev, cur) => {
                        return [...prev, ...cur.action_contact_history.map(h => new CustomerContactActionHistory(h))];
                    }, []);

                    return [
                        new customerStorageActions.UpsertMany(customers),

                        new customerAdminMessagesActions.RemoveManyByCustomerIds(customers.map(u => u.id)),
                        new customerAdminMessagesActions.UpsertMany(customerAdminMessages),

                        new customerSystemMessagesActions.RemoveManyByCustomerIds(customers.map(u => u.id)),
                        new customerSystemMessagesActions.UpsertMany(customerSystemMessages),

                        new customerCommentsActions.RemoveManyByCustomerIds(customers.map(u => u.id)),
                        new customerCommentsActions.UpsertMany(customerComments),

                        new customerActions.LoadSuccess({...response, data: customers}, action.selector),

                        new customerHistoryAction.UpsertMany(history),
                        new customerContactHistoryAction.UpsertMany(contact_history)
                    ];
                }),
                catchError(error => [
                    new customerActions.Error(new NGRXError(action, error)),
                    new customerActions.LoadFailed,
                ]),
            )
        )
    );

    @Effect()
    create$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.CREATE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerActions.Create, User]) => !!user),
        switchMap(([action,  user]) => {
            let params: ICustomerRequestStoreParams = {
                uid: user.id,
                name: action.payload.customer.name,
                number: action.payload.customer.number,
                status: action.payload.customer.status
            };
            if (action.payload.customer.source_id) {
                params.source_id = action.payload.customer.source_id;
            }
            if(!isEmpty(action.payload.customerContact)){
                params.customer_contact = action.payload.customerContact.map(d => getCustomerContactsRequest(d));
            }
            return this.httpService.create(params).pipe(
                switchMap(response => {
                    let customer = new Customer(response);

                    return [
                        new customerStorageActions.UpsertOne(customer),
                        new customerActions.CreateSuccess({ customer: customer }),
                        new customerActions.Reload(),
                        new customerHistoryAction.UpsertMany(response.action_history.map(h => new CustomerActionHistory(h))),
                        new customerContactHistoryAction.UpsertMany(response.action_contact_history.map(h => new CustomerContactActionHistory(h)))
                    ];
                }),
                catchError(error => [
                    new customerActions.Error(new NGRXError(action, error)),
                    new customerActions.CreateFailed,
                ])
            );
        })
    );

    @Effect()
    getById$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.GET_BY_ID),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerActions.GetById, User]) => !!user),
        switchMap(([action, user]) =>
            this.httpService.show({ uid: user.id, customer_id: action.payload }).pipe(
                switchMap((response) => {
                    let customer = new Customer(response);
                    let customerAdminMessages = response.customer_admin_messages.map(d => new CustomerAdminMessages(d));
                    let customerSystemMessages = response.customer_system_messages.map(d => new CustomerSystemMessages(d));
                    let customerComments= response.customer_comments.map(d => new CustomerComments(d));

                    return [
                        new customerStorageActions.UpsertOne(customer),

                        new customerAdminMessagesActions.RemoveManyByCustomerIds([customer.id]),
                        new customerAdminMessagesActions.UpsertMany(customerAdminMessages),

                        new customerSystemMessagesActions.RemoveManyByCustomerIds([customer.id]),
                        new customerSystemMessagesActions.UpsertMany(customerSystemMessages),

                        new customerCommentsActions.RemoveManyByCustomerIds([customer.id]),
                        new customerCommentsActions.UpsertMany(customerComments),

                        new customerActions.GetByIdSuccess({ customer: customer }),

                        new customerHistoryAction.UpsertMany(response.action_history.map(h => new CustomerActionHistory(h))),
                        new customerContactHistoryAction.UpsertMany(response.action_contact_history.map(h => new CustomerContactActionHistory(h)))
                    ];
                }),
                catchError(error => [
                    new customerActions.Error(new NGRXError(action, error)),
                    new customerActions.GetByIdFailed,
                ])
            )
        )
    );

    @Effect()
    update$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.UPDATE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerActions.Update, User]) => !!user),
        switchMap(([action, user]) => {
            let data: ICustomerRequestUpdateWithPermission = {};

            if (action instanceof customerActions.Update) {
                data.number = action.payload.changes.customer.number && (action.payload.customer.number !== action.payload.changes.customer.number) ? action.payload.changes.customer.number : undefined;
                data.name = action.payload.changes.customer.name && (action.payload.customer.name !== action.payload.changes.customer.name) ? action.payload.changes.customer.name : undefined;
                data.status = action.payload.changes.customer.status && (action.payload.customer.status !== action.payload.changes.customer.status) ? action.payload.changes.customer.status : undefined;
                data.docs_link = action.payload.customer.docs_link !== action.payload.changes.customer.docs_link ? action.payload.changes.customer.docs_link : undefined;
                data.source_id = action.payload.changes.customer.source_id && (action.payload.customer.source_id !== action.payload.changes.customer.source_id) ? action.payload.changes.customer.source_id : undefined;
            }

            const cleanedData = pickBy(data, v => v !== undefined);
            if(isEmpty(cleanedData)) {
                return [new customerActions.UpdateFailed];
            }

            return this.httpService.update({uid: user.id, customer_id: action.payload.customer.id}, cleanedData).pipe(
                switchMap((response) => {
                    let customer = new Customer(response);

                    return [
                        new customerStorageActions.UpsertOne(customer),
                        new customerActions.UpdateSuccess({ customer: customer }),
                        new customerHistoryAction.UpsertMany(response.action_history.map(h => new CustomerActionHistory(h))),
                        new customerContactHistoryAction.UpsertMany(response.action_contact_history.map(h => new CustomerContactActionHistory(h)))
                    ];
                }),
                catchError(error => [
                    new customerActions.Error(new NGRXError(action, error)),
                    new customerActions.UpdateFailed,
                ])
            );
        }),
    );

    @Effect()
    delete$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.DELETE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerActions.Delete, User]) => !!user),
        mergeMap(([action, user]) => {
            let params: ICustomerRequestDestroyParams = {
                uid: user.id
            };
            if(action.payload.force) {
                params.force = action.payload.force;
            }
            return this.httpService.destroy(action.payload.customers.map(с => с.id), params).pipe(
                mergeMap((response) => {
                    let actions: Action[];
                    let customers = response.map(e => new Customer(e));

                    let history: CustomerActionHistory[] = response.reduce((prev, cur) => {
                        return [...prev, ...cur.action_history.map(h => new CustomerActionHistory(h))];
                    }, []);

                    let contact_history: CustomerContactActionHistory[] = response.reduce((prev, cur) => {
                        return [...prev, ...cur.action_contact_history.map(h => new CustomerContactActionHistory(h))];
                    }, []);

                    if (action.payload.force === true) {
                        actions = [
                            new customerStorageActions.RemoveMany(customers),
                            new customerActions.DeleteSuccess({ customers: customers, force: action.payload.force }),
                            new customerHistoryAction.UpsertMany(history),
                            new customerContactHistoryAction.UpsertMany(contact_history)
                        ];
                    } else {
                        actions = [
                            new customerStorageActions.UpsertMany(customers),
                            new customerActions.DeleteSuccess({ customers: customers, force: action.payload.force }),
                            new customerHistoryAction.UpsertMany(history),
                            new customerContactHistoryAction.UpsertMany(contact_history)
                        ];
                    }
                    return actions;
                }),
                catchError(error => [
                    new customerActions.Error(new NGRXError(action, error)),
                    new customerActions.DeleteFailed,
                ])
            )
        })
    );

    @Effect()
    restore$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.RESTORE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerActions.Restore, User]) => !!user),
        mergeMap(([action, user]) =>
            this.httpService
            .restore(action.payload.customers.map(u => u.id), { uid: user.id })
            .pipe(
                mergeMap((response) => {
                    let customers: Customer[] = [];
                    response.forEach(e => {
                        customers.push(new Customer(e));
                    });

                    let history: CustomerActionHistory[] = response.reduce((prev, cur) => {
                        return [...prev, ...cur.action_history.map(h => new CustomerActionHistory(h))];
                    }, []);

                    let contact_history: CustomerContactActionHistory[] = response.reduce((prev, cur) => {
                        return [...prev, ...cur.action_contact_history.map(h => new CustomerContactActionHistory(h))];
                    }, []);

                    return [
                        new customerStorageActions.UpsertMany(customers),
                        new customerActions.RestoreSuccess({ customers: customers }),
                        new customerHistoryAction.UpsertMany(history),
                        new customerContactHistoryAction.UpsertMany(contact_history)
                    ];
                }),
                catchError(error => [
                    new customerActions.Error(new NGRXError(action, error)),
                    new customerActions.RestoreFailed,
                ])
            )
        )
    );

    @Effect()
    getByNameOrEmail$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.GET_BY_NAME_OR_EMAIL),
        map((action: customerActions.GetByNameOrEmail) => {
            return new customerActions.Load({
                search: {
                    strict: false,
                    method: 'OR',
                    name: action.payload,
                    email: action.payload,
                },
                page: 1,
                size: 20,
                status: 'active'
            });
        }),
    );

    @Effect()
    GetByNameEmailCustomerName$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.GET_BY_NAME_EMAIL_CUSTOMER_NAME),
        map((action: customerActions.GetByNameEmailCustomerName) => {
            return new customerActions.Load({
                search: {
                    strict: false,
                    method: 'OR',
                    name: action.payload,
                    email: action.payload,
                    customer_name: action.payload,
                },
                page: 1,
                size: 20,
                status: 'active'
            });
        }),
    );

    @Effect()
    addUserToCustomer$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.ADD_USER_TO_CUSTOMER),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerActions.AddUserToCustomer, User]) => !!user),
        switchMap(([action, user]) =>
            this.httpService.addUserToCustomer(<ICustomerRequestAddUser>{
                uid: user.id,
                customer_id: action.payload.customer.id,
                email: action.payload.email,
                role_id: action.payload.role_id
            }).pipe(
                switchMap((response) => {
                    let customer = new Customer(<ICustomerResponse>response);

                    return [
                        new customerStorageActions.UpdateOne(customer),
                        new customerActions.AddUserToCustomerSuccess(),
                        new customerHistoryAction.UpsertMany(response.action_history.map(h => new CustomerActionHistory(h))),
                        new customerContactHistoryAction.UpsertMany(response.action_contact_history.map(h => new CustomerContactActionHistory(h)))
                    ];
                }),
                catchError(error => [
                    new customerActions.AddUserToCustomerFailed(),
                    new customerActions.Error(new NGRXError(action, error)),
                ]),
            )
        )
    );

    @Effect()
    removeUserFromCustomer$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.REMOVE_USER_FROM_CUSTOMER),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerActions.RemoveUserFromCustomer, User]) => !!user),
        switchMap(([action, user]) =>
            this.httpService.removeUserFromCustomer(<ICustomerRequestRemoveUser>{
                uid: user.id,
                customer_id: action.payload.customer.id,
                user_id: action.payload.user.id,
                email: action.payload.user.email,
            }).pipe(
                switchMap((response) => {
                    let customer = new Customer(<ICustomerResponse>response);

                    return [
                        new customerStorageActions.UpdateOne(customer),
                        new customerActions.RemoveUserFromCustomerSuccess(),
                        new customerHistoryAction.UpsertMany(response.action_history.map(h => new CustomerActionHistory(h))),
                        new customerContactHistoryAction.UpsertMany(response.action_contact_history.map(h => new CustomerContactActionHistory(h)))
                    ];
                }),

                catchError(error => [
                    new customerActions.RemoveUserFromCustomerFailed(),
                    new customerActions.Error(new NGRXError(action, error)),
                ]),
            )
        )
    );

    @Effect()
    changeUserCustomerRole$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.CHANGE_USER_CUSTOMER_ROLE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerActions.ChangeUserCustomerRole, User]) => !!user),
        switchMap(([action, user]) =>
            this.httpService.changeUserCustomerRole(<ICustomerRequestChangeUserRole>{
                uid: user.id,
                customer_id: action.payload.customer.id,
                user_id: action.payload.user.id,
                email: action.payload.user.email,
                role_id: action.payload.role_id,
            }).pipe(
                switchMap((response) => {
                    let customer = new Customer(<ICustomerResponse>response);

                    return [
                        new customerStorageActions.UpdateOne(customer),
                        new customerActions.ChangeUserCustomerRoleSuccess(),
                        new customerHistoryAction.UpsertMany(response.action_history.map(h => new CustomerActionHistory(h))),
                        new customerContactHistoryAction.UpsertMany(response.action_contact_history.map(h => new CustomerContactActionHistory(h)))
                    ];
                }),

                catchError(error => [
                    new customerActions.ChangeUserCustomerRoleFailed(),
                    new customerActions.Error(new NGRXError(action, error)),
                ]),
            )
        )
    );

    @Effect()
    resendNewUserMail$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.RESEND_NEW_USER_MAIL),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerActions.ResendNewUserMail, User]) => !!user),
        switchMap(([action, user]) =>
            this.httpService.resendNewUserMail(<ICustomerResendNewUserMail>{
                uid: user.id,
                customer_id: action.payload.customer.id,
                email: action.payload.email
            }).pipe(
                switchMap((response) => {
                    let customer = new Customer(<ICustomerResponse>response);

                    return [
                        new customerStorageActions.UpdateOne(customer),
                        new customerActions.ResendNewUserMailSuccess()
                    ];
                }),
                catchError(error => [
                    new customerActions.ResendNewUserMailFailed(),
                    new customerActions.Error(new NGRXError(action, error)),
                ]),
            )
        )
    );

    @Effect()
    importFromDK$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.IMPORT_FROM_DK),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerActions.importFromDK, User]) => !!user),
        switchMap(([action, user]) => {
            let params: ICustomerRequestImportFormDK = {
                uid: user.id,
            };
            return this.httpService.importFormDK(params).pipe(
                map(response => {
                        if (response.success) {
                            return new customerActions.importFromDKSuccess({data: response.data ? response.data : ''})
                        } else {
                            return new customerActions.importFromDKFailed({data: response.error.message})
                        }
                    }
                ),
                catchError(error => [
                    new customerActions.importFromDKFailed({data: error.error.error.message}),
                    new customerActions.Error(new NGRXError(action, error)),
                ]),
            );
        }),
    );

    @Effect()
    importFromDKSuccess$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.IMPORT_FROM_DK_SUCCESS),
        map(action => new customerActions.Reload)
    );

    @Effect()
    getEmailLogs$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.GET_EMAIL_LOGS),
        withLatestFrom(this.userService.activeUser$ ),
        filter(([action, user]: [customerActions.GetEmailLogs, User]) => !!user),
        mergeMap(([action, user]) => {
            return this.httpService.getEmailLogs({uid: user.id, ...action.payload})
                .pipe(
                    switchMap(email_logs => [
                        new customerStorageActions.UpdateEmailLogs({ id: action.payload.customer_id, email_logs: email_logs }),
                        new customerActions.GetEmailLogsSuccess()
                    ]),
                    catchError(error => [
                        new customerActions.Error(new NGRXError(action, error)),
                        new customerActions.GetEmailLogsFailed(new NGRXError(action, error)),
                    ]),
                );
        }),
    );

    @Effect()
    resendEmailLogs$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.RESEND_EMAIL_LOGS),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerActions.ResendEmailLogs, User]) => !!user),
        switchMap(([action, user]) =>
            this.httpService.resendEmailLogs(<ICustomerEmailLogsRequestResendParams>{
                uid: user.id,
                id: action.payload.id
            }).pipe(
                map(response => new customerActions.ResendEmailLogsSuccess()),
                catchError(error => [
                    new customerActions.Error(new NGRXError(action, error)),
                    new customerActions.ResendEmailLogsFailed()
                ])
            )
        )
    );

    @Effect()
    getSubscription: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.GET_SUBSCRIPTION),
        withLatestFrom(this.userService.activeUser$ ),
        filter(([action, user]: [customerActions.GetSubscription, User]) => !!user),
        mergeMap(([action, user]) => {
            return this.httpService.getSubscription({uid: user.id, ...action.payload})
                .pipe(
                    switchMap(subscription => [
                        new customerStorageActions.UpdateSubscription({ id: action.payload.customer_id, subscription: subscription }),
                        new customerActions.GetSubscriptionSuccess
                    ]),
                    catchError(error => [
                        new customerActions.Error(new NGRXError(action, error)),
                        new customerActions.GetSubscriptionFailed(new NGRXError(action, error)),
                    ]),
                );
        }),
    );

    @Effect()
    getTimeTracking$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.GET_TIME_TRACKING),
        withLatestFrom(this.userService.activeUser$ ),
        filter(([action, user]: [customerActions.GetTimeTracking, User]) => !!user),
        mergeMap(([action, user]) => {
            return this.httpService.getTimeTracking({uid: user.id, ...action.payload})
                .pipe(
                    switchMap(time => [
                        new customerStorageActions.UpdateTimeTracking({ id: action.payload.customer_id, time: time }),
                        new customerActions.GetTimeTrackingSuccess()
                    ]),
                    catchError(error => [
                        new customerActions.Error(new NGRXError(action, error)),
                        new customerActions.GetTimeTrackingFailed(new NGRXError(action, error)),
                    ]),
                );
        }),
    );

    @Effect()
    getInvoice$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.GET_INVOICE),
        withLatestFrom(this.userService.activeUser$ ),
        filter(([action, user]: [customerActions.GetInvoice, User]) => !!user),
        mergeMap(([action, user]) => {
            return this.httpService.getInvoice({uid: user.id, ...action.payload})
                .pipe(
                    switchMap(invoice => [
                        new customerStorageActions.UpdateInvoice({ id: action.payload.customer_id, invoice: invoice }),
                        new customerActions.GetInvoiceSuccess()
                    ]),
                    catchError(error => [
                        new customerActions.Error(new NGRXError(action, error)),
                        new customerActions.GetInvoiceFailed()
                    ]),
                );
        }),
    );

    @Effect()
    pdfInvoice$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.GET_INVOICE_PDF),
        withLatestFrom(this.userService.activeUser$ ),
        filter(([action, user]: [customerActions.GetInvoicePdf, User]) => !!user),
        mergeMap(([action, user]) => {
            return this.httpService.getInvoicePdf({uid: user.id, ...action.payload})
                .pipe(
                    switchMap(data => {
                        let b64toBlob = (dataURI) => {
                            const bytes = atob(dataURI);
                            let length = bytes.length;
                            let out = new Uint8Array(length);

                            while (length--) {
                                out[length] = bytes.charCodeAt(length);
                            }

                            return new Blob([out], { type: 'application/pdf' });
                        };

                        var link = document.createElement('a');
                        link.href = URL.createObjectURL(b64toBlob(data.FileContent));
                        link.download = `${data.FileName}.pdf`;
                        link.click();

                        return [
                            new customerActions.GetInvoicePdfSuccess()
                        ]
                    }),
                    catchError(error => [
                        new customerActions.Error(new NGRXError(action, error)),
                        new customerActions.GetInvoicePdfFailed()
                    ]),
                );
        }),
    );

    @Effect()
    htmlInvoice$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.GET_INVOICE_HTML),
        withLatestFrom(this.userService.activeUser$ ),
        filter(([action, user]: [customerActions.GetInvoiceHtml, User]) => !!user),
        mergeMap(([action, user]) => {
            return this.httpService.getInvoiceHtml({uid: user.id, ...action.payload})
                .pipe(
                    switchMap(invoice => [
                        new customerActions.GetInvoiceHtmlSuccess({ id: action.payload.customer_id, html: invoice.html })
                    ]),
                    catchError(error => [
                        new customerActions.Error(new NGRXError(action, error)),
                        new customerActions.GetInvoiceHtmlFailed()
                    ]),
                );
        }),
    );

    @Effect()
    emailInvoice$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.INVOICE_EMAIL),
        withLatestFrom(this.userService.activeUser$ ),
        filter(([action, user]: [customerActions.InvoiceEmail, User]) => !!user),
        switchMap(([action, user]) => {
            let data: ICustomerRequestEmailInvoiceParams = {
                uid: user.id,
                customer_id: action.payload.customer_id,
                invoice_number: action.payload.invoice.Number,
                emails: action.payload.emails.length ? action.payload.emails : undefined
            };

            return this.httpService.emailInvoice(data).pipe(
                    map(response => new customerActions.InvoiceEmailSuccess),
                    catchError(error => [
                        new customerActions.Error(new NGRXError(action, error)),
                        new customerActions.InvoiceEmailFailed
                    ])
                );
        }),
    );

    @Effect()
    getPaymentMode: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.GET_PAYMENT_MODE),
        withLatestFrom(this.userService.activeUser$ ),
        filter(([action, user]: [customerActions.GetPaymentMode, User]) => !!user),
        mergeMap(([action, user]) => {
            return this.httpService.getPaymentMode({uid: user.id, ...action.payload})
                .pipe(
                    switchMap(data => [
                        new customerStorageActions.UpdateBilling({
                            id: action.payload.customer_id,
                            paymentMode: data.paymentMode,
                            creditCard: data.creditCard,
                            creditName: data.Name,
                            creditExpDate: data.ExpDate,
                        }),
                        new customerActions.GetPaymentModeSuccess
                    ]),
                    catchError(error => [
                        new customerActions.Error(new NGRXError(action, error)),
                        new customerActions.GetPaymentModeFailed
                    ]),
                );
        }),
    );

    @Effect()
    updatePaymentMode$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.UPDATE_PAYMENT_MODE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerActions.UpdatePaymentMode, User]) => !!user),
        switchMap(([action, user]) => {
            let data: ICustomerRequestUpdatePaymentMode = {
                paymentMode: action.payload.paymentMode && (action.payload.customer.paymentMode !== action.payload.paymentMode) ? action.payload.paymentMode : undefined,
                updateSubscription: action.payload.updateSubscription
            };

            const cleanedData = pickBy(data, v => v !== undefined);
            if(isEmpty(cleanedData)) {
                return [
                    new customerActions.UpdatePaymentModeFailed
                ];
            }

            return this.httpService.updatePaymentMode({uid: user.id, customer_id: action.payload.customer.id}, cleanedData)
                .pipe(
                    switchMap((data) => {
                        return [
                            new customerStorageActions.UpdatePaymentMode({ id: action.payload.customer.id, paymentMode: data.paymentMode }),
                            new customerActions.UpdatePaymentModeSuccess,
                        ];
                    }),
                    catchError(error => [
                        new customerActions.Error(new NGRXError(action, error)),
                        new customerActions.UpdatePaymentModeFailed
                    ])
                );
        }),
    );

    @Effect()
    updateCreditCard$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.UPDATE_CREDIT_CARD),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerActions.UpdateCreditCard, User]) => !!user),
        switchMap(([action, user]) => {
            let data: ICustomerRequestUpdateCreditCard = {
                card: action.payload.card ? action.payload.card : undefined
            };

            const cleanedData = pickBy(data, v => v !== undefined);
            if (isEmpty(cleanedData)) {
                return [
                    new customerActions.UpdateCreditCardFailed
                ];
            }

            return this.httpService.updateCreditCard({uid: user.id, customer_id: action.payload.customer.id}, cleanedData)
                .pipe(
                    switchMap((data) => {
                        return [
                            new customerStorageActions.UpdateCreditCard({
                                id: action.payload.customer.id,
                                creditCard: data.creditCard,
                                creditName: data.Name,
                                creditExpDate: data.ExpDate,
                            }),
                            new customerActions.UpdateCreditCardSuccess,
                        ];
                    }),
                    catchError(error => [
                        new customerActions.Error(new NGRXError(action, error)),
                        new customerActions.UpdateCreditCardFailed
                    ])
                );
        }),
    );

    @Effect()
    createAdminMessage$: Observable<Action> = this.actions$.pipe(
        ofType(customerAdminMessagesActions.ActionTypes.CREATE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerAdminMessagesActions.Create, User]) => !!user),
        switchMap(([action,  user]) => {
            let params: ICustomerAdminMessagesRequestCreate = {
                uid: user.id,
                customer_id: action.payload.customer_id,
                message: action.payload.message,
                type: action.payload.type,
            };

            return this.httpService.createAdminMesage(params).pipe(
                switchMap(response => {
                    let customer = new Customer(response);
                    let customerAdminMessages = response.customer_admin_messages.map(d => new CustomerAdminMessages(d));

                    return [
                        new customerAdminMessagesActions.RemoveManyByCustomerIds([customer.id]),
                        new customerAdminMessagesActions.UpsertMany(customerAdminMessages),
                        new customerAdminMessagesActions.CreateSuccess(),
                    ];
                }),
                catchError(error => [
                    new customerAdminMessagesActions.Error(new NGRXError(action, error)),
                    new customerAdminMessagesActions.CreateFailed,
                ])
            );
        })
    );

    @Effect()
    updateAdminMessage$: Observable<Action> = this.actions$.pipe(
        ofType(customerAdminMessagesActions.ActionTypes.UPDATE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerAdminMessagesActions.Update, User]) => !!user),
        switchMap(([action,  user]) => {
            let params: ICustomerAdminMessagesRequestUpdate = {
                uid: user.id,
                message_id: action.payload.message_id,
                customer_id: action.payload.customer_admin_messages.customer_id,
                message: action.payload.customer_admin_messages.message,
                type: action.payload.customer_admin_messages.type
            };

            return this.httpService.updateAdminMesage(params).pipe(
                switchMap(response => {
                    let customer = new Customer(response);
                    let customerAdminMessages = response.customer_admin_messages.map(d => new CustomerAdminMessages(d));

                    return [
                        new customerAdminMessagesActions.RemoveManyByCustomerIds([customer.id]),
                        new customerAdminMessagesActions.UpsertMany(customerAdminMessages),
                        new customerAdminMessagesActions.UpdateSuccess(),
                    ];
                }),
                catchError(error => [
                    new customerAdminMessagesActions.Error(new NGRXError(action, error)),
                    new customerAdminMessagesActions.UpdateFailed,
                ])
            );
        })
    );

    @Effect()
    deleteAdminMessage$: Observable<Action> = this.actions$.pipe(
        ofType(customerAdminMessagesActions.ActionTypes.DELETE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerAdminMessagesActions.Delete, User]) => !!user),
        switchMap(([action,  user]) => {
            let params: ICustomerAdminMessagesRequestDelete = {
                uid: user.id,
                message_id: action.payload.id,
                customer_id: action.payload.customer_id
            };

            return this.httpService.deleteAdminMesage(params).pipe(
                switchMap(response => {
                    let customer = new Customer(response);
                    let customerAdminMessages = response.customer_admin_messages.map(d => new CustomerAdminMessages(d));

                    return [
                        new customerAdminMessagesActions.RemoveManyByCustomerIds([customer.id]),
                        new customerAdminMessagesActions.UpsertMany(customerAdminMessages),
                        new customerAdminMessagesActions.DeleteSuccess(),
                    ];
                }),
                catchError(error => [
                    new customerAdminMessagesActions.Error(new NGRXError(action, error)),
                    new customerAdminMessagesActions.DeleteFailed,
                ])
            );
        })
    );

    @Effect()
    sendCustomerEmail$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.SEND_EMAIL),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerActions.SendEmail, User]) => !!user),
        switchMap(([action,  user]) => {
            let params: ICustomerRequestSendEmail = {
                uid: user.id,
                customer_ids: action.payload.customer_ids,
                subject: action.payload.subject,
                html_template: action.payload.html_template,
                text_template: action.payload.text_template,
                main_contact: action.payload.main_contact
            };

            return this.httpService.sendEmail(params).pipe(
                map(response => new customerActions.SendEmailSuccess()),
                catchError(error => [
                    new customerActions.SendEmailFailed(),
                    new customerActions.Error(new NGRXError(action, error)),
                ])
            );
        })
    );

    @Effect()
    getHistory: Observable<Action> = this.actions$.pipe(
        ofType(customerHistoryAction.ActionTypes.GET_HISTORY),
        withLatestFrom(this.userService.activeUser$ ),
        filter(([action, user]: [customerHistoryAction.GetHistory, User]) => !!user),
        mergeMap(([action, user]) => {
            let params = {...action.payload};
            let actions = [];
            if(action.payload.date_from) {
                actions.push(new customerHistoryAction.SetDateFrom(action.payload.date_from))
                params.date_from = Math.floor(action.payload.date_from / 1000);
            }if(action.payload.date_until) {
                actions.push(new customerHistoryAction.SetDateUntil(action.payload.date_until))
                params.date_until = Math.floor(action.payload.date_until / 1000);
            }
            return this.httpService.getHistory({uid: user.id, ...params})
                .pipe(
                    switchMap(hist => [
                        ...actions,
                        new customerHistoryAction.UpsertMany(hist),
                        new customerHistoryAction.GetHistorySuccess,
                    ]),
                    catchError(error => [
                        new customerHistoryAction.Error(new NGRXError(action, error)),
                        new customerHistoryAction.GetHistoryFailed,
                    ]),
                );
        }),
    );

    @Effect()
    getContactHistory: Observable<Action> = this.actions$.pipe(
        ofType(customerContactHistoryAction.ActionTypes.GET_HISTORY),
        withLatestFrom(this.userService.activeUser$ ),
        filter(([action, user]: [customerContactHistoryAction.GetHistory, User]) => !!user),
        mergeMap(([action, user]) => {
            let params = {...action.payload};
            let actions = [];
            if(action.payload.date_from) {
                actions.push(new customerContactHistoryAction.SetDateFrom(action.payload.date_from))
                params.date_from = Math.floor(action.payload.date_from / 1000);
            }if(action.payload.date_until) {
                actions.push(new customerContactHistoryAction.SetDateUntil(action.payload.date_until))
                params.date_until = Math.floor(action.payload.date_until / 1000);
            }
            return this.httpService.getContactHistory({uid: user.id, ...params})
                .pipe(
                    switchMap(hist => [
                        ...actions,
                        new customerContactHistoryAction.UpsertMany(hist),
                        new customerContactHistoryAction.GetHistorySuccess,
                    ]),
                    catchError(error => [
                        new customerContactHistoryAction.Error(new NGRXError(action, error)),
                        new customerContactHistoryAction.GetHistoryFailed,
                    ]),
                );
        }),
    );

    @Effect()
    createComment$: Observable<Action> = this.actions$.pipe(
        ofType(customerCommentsActions.ActionTypes.CREATE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerCommentsActions.Create, User]) => !!user),
        switchMap(([action,  user]) => {
            let params: ICustomerCommentsRequestCreate = {
                uid: user.id,
                customer_id: action.payload.customer_id,
                comment: action.payload.comment
            };

            return this.httpService.createComment(params).pipe(
                switchMap(response => {
                    let customer = new Customer(response);
                    let customerComments = response.customer_comments.map(d => new CustomerComments(d));

                    return [
                        new customerCommentsActions.RemoveManyByCustomerIds([customer.id]),
                        new customerCommentsActions.UpsertMany(customerComments),
                        new customerCommentsActions.CreateSuccess(),
                    ];
                }),
                catchError(error => [
                    new customerCommentsActions.Error(new NGRXError(action, error)),
                    new customerCommentsActions.CreateFailed,
                ])
            );
        })
    );

    @Effect()
    updateComment$: Observable<Action> = this.actions$.pipe(
        ofType(customerCommentsActions.ActionTypes.UPDATE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerCommentsActions.Update, User]) => !!user),
        switchMap(([action,  user]) => {
            let params: ICustomerCommentsRequestUpdate = {
                uid: user.id,
                comment_id: action.payload.id,
                customer_id: action.payload.customer_id,
                comment: action.payload.comment
            };

            return this.httpService.updateComment(params).pipe(
                switchMap(response => {
                    let customer = new Customer(response);
                    let customerComments = response.customer_comments.map(d => new CustomerComments(d));

                    return [
                        new customerCommentsActions.RemoveManyByCustomerIds([customer.id]),
                        new customerCommentsActions.UpsertMany(customerComments),
                        new customerCommentsActions.UpdateSuccess(),
                    ];
                }),
                catchError(error => [
                    new customerCommentsActions.Error(new NGRXError(action, error)),
                    new customerCommentsActions.UpdateFailed,
                ])
            );
        })
    );

    @Effect()
    deleteComment$: Observable<Action> = this.actions$.pipe(
        ofType(customerCommentsActions.ActionTypes.DELETE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerCommentsActions.Delete, User]) => !!user),
        switchMap(([action,  user]) => {
            let params: ICustomerCommentsRequestDelete = {
                uid: user.id,
                comment_id: action.payload.id,
                customer_id: action.payload.customer_id
            };

            return this.httpService.deleteComment(params).pipe(
                switchMap(response => {
                    let customer = new Customer(response);
                    let customerComments = response.customer_comments.map(d => new CustomerComments(d));

                    return [
                        new customerCommentsActions.RemoveManyByCustomerIds([customer.id]),
                        new customerCommentsActions.UpsertMany(customerComments),
                        new customerCommentsActions.DeleteSuccess(),
                    ];
                }),
                catchError(error => [
                    new customerCommentsActions.Error(new NGRXError(action, error)),
                    new customerCommentsActions.DeleteFailed,
                ])
            );
        })
    );

    @Effect()
    createExpense$: Observable<Action> = this.actions$.pipe(
        ofType(customerActions.ActionTypes.CREATE_EXPENSE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerActions.CreateExpense, User]) => !!user),
        switchMap(([action, user]) => {
            let data: CustomerExpense = action.payload.expense;

            return this.httpService.createExpense({uid: user.id, customer_id: action.payload.customer.id}, data).pipe(
                map(response => new customerActions.CreateExpenseSuccess()),
                catchError(error => [
                    new customerActions.CreateExpenseFailed(),
                    new customerActions.Error(new NGRXError(action, error)),
                ])
            );
        }),
    );
}