import { ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { RefreshTokenInterceptor } from './services/refresh-token.interceptor';
import { AuthenticationHttpService } from './services/authentication-http.service';
import { AuthenticationService } from './services/authentication.service';
import { AuthenticationGuard } from './guards/authentication-guard.service';

import { SharedModule } from '@appRoot/shared/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';

import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ConfirmFormComponent } from './components/confirm-form/confirm-form.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ResetPasswordSendComponent } from './components/reset-password-send/reset-password-send.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';


@NgModule({
	declarations: [
		LoginComponent,
		LogoutComponent,
		RegisterComponent,
		ConfirmFormComponent,
		ConfirmComponent,
		ResetPasswordSendComponent,
		ResetPasswordComponent,
	],
	imports: [
		SharedModule,
		RouterModule,
		MatFormFieldModule,
	],
	providers: [		
	],
	exports: [
		LoginComponent,
		LogoutComponent,
		RegisterComponent,
		ConfirmFormComponent,
		ConfirmComponent,
		ResetPasswordSendComponent,
		ResetPasswordComponent,
	],

})
export class AuthenticationModule {
	static forRoot(): ModuleWithProviders<AuthenticationModule> {
		return {
			ngModule: AuthenticationModule,
			providers: [
				{ provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
				AuthenticationGuard,
				AuthenticationHttpService,
				AuthenticationService,
			]      
		};
	}

}
