import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';

import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent implements OnInit, OnDestroy {

    public loading = false;
    public authenticated = false;
    private loadingSbn: Subscription;
    private authenticateSbn: Subscription;

    @Input() public className: string = 'btn btn-block btn-primary';

    constructor(
        private authService: AuthenticationService, 
        ) {}
    
    ngOnInit() {
        this.loadingSbn = this.authService.getLoading$().subscribe(e => {
            this.loading = e;
        });
        this.authenticateSbn = this.authService.isAuthenticated$().subscribe(e => {
            this.authenticated = e;
        });
    }

    ngOnDestroy(){
        this.loadingSbn.unsubscribe();
        this.authenticateSbn.unsubscribe();
    }

    logout($ev) {
        $ev.preventDefault();         
        this.loading = true;
        this.authService.dispatchLogout();
    }

}
