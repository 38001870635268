import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Action } from '@ngrx/store';
import { AvailableData } from '../../models';


export enum ActionTypes {
    LOAD = '[AvailableData] load',
    LOAD_SUCCESS = '[AvailableData] load success',
    LOAD_FAILED = '[AvailableData] load failed',

    ERROR = '[AvailableData] error',
    RESET = '[AvailableData] reset',
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;
    constructor(readonly payload: NGRXError<Actions>) { }
}

export class Reset implements Action {
    readonly type = ActionTypes.RESET;
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD
}
export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;
    constructor(readonly payload: AvailableData) {}
}
export class LoadFailed implements Action {
    readonly type = ActionTypes.LOAD_FAILED;
}


export type Actions =
    | Load
    | LoadSuccess
    | LoadFailed
    | Error
    | Reset
    ;