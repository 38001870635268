import { NgModule } from '@angular/core';
import { AvailableDataHttpService } from './services/available-data-http.service';

@NgModule({
	declarations: [],
	imports: [],
	providers: [
		AvailableDataHttpService,
	]
})
export class AvailableDataModule { }