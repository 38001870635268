import {
    ChangeDetectorRef,
    Compiler,
    Component,
    Injector,
    OnDestroy,
    OnInit
} from '@angular/core';
import { SettingsService }    from '@appRoot/core/services/settings.service';
import { UserService }        from '@appRoot/core/user/services/user.service';
import { User }               from '@appRoot/core/user/models';
import { Subscription }       from 'rxjs';
import { ROUTE_PATHS }        from "@appRoot/routes/routes.names";
import { Customer } from "@appRoot/core/customer/models";
import { Router } from "@angular/router";


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

    private userSbn: Subscription;

    public logo: string;
    public logoSingle: string;
    public user: User;
    public switchAccountCount: number;
    public user_link: string;

    constructor(
        public settings: SettingsService,
        private userService: UserService,
        private injector: Injector,
        private compiler: Compiler,
        private router: Router,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.logo = this.settings.app.logo.default;
        this.logoSingle = this.settings.app.logo.single;

        this.userSbn = this.userService.activeUser$.subscribe(user => {
            this.user = user;
        });

        this.userLink();
    }

    ngOnDestroy(){
        this.userSbn.unsubscribe();
    }

    toggleOffsidebar() {
        this.settings.layout.offsidebarOpen = !this.settings.layout.offsidebarOpen;
    }

    userLink() {
        return this.userService.activeUser$.subscribe(user => {
            if(!user) return;
            this.user_link = '/'+ROUTE_PATHS.UsersModule+'/'+user.id+'/general';
        });
    }

    entityChanges(entity: Customer) {
        if (entity) {
            this.router.navigate(["/customers/" + entity.id]);
        }
        this.cdr.detectChanges();
    }
}
