import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Actions, ActionTypes } from '../actions/customer-expense-settings.actions';
import { CustomerExpenseSettings } from '../../models';


export interface State {
    error: NGRXError<Actions>;
    loading: boolean;
    loaded: boolean;
    updating: boolean;
    data: CustomerExpenseSettings;
}

export const initialState: State = {
    error: null,
    loading: false,
    loaded: false,
    updating: false,
    data: null
};

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {

        case ActionTypes.ERROR:
            return {
                ...state,
                error: action.payload
            };

        case ActionTypes.LOAD:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                data: new CustomerExpenseSettings(action.payload),
            };
        case ActionTypes.LOAD_FAILED:
            return {
                ...state,
                loading: false,
                loaded: false,
            };

        case ActionTypes.UPDATE:
            return {
                ...state,
                updating: true,
            };
        case ActionTypes.UPDATE_SUCCESS:
        case ActionTypes.UPDATE_FAILED:
            return {
                ...state,
                updating: false,
            };

        default:
            return state;
    }
}
