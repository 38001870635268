import { isNil } from 'lodash';
import { ICustomerContactsResponse } from './ICustomerContactsRequestParams';
import { ICustomerContactActionHistoryResponse } from './ICustomerContactHistoryRequestParams';


export class CustomerContactActionHistory {
    id: number;
    customer_id: number;
    contact_id: number;
    user_id: number;
    username: string;
    action: string;
    created: number; // timestamp

    origin: Partial<ICustomerContactsResponse>;
    changes: Partial<ICustomerContactsResponse>;

    constructor(data?: ICustomerContactActionHistoryResponse) {
        if(data) {
            this.createFromResponse(data);
        }
    }

    createFromResponse(data: ICustomerContactActionHistoryResponse) {
        this.id = data.id;
        this.customer_id = data.customer_id;
        this.contact_id = data.contact_id;
        this.user_id = data.user_id;
        this.username = data.user?.name;
        this.action = data.action;
        this.created = !isNil(data.created_at) ? (new Date(data.created_at)).getTime() : null;
        this.origin = data.contact_data && data.contact_data.origin ? data.contact_data.origin : null;
        this.changes = data.contact_data && data.contact_data.changes ? data.contact_data.changes : null;
    }
}
