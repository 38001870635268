import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Customer } from '../models';
import { State } from '../ngrx-store/reducers/customer.reducer';
import * as customerSelectors from '../ngrx-store/selectors/customer.selectors';
import { Go } from '@appRoot/core/ngrx-store/actions/navigation.actions';


@Injectable()
export class CustomerService {

	constructor(
		private store: Store<State>,
    ) { }

	get getCustomerId$(): Observable<number> {
		return this.store.pipe(select(customerSelectors.getSessionCustomerId));
	}
	get customer$(): Observable<Customer> {
		return this.store.pipe(select(customerSelectors.getSessionCustomer));
	}

	get getActiveCustomerId$(): Observable<number> {
		return this.store.pipe(select(customerSelectors.getActiveCustomerId));
	}

	get activeCustomer$(): Observable<Customer> {
		return this.store.pipe(select(customerSelectors.getActiveCustomer));
	}

    goToCustomerView(customer: Customer) {
        let path = ['/customers/' + customer.id];
        this.store.dispatch(new Go({ path: path }))
    }

}

