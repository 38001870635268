import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { NGRXError } from 'src/app/core/ngrx-store/models/NGRXError';
import { Notification } from '../../models';
import { Actions, ActionTypes } from '../actions/notification.actions';


export function selectId(entity: Notification): string {
    return entity.notification;
}

export const adapter: EntityAdapter<Notification> = createEntityAdapter({
    selectId: selectId,
    sortComparer: false,
});

export interface State extends EntityState<Notification> {
    error: NGRXError<Actions>;
    loaded: boolean;
    loading: boolean;
    updating: boolean;
}

export const initialState: State = adapter.getInitialState({
    error: null,
    loaded: false,
    loading: false,
    updating: false,
});

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {

        case ActionTypes.ERROR:
            return {
                ...state,
                error: action.payload
            };

        case ActionTypes.RESET:
            return {
                ...initialState
            };

        case ActionTypes.LOAD:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.LOAD_SUCCESS:
            return adapter.addAll(action.payload, {
                ...state,
                loading: false,
                loaded: true,
            });
        case ActionTypes.LOAD_FAILED:
            return {
                ...state,
                loading: false,
                loaded: false,
            };

        case ActionTypes.UPDATE:
            return {
                ...state,
                updating: true,
            };
        case ActionTypes.UPDATE_SUCCESS:
            return adapter.upsertOne(action.payload, {
                ...state,
                updating: false,
            });
        case ActionTypes.UPDATE_FAILED:
            return {
                ...state,
                updating: false,
            };

        default:
            return state;
    }
}
