import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from '../reducers/confirmation.reducer';


export const getStore = createFeatureSelector<State>('confirmation');

export const getSending = createSelector(
  getStore,
  (state) => state.sending
);

export const getConfirming = createSelector(
  getStore,
  (state) => state.confirming
);

export const getError = createSelector(
  getStore,
  (state) => state.error
);

export const getConfirmed = createSelector(
  getStore,
  (state) => state.confirmed
);

export const getSent = createSelector(
  getStore,
  (state) => state.sent
);