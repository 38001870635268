import {
    ComponentFactoryResolver,
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    ViewContainerRef,
} from '@angular/core';
import { UserInfoTooltipComponent } from './user-info-tooltip.component';


@Directive({
    selector: '[appUserInfoTooltip]',
})
export class UserInfoTooltipDirective implements OnInit {
    private component: UserInfoTooltipComponent;

    constructor(
        private el: ElementRef,
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
    ) { }

    private _userId: number;

    @Input('appUserInfoTooltip')
    set userId(userId: number) {
        this._userId = userId;
        if(this.component) {
            this.component.userId = userId;
        }
    }

    @HostListener('mouseenter', ['$event']) onMouseEnter(event): void {
        this.component.show();
    }

    @HostListener('mouseleave', ['$event']) onMouseLeave(event): void {
        this.component.hide();
    }

    ngOnInit(): void {
        this.createComponent();
    }

    private createComponent(): void {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(UserInfoTooltipComponent);
        const componentRef = this.viewContainerRef.createComponent(componentFactory);
        this.component = componentRef.instance;
        this.component.directiveElem = this.el;
        this.component.userId = this._userId;
    }
}
