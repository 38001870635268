import { createSelector } from '@ngrx/store';
import { ModuleStore } from '../reducers';
import { adapter } from '@appRoot/core/customer/ngrx-store/reducers/customer-mail-templates.reducer';


export const getState = createSelector(
    ModuleStore,
    (store) => store.customer_mail_templates
);

export const getLoaded = createSelector(
    getState,
    (state) => state.loaded
);

export const getLoading = createSelector(
    getState,
    (state) => state.loading
);

export const getCreating = createSelector(
    getState,
    (state) => state.creating
);

export const getUpdating = createSelector(
    getState,
    (state) => state.updating
);

export const getDeleting = createSelector(
    getState,
    (state) => state.deleting
);

export const getError = createSelector(
    getState,
    (state) => state.error
);

const entitySelectors = adapter.getSelectors();

export const getTemplatesArray = createSelector(
    getState,
    entitySelectors.selectAll
);

export const getTemplatesDictionary = createSelector(
    getState,
    entitySelectors.selectEntities
);

export const getVariables = createSelector(
    getState,
    (state) => state.variables
);

export const getTemplateById = (templateId: number) => {
    return createSelector(
        getTemplatesDictionary,
        (entities) => entities[templateId]
    );
};

export const getActiveTemplates = createSelector(
    getTemplatesArray,
    (entities) => entities.filter(e => e.status)
);