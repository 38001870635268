import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from 'src/environments/environment';

import * as core from './reducers/core.reducer';
import * as navigation from './reducers/navigation.reducer';
import * as translator from '@appRoot/core/translator/ngrx-store/reducers/translator.reducer';
import * as authentication from '@appRoot/core/authentication/ngrx-store/reducers/authentication.reducer'
import * as registration from '@appRoot/core/authentication/ngrx-store/reducers/registration.reducer'
import * as confirmation from '@appRoot/core/authentication/ngrx-store/reducers/confirmation.reducer'
import * as resetPassword from '@appRoot/core/authentication/ngrx-store/reducers/reset-password.reducer'
import * as permission from '@appRoot/core/roles-permissions/ngrx-store/reducers/permission.reducer';
import * as permissionGroup from '@appRoot/core/roles-permissions/ngrx-store/reducers/permission-group.reducer';
import * as role from '@appRoot/core/roles-permissions/ngrx-store/reducers/role.reducer';
import * as availableDataReducer from '@appRoot/core/available-data/ngrx-store/reducers/available-data.reducer';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { RouterState } from './router';


export interface State {
    core: core.State;
    translator: translator.State;
    authentication: authentication.State;
    registration: registration.State;
    confirmation: confirmation.State;
    resetPassword: resetPassword.State;
    permission: permission.State;
    permissionGroup: permissionGroup.State;
    role: role.State;
    router: RouterReducerState<RouterState>;
    navigation: navigation.State;
    availableData: availableDataReducer.State;
}

export const reducers: ActionReducerMap<State> = {
    core: core.reducer,
    translator: translator.reducer,
    authentication: authentication.reducer,
    registration: registration.reducer,
    confirmation: confirmation.reducer,
    resetPassword: resetPassword.reducer,
    permission: permission.reducer,
    permissionGroup: permissionGroup.reducer,
    role: role.reducer,
    router: routerReducer,
    navigation: navigation.reducer,
    availableData: availableDataReducer.reducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ?  [storeFreeze] : [];
