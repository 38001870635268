import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { filter, skip, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '@appRoot/core/authentication/services/authentication.service';
import * as authAction from '@appRoot/core/authentication/ngrx-store/actions/authentication.actions';
import { BasicForm } from '@appRoot/core/forms/form-basic.form';
import { ErrorCatcherService } from '@appRoot/error-catcher/error-catcher.service';
import { fadeInAnimation } from "@appRoot/lazy-modules/self-signup/_animations";
import * as selfSignupActions from "@appRoot/lazy-modules/self-signup/ngrx-store/actions/self-signup.actions";
import { ActionsSubject, select, Store } from "@ngrx/store";
import { ofType } from "@ngrx/effects";
import * as customerActions from "@appRoot/core/customer/ngrx-store/actions/customer.actions";
import * as authActions from "src/app/core/authentication/ngrx-store/actions/authentication.actions";
import * as selfSignupSelectors from "@appRoot/lazy-modules/self-signup/ngrx-store/selectors/self-signup.selectors";


@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [ fadeInAnimation ],
    host: {'[@fadeInAnimation]': ''}
})
export class LoginFormComponent extends BasicForm {

    public errorMessage = null;
    public errorShow = false;
    public validationMessages: Map<AbstractControl, {[key:string]: string}>;

    constructor(
        private store: Store<any>,
        private authService: AuthenticationService,
        private fb: FormBuilder,
        private errorCatcherService: ErrorCatcherService,
        private cdr: ChangeDetectorRef,
        private actionsSubject: ActionsSubject,
        ) {
        super();
    }

    ngOnInit(){
        super.ngOnInit();
        this.loadingSubscriber();
        this.errorSubscriber();

        this.validationMessages = new Map([
            [this.username, {
                'required': '',
                'email': 'This field must be a valid email address.',
            }],
            [this.password, {
                'required': '',
            }]
        ]);

        this.actionsSubject.pipe(
            skip(1),
            ofType(authActions.ActionTypes.LOGIN_SUCCESS),
            takeUntil(this.ngUnsubscribe),
        ).subscribe((action: customerActions.UpdateSuccess) => {
            this.store.dispatch(new selfSignupActions.GetUserCustomers(this.username.value));
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    unsubscribe() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    get username() { return this.form.get('username'); }
    get password() { return this.form.get('password'); }

    createForm(){
         this.form = this.fb.group({
            'username': [null, [Validators.required, Validators.email]],
            'password': [null, Validators.required]
        });
    }

    onSubmit(): void {
        super.onSubmit();

        if (this.form.valid) {
            this.errorShow = false;
            this.authService.dispatchLogin(this.username.value, this.password.value);
        }
    }

    private errorSubscriber() {
        this.authService.getError$().pipe(takeUntil(this.ngUnsubscribe), skip(1), filter(e => e !== null)).subscribe(error => {
            if (error.action instanceof authAction.Login && this.errorCatcherService.has(error.instance)) {
                let cError = this.errorCatcherService.get(error.instance);
                this.errorMessage = this.getMessageError(cError.code, cError.message);
                if (this.errorMessage) {
                    this.errorShow = true;
                }
                this.cdr.detectChanges();
            }
        });

        this.store.pipe(
            select(selfSignupSelectors.getError)).pipe(takeUntil(this.ngUnsubscribe), skip(1), filter(e => e !== null)).subscribe(error => {
            if (error.action instanceof selfSignupActions.GetUserCustomers && this.errorCatcherService.has(error.instance)) {
                let cError = this.errorCatcherService.get(error.instance);
                this.errorMessage = this.getMessageError(cError.code, cError.message);
                if (this.errorMessage) {
                    this.errorShow = true;
                }
                this.cdr.detectChanges();
            }
        });
    }

    private getMessageError(code: number, error_message: string): string {
        let message;
        switch (code) {
            case 100003:
                message = 'You need to confirm your account. We have sent you an activation code, please check your email.';
                message += '<br><br>';
                message += 'If you did not receive it click below to send it again.';
                message += '<br>';
                // noinspection HtmlUnknownTarget
                message += '<a data-link="/verify" href="/verify" >Confirm account</a>';
                break;

            case 100404:
                message = error_message;
                this.username.setErrors({ 'invalid': true });
                break;

            case 401:
                message = 'Incorrect password.';
                this.password.setErrors({ 'invalid': true });
                break;

            default:
                message = 'Error! Something went wrong...';
        }
        return message;
    }

    private loadingSubscriber() {
        this.authService.getLoading$().pipe(takeUntil(this.ngUnsubscribe)).subscribe(loading => {
            this.loading = loading;
        });
    }
}
