import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CustomMaterialModule } from '@appRoot/custom-material/custom-material.module';
import { UserInfoTooltipComponent } from '@appRoot/shared/components/user-info/user-info-tooltip-directive/user-info-tooltip.component';
import { CustomerInfoTooltipComponent } from '@appRoot/shared/components/customer-info/customer-info-tooltip-directive/customer-info-tooltip.component';
import { UserInfoTooltipDirective } from '@appRoot/shared/components/user-info/user-info-tooltip-directive/user-info-tooltip.directive';
import { CustomerInfoTooltipDirective } from '@appRoot/shared/components/customer-info/customer-info-tooltip-directive/customer-info-tooltip.directive';
import { UserShortInfoComponent } from '@appRoot/shared/components/user-info/user-short-info/user-short-info.component';
import { CustomerShortInfoComponent } from '@appRoot/shared/components/customer-info/customer-short-info/customer-short-info.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import { TagInputModule } from 'ngx-chips';
import { CountdownModule } from 'ngx-countdown';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxSelectModule } from 'ngx-select-ex';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { NgSlimScrollModule, SLIMSCROLL_DEFAULTS } from 'ngx-slimscroll';
import { ToastrModule } from 'ngx-toastr';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { environment } from '../../environments/environment';

import { Bs4AlertComponent } from './components/bs4-alert/bs4-alert.component';
import { ModalComponent } from './components/modal/modal.component';
import { ModalService } from './components/modal/modal.service';
import { NavItemComponent } from './components/nav-item/nav-item.component';
import { NavItemService } from './components/nav-item/nav-item.service';
import { NavigationBtnComponent } from './components/navigation-btn/navigation-btn.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PermissionsItemComponent } from './components/permissions/permissions-item/permissions-item.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { UserAutocompleteComponent } from './components/user-autocomplete/user-autocomplete.component';
import { CustomerAutocompleteComponent } from './components/customer-autocomplete/customer-autocomplete.component';
import { UserRenderInfoComponent } from './components/user-info/user-render-info/user-render-info.component';
import { FieldErrorDirective } from './directives/field-error/field-error.directive';
import { NowDirective } from './directives/now/now.directive';
import { routeLinkDirective } from './directives/route-link/route-link.directive';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from "ng-recaptcha";
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ThousandSeparatorDirective } from './directives/thousand-separator/thousand-separator.directive';

import { SafeHtmlPipe } from './pipes/safehtml.pipe';
import { BytesPipe } from './pipes/bytes.pipe';

import { CustomerRenderInfoComponent } from "@appRoot/shared/components/customer-info/customer-render-info/customer-render-info.component";


export function getTooltipConfig(): TooltipConfig {
    return Object.assign(new TooltipConfig(),
        {
            placement: 'top',
        }
    );
}


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AlertModule.forRoot(),
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        TooltipModule.forRoot(),
        ProgressbarModule.forRoot(),
        CollapseModule.forRoot(),
        ModalModule.forRoot(),
        FontAwesomeModule,
        ToastrModule.forRoot({
            toastComponent: ToastMessageComponent,
            timeOut: 15000,
            enableHtml: true,
            progressBar: true,
        }),
        SweetAlert2Module.forRoot({
            confirmButtonClass: 'btn btn-lg mr-2 ml-2 btn-success',
            cancelButtonClass: 'btn btn-lg mr-2 ml-2 btn-danger',
            buttonsStyling: false,
        }),
        NgxPermissionsModule.forChild(),
        NgxSelectModule,
        RouterModule,
        ShowHidePasswordModule,
        TagInputModule,
        NgSlimScrollModule,
        CustomMaterialModule,
        CountdownModule,
        CreditCardDirectivesModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        ClipboardModule
    ],
    declarations: [
        NowDirective,
        SafeHtmlPipe,
        BytesPipe,
        routeLinkDirective,
        NavItemComponent,
        ModalComponent,
        FieldErrorDirective,
        PreloaderComponent,
        Bs4AlertComponent,
        PaginatorComponent,
        NavigationBtnComponent,
        UserAutocompleteComponent,
        CustomerAutocompleteComponent,
        CustomerRenderInfoComponent,
        UserInfoTooltipComponent,
        CustomerInfoTooltipComponent,
        UserShortInfoComponent,
        CustomerShortInfoComponent,
        UserInfoTooltipDirective,
        CustomerInfoTooltipDirective,
        UserRenderInfoComponent,
        PermissionsItemComponent,
        ToastMessageComponent,
        ThousandSeparatorDirective
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        AlertModule,
        BsDropdownModule,
        TabsModule,
        TooltipModule,
        ProgressbarModule,
        CollapseModule,
        ModalModule,
        NowDirective,
        SafeHtmlPipe,
        BytesPipe,
        routeLinkDirective,
        NavItemComponent,
        ModalComponent,
        FontAwesomeModule,
        NgxPermissionsModule,
        FieldErrorDirective,
        PreloaderComponent,
        Bs4AlertComponent,
        PaginatorComponent,
        NavigationBtnComponent,
        NgxSelectModule,
        ShowHidePasswordModule,
        TagInputModule,
        NgSlimScrollModule,
        UserAutocompleteComponent,
        CustomerAutocompleteComponent,
        CustomerRenderInfoComponent,
        CustomMaterialModule,
        UserShortInfoComponent,
        CustomerShortInfoComponent,
        UserInfoTooltipDirective,
        CustomerInfoTooltipDirective,
        UserRenderInfoComponent,
        PermissionsItemComponent,
        CountdownModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        ClipboardModule,
        ThousandSeparatorDirective
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                NavItemService,
                ModalService,
                {
                    provide: TooltipConfig,
                    useFactory: getTooltipConfig
                },
                {
                    provide: SLIMSCROLL_DEFAULTS,
                    useValue: {
                        position: "right",
                        barBackground: "#000",
                        barOpacity: "0.35",
                        barWidth: "7",
                        barBorderRadius: "1",
                        barMargin: "0",
                        gridBackground: "#000",
                        gridOpacity: "0.15",
                        gridWidth: "7",
                        gridBorderRadius: "0",
                        gridMargin: "0",
                        alwaysVisible: false,
                    }
                },
                {
                    provide: RECAPTCHA_SETTINGS,
                    useValue: {
                        siteKey: environment.recaptcha.siteKey
                    } as RecaptchaSettings
                }
            ]
        };
    }

    constructor(library: FaIconLibrary) {
        //fontawesome library
        library.addIconPacks(fas,
            far
        );
    }
}
