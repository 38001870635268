export interface IRoleResponse {
    id: number;
    name: string;
    label: string;
    description: string;
    permission_ids: number[];
    created_at: number;
    updated_at: number;
}

export class Role {
    id: number;
    name: string;
    label: string;
    description: string;
    permission_ids: number[] = [];
    changed: boolean = false;

    constructor(response?: IRoleResponse){
        if(response){
            this.id = +response.id;
            this.name = response.name;
            this.label = response.label;
            this.description = response.description;
            this.permission_ids = response.permission_ids;
        }
    }
}