<div class="wrapper">
    <div class="block-center mt-4 wd-xl">
        <!-- START card-->
        <div class="card card-flat">
            <div class="card-header text-center bg-dark">
                <a href="#">
                    <img class="block-center rounded" src="{{settings.app.logo.default}}" alt="Image" />
                </a>
            </div>
            <div class="card-body">
                <p class="text-center py-2 text-uppercase"> </p>


                <br><br>
                <a *ngIf="settings.registrationMethod === 'public'" class="btn btn-block btn-secondary" [routerLink]="'/register'">{{ 'Sign Up' | translate }}</a>
                <a class="btn btn-block btn-secondary" [routerLink]="'/login'">{{ 'Sign in' | translate }}</a>

            </div>
        </div>
        <!-- END card-->
        <div class="p-3 text-center">
            <span>&copy;</span>
            <span>{{ settings.app.year }}</span>
            <span class="mx-2">-</span>
            <span>{{ settings.app.name }}</span>
            <br/>
            <span>{{ settings.app.description }}</span>
        </div>
    </div>
</div>
