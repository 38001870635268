import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { of, Subject } from 'rxjs';
import * as customerSelectors from '@appRoot/core/customer/ngrx-store/selectors/customer.selectors';
import * as customerActions from '@appRoot/core/customer/ngrx-store/actions/customer.actions';
import {
    concatMap,
    delay,
    distinctUntilChanged,
    filter,
    switchMap,
    take,
    takeWhile,
    tap,
    withLatestFrom
} from 'rxjs/operators';


@Injectable({
    providedIn: 'root',
})
export class CustomerInfoService {
    public toggleAllTooltips$: Subject<'show' | 'hide'> = new Subject();

    private customerLoader = new Subject<number>();
    private customerSubscriberCreated = false;

    constructor(
        private store: Store<any>
    ){}

    public loadCustomer(id: number){
        if(!this.customerSubscriberCreated){
            this.customerSubscriberCreated = true;
            this.customerLoader.pipe(
                distinctUntilChanged(),
                concatMap(x => of(x)
                    .pipe(
                        delay(10),
                        switchMap(id => this.store.pipe(select(customerSelectors.getGetting) ),(id, loading) => ({id, loading}) ),
                        filter(({id, loading}) => !loading),
                        withLatestFrom( this.store.pipe(select(customerSelectors.getCustomerById(x))), ({ id, loading }, account) => ({id, loading, account})),
                        takeWhile(({id, loading, account}) => !account),
                        tap(({id, account}) => {
                            this.store.dispatch( new customerActions.GetById(id) )
                        }),
                        take(1),
                    ))
            ).subscribe();
        }
        this.customerLoader.next(id);
    }
}
