import { Type } from "../models/Type";

export interface IPackagesResponse {
    id: number;
    name: string;
    description: string;
    service_types: string[];
    item_code: string;
    product_description: string;
    price: string;
    dk_product_id: number;
    type: keyof typeof Type;
}

export class Packages {
    id: number = null;
    name: string = null;
    description: string = null;
    service_types: string[] = [];
    item_code: string = null;
    product_description: string = null;
    price: string = null;
    dk_product_id: number = null;
    type: keyof typeof Type = Type.public;

    constructor(data?: IPackagesResponse) {
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.description = data.description;
            this.service_types = data.service_types;
            this.item_code = data.item_code;
            this.product_description = data.product_description;
            this.price = data.price;
            this.dk_product_id = data.dk_product_id;
            this.type = data.type;
        }
    }
}