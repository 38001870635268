import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPaginatedResponse } from '@appRoot/core/interfaces';
import { SettingsService } from '@appRoot/core/services/settings.service';
import { isBoolean, isEmpty, isNumber } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    ICustomerGroupsRequestIndexParams,
    ICustomerGroupsResponse,
    ICustomerGroupsRequestShowParams,
    ICustomerGroupsRequestUpdateRequiredParams,
    ICustomerGroupsRequestUpdate,
    ICustomerGroupsRequestImportFormDK,
    ICustomerGroupsChangeNotificationRequestIndexParams,
    ICustomerGroupsChangeNotificationResponse,
    ICustomerGroupsChangeNotificationRequestUpdateRequiredParams,
    ICustomerGroupsChangeNotificationRequestUpdate,
    ICustomerGroupsChangeNotificationMainRequestIndexParams,
    ICustomerGroupsChangeNotificationMainResponse,
    ICustomerGroupsChangeNotificationMainRequestUpdateRequiredParams,
    ICustomerGroupsChangeNotificationMainRequestUpdate
} from '../models';


@Injectable()
export class CustomerGroupsHttpService {

    public readonly edge: string = 'customer-groups';

    constructor(
        private http: HttpClient,
        private settings: SettingsService,
    ) { }

    fetch(data: ICustomerGroupsRequestIndexParams): Observable<IPaginatedResponse<ICustomerGroupsResponse[]>> {
        let params = new HttpParams();

        params = params.append('uid', ''+data.uid);
        params = data.page ? params.append('page[number]', ''+data.page) : params;
        params = data.size ? params.append('page[size]', ''+data.size) : params;
        params = data.sort ? params.append('sort', data.sort) : params;
        params = data.order ? params.append('order',data.order) : params;

        if (data.search) {
            for (let prop in data.search) {
                // noinspection JSUnfilteredForInLoop
                let value = isBoolean(data.search[prop]) ? +data.search[prop] : data.search[prop];
                params = !isNumber(value) && isEmpty(value) ? params : params.append('search[' + prop + ']', ''+value);
            }
        }

        return this.http.get<{ data: IPaginatedResponse<ICustomerGroupsResponse[]> }>(`${this.settings.API_PATH}/${this.edge}`, {params: params}).pipe(
            map(response => response.data),
        );
    }

    show(data: ICustomerGroupsRequestShowParams): Observable<ICustomerGroupsResponse> {
        let params = new HttpParams({ fromObject: <any>{ ...data } });
        return this.http.get<{ data: ICustomerGroupsResponse }>(`${this.settings.API_PATH}/${this.edge}/${data.group_id}`, {params: params}).pipe(
            map(response => {
                return response.data
            }),
        );
    }

    update(data: ICustomerGroupsRequestUpdateRequiredParams, changes: ICustomerGroupsRequestUpdate): Observable<ICustomerGroupsResponse> {
        return this.http.patch<{ data: ICustomerGroupsResponse }>(`${this.settings.API_PATH}/${this.edge}/${data.group_id}`, {...data, ...changes}).pipe(
            map(response => (response.data)),
        );
    }

    importGroupsFormDK(data: ICustomerGroupsRequestImportFormDK): Observable<boolean> {
        return this.http.post<{ data: boolean }>(`${this.settings.API_PATH}/${this.edge}/groups-dk-import`, data).pipe(
            map(response => response.data),
        );
    }

    getChangeNotifications(data: ICustomerGroupsChangeNotificationRequestIndexParams): Observable<ICustomerGroupsChangeNotificationResponse[]> {
        let params = new HttpParams({ fromObject: <any>{ ...data } });
        return this.http.get<{ data: ICustomerGroupsChangeNotificationResponse[] }>(`${this.settings.API_PATH}/${this.edge}/change-notifications`, {params: params}).pipe(
            map(response => response.data),
        );
    }

    updateChangeNotifications(data: ICustomerGroupsChangeNotificationRequestUpdateRequiredParams, changes: ICustomerGroupsChangeNotificationRequestUpdate): Observable<ICustomerGroupsChangeNotificationResponse> {
        return this.http.post<{ data: ICustomerGroupsChangeNotificationResponse }>(`${this.settings.API_PATH}/${this.edge}/update-change-notifications`, {...data, ...changes}).pipe(
            map(response => (response.data)),
        );
    }

    getChangeNotificationsMain(data: ICustomerGroupsChangeNotificationMainRequestIndexParams): Observable<ICustomerGroupsChangeNotificationMainResponse> {
        let params = new HttpParams({ fromObject: <any>{ ...data } });
        return this.http.get<{ data: ICustomerGroupsChangeNotificationMainResponse }>(`${this.settings.API_PATH}/${this.edge}/change-notifications-main`, {params: params}).pipe(
            map(response => response.data),
        );
    }

    updateChangeNotificationMain(data: ICustomerGroupsChangeNotificationMainRequestUpdateRequiredParams, changes: ICustomerGroupsChangeNotificationMainRequestUpdate): Observable<ICustomerGroupsChangeNotificationMainResponse> {
        return this.http.post<{ data: ICustomerGroupsChangeNotificationMainResponse }>(`${this.settings.API_PATH}/${this.edge}/update-change-notifications-main`, {...data, ...changes}).pipe(
            map(response => (response.data)),
        );
    }
}

