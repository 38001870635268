import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '@appRoot/core/services/settings.service';
import { NavItem } from './NavItem';
import { User } from "@appRoot/core/user/models";
import { UserService } from "@appRoot/core/user/services/user.service";
import { Subject, Subscription } from "rxjs";
import { select, Store } from "@ngrx/store";
import { ROUTE_PATHS } from '@appRoot/routes/routes.names';
import { takeUntil } from "rxjs/operators";
import * as availableDataSelectors from "@appRoot/core/available-data/ngrx-store/selectors/available-data.selectors";
import { AvailableData } from '@appRoot/core/available-data/models';


@Component({
    selector: 'app-nav-item',
    templateUrl: './nav-item.component.html',
    styleUrls: ['./nav-item.component.scss'],
    animations: [
        trigger('openClose', [
            state('close', style({
                height: '0px',
            })),
            transition('close => open', group([
                style({ opacity: 0, transform: 'translateX(-18px)' }),
                animate('.4s ease', style({ opacity: 1, transform: 'none' })),
                animate('.2s ease', style({ height: '*' }))
            ])),
            transition('open => close', animate('.2s ease')),
        ])
    ]
})
export class NavItemComponent implements OnInit {

    readonly ngUnsubscribe = new Subject();

    private _currentState: Array<number> = [];
    private userSbn: Subscription;

    public user: User;

    public customerLinkName: string = ROUTE_PATHS.CustomersModule;
    public shareLinkName: string = 'shared';
    public availableData: AvailableData;

    router: Router;

    @Input() public navItems: NavItem[];
    @Input() public level: number = 0;
    @Input() public index: number = -1;
    @Input() public openClose: boolean = false;
    @Input()
    set currentState(newState) {
        this.toggleNav(newState[this.level] == this.index);
        this._currentState = newState;
    }
    get currentState() { return this._currentState };

    constructor(
        public settings: SettingsService,
        public injector: Injector,
        private userService: UserService,
        private store: Store<any>,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.getAvailableData();

        this.router = this.injector.get(Router);

        this.router.events.subscribe((val) => {
            window.scrollTo(0, 0); // scroll view to top
            this.settings.layout.asideToggled = false; // close sidebar on route change
        });

        this.userSbn = this.userService.activeUser$.subscribe(user => {
            this.user = user;
        });
    }

    ngOnDestroy(){
        this.userSbn.unsubscribe();
    }

    public toggleSubmenuClick(event, index, level) {
        event.stopPropagation();
        event.preventDefault();
        if (!this.isSidebarCollapsed() && !this.isSidebarCollapsedText() && !this.isEnabledHover()) {
            // Create new array is important for detecting changes - array should be another
            let newState = [...this.currentState];

            // If navItem is not clicked twice - show it
            if (newState[level + 1] !== index) {
                newState[level + 1] = index;
            } else {
                // Cut the array - to close current item and all of children navItems
                newState = newState.slice(0, level + 1);
            }

            this.currentState = newState;
        }
    }

    public toggleSubmenuHover(event, index, level) {
        event.stopPropagation();
        if (this.isSidebarCollapsed() || this.isSidebarCollapsedText() || this.isEnabledHover()) {
            // Create new array is important for detecting changes - array should be another
            let newState = [...this.currentState];

            // If navItem is not clicked twice - show it
            if (newState[level + 1] !== index) {
                newState[level + 1] = index;
            } else {
                // Cut the array - to close current item and all of children navItems
                newState = newState.slice(0, level + 1);
            }

            this.currentState = newState;
        }
    }

    private toggleNav(open: boolean) {
        this.openClose = open;
    }

    private isSidebarCollapsed() {
        return this.settings.layout.isCollapsed;
    }
    private isSidebarCollapsedText() {
        return this.settings.layout.isCollapsedText;
    }
    private isEnabledHover() {
        return this.settings.layout.asideHover;
    }

    private getAvailableData() {
        this.store.pipe(
            select(availableDataSelectors.getData),
            takeUntil(this.ngUnsubscribe),
        ).subscribe((e) => {
            this.availableData = e;
            this.cdr.markForCheck();
        });
    }

    singleCustomer(link) {
        if (this.availableData && this.availableData.customer_id) {
            return '/'+ROUTE_PATHS.CustomersModule+'/'+this.availableData.customer_id;
        } else {
            return link;
        }
    }

    userIdReplace(link) {
        let new_link = link;

        this.userService.activeUser$.subscribe(user => {
            if(!user) return;

            let regEx = new RegExp(':userId', 'gi');
            new_link = new_link.replace(regEx, user.id);
        });

        return new_link;
    }
}
