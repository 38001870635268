import { IPaginatedResponse } from '@appRoot/core/interfaces';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Actions, ActionTypes } from '../actions/user.actions';


export interface State {
    loading: boolean;
    complete: boolean,
    getting_current: boolean;
    creating: boolean;
    getting: boolean;
    updating: boolean;
    deleting: boolean;
    restoring: boolean;
    user: number; // session user id
    activeUser: number; // active user id
    error: NGRXError<Actions>;
    pagination: IPaginatedResponse<number[]>;
}

export const initialState: State = {
    loading: false,
    complete: false,
    getting_current: false,
    creating: false,
    getting: false,
    updating: false,
    deleting: false,
    restoring: false,
    user: null,
    activeUser: null,
    error: null,
    pagination: null,
};

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {

        case ActionTypes.ERROR:
            return {
                ...state,
                error: action.payload
            };

        case ActionTypes.RESET:
            return {
                ...initialState
            };

        case ActionTypes.LOAD:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.LOAD_SUCCESS:
        case ActionTypes.LOAD_FAILED:
            return {
                ...state,
                loading: false,
                complete: true,
            };
        case ActionTypes.RELOAD:
            return {
                ...state,
                complete: false,
            };
        case ActionTypes.PAGINATION_SET:
            return {
                ...state,
                pagination: action.payload,
            };

        case ActionTypes.GET_CURRENT:
            return {
                ...state,
                getting_current: true,
            };
        case ActionTypes.GET_CURRENT_SUCCESS:
            return {
                ...state,
                user: action.payload.user.id,
                getting_current: false,
            };
        case ActionTypes.GET_CURRENT_FAILED:
            return {
                ...state,
                getting_current: false,
            };

        case ActionTypes.SET_ACTIVE_USER:
            return {
                ...state,
                activeUser: action.payload.user.id
            };

        case ActionTypes.CREATE:
            return {
                ...state,
                creating: true,
            };
        case ActionTypes.CREATE_SUCCESS:
        case ActionTypes.CREATE_FAILED:
            return {
                ...state,
                creating: false,
            };

        case ActionTypes.GET_BY_ID:
            return {
                ...state,
                getting: true,
            };
        case ActionTypes.GET_BY_ID_SUCCESS:
        case ActionTypes.GET_BY_ID_FAILED:
            return {
                ...state,
                getting: false,
            };

        case ActionTypes.UPDATE:
        case ActionTypes.CHANGE_EMAIL:
        case ActionTypes.CHANGE_PASSWORD:
            return {
                ...state,
                updating: true,
            };
        case ActionTypes.UPDATE_SUCCESS:
        case ActionTypes.UPDATE_FAILED:
            return {
                ...state,
                updating: false,
            };

        case ActionTypes.DELETE:
        case ActionTypes.DELETE_SELF:
            return {
                ...state,
                deleting: true,
            };
        case ActionTypes.DELETE_SUCCESS:
        case ActionTypes.DELETE_FAILED:
        case ActionTypes.DELETE_SELF_SUCCESS:
        case ActionTypes.DELETE_SELF_FAILED:
            return {
                ...state,
                deleting: false,
            };

        case ActionTypes.RESTORE:
        case ActionTypes.RESTORE_SELF:
            return {
                ...state,
                restoring: true,
            };
        case ActionTypes.RESTORE_SUCCESS:
        case ActionTypes.RESTORE_FAILED:
        case ActionTypes.RESTORE_SELF_SUCCESS:
        case ActionTypes.RESTORE_SELF_FAILED:
            return {
                ...state,
                restoring: false,
            };

        default:
            return state;
    }
}
