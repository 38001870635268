import { Action } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

export enum ActionTypes {
	ADD = '[NAVIGATION] add',
	BACK = '[NAVIGATION] back',
	FORWARD = '[NAVIGATION] forward',
	UP = '[NAVIGATION] upper level',
	GO = '[NAVIGATION] GO',
}

export class Add implements Action {
	readonly type = ActionTypes.ADD;
	constructor(readonly payload: {url: string}) {}
}

export class Back implements Action {
	readonly type = ActionTypes.BACK;
}

export class Forward implements Action {
	readonly type = ActionTypes.FORWARD;
}

export class Up implements Action {
	readonly type = ActionTypes.UP;
}

export class Go implements Action {
	readonly type = ActionTypes.GO;
	constructor(
		public payload: {
			path: any[];
			query?: object;
			extras?: NavigationExtras;
		}
	) {}
}

export type Actions = Add | Back | Forward | Up | Go
