import { OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { Event } from '@angular/router';
import { Subject } from 'rxjs';
import { NgUnsubscribe } from "@appRoot/core/interfaces";


export abstract class BasicForm implements OnInit, OnDestroy, NgUnsubscribe {

    public form: FormGroup;

    public loading: boolean | string;

    readonly ngUnsubscribe = new Subject();

    validationMessages: Map<AbstractControl, { [key: string]: string }> = new Map;

    abstract createForm(): void;

    ngOnInit() {
        this.loading = false;
        this.createForm();
    }

    ngOnDestroy() {
        this.unsubscribe();
    }

    unsubscribe(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onSubmit(event?: Event): void {
        Object.keys(this.form.controls).forEach((field: string) => {
            const control = this.form.get(field);
            this.validateAllFormFields(control);
        });
    }

    isFieldInvalid(field: AbstractControl): boolean {
        return (field.touched && field.invalid);
    }

    setValidationMessages(c: AbstractControl, m: { [key: string]: string }) {
        this.validationMessages.set(c, m);
    }

    protected validateAllFormFields(control: AbstractControl) {
        if (control instanceof FormControl) {
            control.markAsTouched({onlySelf: true});
        } else if (control instanceof FormGroup) {
            Object.keys(control.controls).forEach((field: string) => {
                const groupControl = control.get(field);
                this.validateAllFormFields(groupControl);
            });
        } else if (control instanceof FormArray) {
            const controlAsFormArray = control as FormArray;
            controlAsFormArray.controls.forEach((arrayControl: AbstractControl) => this.validateAllFormFields(arrayControl));
        }
    }

    protected getControlName(control: AbstractControl): string {
        for (let controlName in control.parent.controls) {
            // noinspection JSUnfilteredForInLoop
            if (control.parent.controls[controlName] === control) {
                // noinspection JSUnfilteredForInLoop
                return controlName;
            }
        }
        return null;
    }

}
