import { Injectable }    from '@angular/core';
import { ToastrService }         from 'ngx-toastr';
import { IndividualConfig }      from 'ngx-toastr/toastr/toastr-config';
import { ActiveToast }           from 'ngx-toastr/toastr/toastr.service';
import { ToastMessageComponent } from './toast-message.component';

@Injectable({
    providedIn: 'root'
})
export class ToastMessageService {

    constructor(private toastr: ToastrService) { }

    show({message, title, options , type }: {message: string, title: string, options: Partial<IndividualConfig>, type: string}): ActiveToast<any> {
        return this.toastr.show(message,  title, options, type);
    }

    success({title, message}: {message: string, title: string}): ActiveToast<ToastMessageComponent>{
        return this.toastr.success(message,  title);
    }

    error({title, message}: {message: string, title: string}): ActiveToast<ToastMessageComponent>{
        return this.toastr.error(message,  title);
    }

    info({title, message}: {message: string, title: string}): ActiveToast<ToastMessageComponent> {
        return this.toastr.info(message,  title);
    }

    warning({title, message}: {message: string, title: string}): ActiveToast<ToastMessageComponent> {
        return this.toastr.warning(message,  title);
    }

}
