import { Injectable } from '@angular/core';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { isEmpty, pickBy } from 'lodash';
import { Observable } from 'rxjs';
import {catchError, filter, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {
    CustomerMailTemplate,
    ICustomerMailTemplateRequestUpdate,
    ICustomerMailTemplateRequestCreate
} from '../../models';
import { User } from '@appRoot/core/user/models';
import { CustomerMailTemplatesHttpService } from '../../services/customer-mail-templates-http.service';
import { UserService } from '@appRoot/core/user/services/user.service';
import * as customerMailTemplatesActions from "@appRoot/core/customer/ngrx-store/actions/customer-mail-templates.actions";
import * as customerMailTemplatesSelectors from "@appRoot/core/customer/ngrx-store/selectors/customer-mail-templates.selectors";


@Injectable()
export class CustomerMailTemplatesEffects {

    constructor(
        private actions$: Actions,
        private httpService: CustomerMailTemplatesHttpService,
        private userService: UserService,
        private store: Store<any>,
    ) {}

    @Effect()
    load$: Observable<Action> = this.actions$.pipe(
        ofType(customerMailTemplatesActions.ActionTypes.GET),
        withLatestFrom(
            this.userService.activeUser$,
            this.store.select(customerMailTemplatesSelectors.getLoaded),
            this.store.select(customerMailTemplatesSelectors.getTemplatesArray),
            this.store.select(customerMailTemplatesSelectors.getVariables),
        ),
        filter(([action, user, loaded, entities, variables]: [customerMailTemplatesActions.Get, User, boolean, CustomerMailTemplate[], string[]]) => !!user),
        switchMap(([action, user, loaded, entities, variables]) => {
            if (loaded) {
                return [
                    new customerMailTemplatesActions.GetSuccess({
                        variables: variables,
                        templates: entities,
                    }),
                ];
            }

            return this.httpService.fetch({uid: user.id})
                .pipe(
                    switchMap(data => [
                        new customerMailTemplatesActions.GetSuccess({
                            variables: data.variables,
                            templates: data.templates,
                        })
                    ]),
                    catchError(error => [
                        new customerMailTemplatesActions.GetFailed(),
                        new customerMailTemplatesActions.Error(new NGRXError(action, error)),
                    ]),
                );
        }),
    );

    @Effect()
    create$: Observable<Action> = this.actions$.pipe(
        ofType(customerMailTemplatesActions.ActionTypes.CREATE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerMailTemplatesActions.Create, User]) => !!user),
        mergeMap(([action, user]) => {
                let params: ICustomerMailTemplateRequestCreate = {
                    uid: user.id,
                    subject: action.payload.notification.subject,
                    text_template: action.payload.notification.text_template,
                    html_template: action.payload.notification.html_template
                };
                return this.httpService.create(params).pipe(
                    switchMap(template => [
                        new customerMailTemplatesActions.CreateSuccess({ notification: new CustomerMailTemplate(template) })
                    ]),
                    catchError(error => [
                        new customerMailTemplatesActions.Error(new NGRXError(action, error)),
                        new customerMailTemplatesActions.CreateFailed
                    ])
                );
            }
        )
    );

    @Effect()
    update$: Observable<Action> = this.actions$.pipe(
        ofType(customerMailTemplatesActions.ActionTypes.UPDATE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerMailTemplatesActions.Update, User]) => !!user),
        switchMap(([action, user]) => {
            let data: ICustomerMailTemplateRequestUpdate = {};

            if (action instanceof customerMailTemplatesActions.Update) {
                data.subject = action.payload.notification.subject && (action.payload.notification.subject !== action.payload.changes.notification.subject)
                    ? action.payload.changes.notification.subject
                    : undefined;
                data.html_template = action.payload.notification.html_template && (action.payload.notification.html_template !== action.payload.changes.notification.html_template)
                    ? action.payload.changes.notification.html_template
                    : undefined;
                data.text_template = action.payload.notification.text_template && (action.payload.notification.text_template !== action.payload.changes.notification.text_template)
                    ? action.payload.changes.notification.text_template
                    : undefined;
                data.status = (action.payload.notification.status !== action.payload.changes.notification.status)
                    ? action.payload.changes.notification.status
                    : undefined;
            }

            const cleanedData = pickBy(data, v => v !== undefined);
            if (isEmpty(cleanedData)) {
                return [new customerMailTemplatesActions.UpdateFailed];
            }

            return this.httpService.update({uid: user.id, template_id: action.payload.notification.id}, cleanedData).pipe(
                switchMap((response) => {
                    let template = new CustomerMailTemplate(response);

                    return [
                        new customerMailTemplatesActions.UpdateSuccess({ notification: template }),
                    ];
                }),
                catchError(error => [
                    new customerMailTemplatesActions.Error(new NGRXError(action, error)),
                    new customerMailTemplatesActions.UpdateFailed,
                ])
            );
        }),
    );

    @Effect()
    remove$: Observable<Action> = this.actions$.pipe(
        ofType(customerMailTemplatesActions.ActionTypes.DELETE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerMailTemplatesActions.Delete, User]) => !!user),
        mergeMap( ([action, user]) => {
            return this.httpService.delete(action.payload.notification.id, {uid: user.id, template_id: action.payload.notification.id}).pipe(
                switchMap(response => {
                    return [
                        new customerMailTemplatesActions.DeleteSuccess({notification: action.payload.notification})
                    ];
                }),
                catchError(error => [
                    new customerMailTemplatesActions.Error(new NGRXError(action, error)),
                    new customerMailTemplatesActions.DeleteFailed()
                ]),
            );
        }),
    );
}