import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { BasicForm } from '@appRoot/core/forms/form-basic.form';
import { ActionsSubject, Store } from "@ngrx/store";
import * as selfSignupActions from "../../../ngrx-store/actions/self-signup.actions";
import { ISelfSignupRequestStoreCustomerParams } from "../../../models";
import { fadeInAnimation } from '../../../_animations';
import { CustomValidators } from "ngx-custom-validators";
import { ValidatorService } from "@appRoot/core/services/validator.service";
import * as selfSignupAccountsActions from "@appRoot/lazy-modules/self-signup/ngrx-store/actions/self-signup-accounts.actions";


@Component({
    selector: 'app-customer-register-form',
    templateUrl: './customer-register-form.component.html',
    styleUrls: ['./customer-register-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [ fadeInAnimation ],
    host: {'[@fadeInAnimation]': ''}
})
export class CustomerRegisterFormComponent extends BasicForm implements OnInit {

    public validationMessages: Map<AbstractControl, {[key:string]: string}>;

    constructor(
        private store: Store<any>,
        private cdr: ChangeDetectorRef,
        private actionsSubject: ActionsSubject,
        private validatorService: ValidatorService
    ) {
        super();
    }

    get ssidCtrl() { return this.form.get('ssid') as FormControl; }
    get nameCtrl() { return this.form.get('name') as FormControl; }
    get emailCtrl() { return this.form.get('email') as FormControl; }
    get phoneCtrl() { return this.form.get('phone') as FormControl; }
    get addressCtrl() { return this.form.get('address') as FormControl; }
    get cityCtrl() { return this.form.get('city') as FormControl; }
    get postCodeCtrl() { return this.form.get('postCode') as FormControl; }
    get recaptchaCtrl() { return this.form.get('recaptcha') as FormControl; }

    ngOnInit() {
        super.ngOnInit();
        this.createValidationMessages();
    }

    createForm() {
        // const ssid = new FormControl('', [Validators.required, CustomValidators.number, ValidatorService.isSSIDValid], [this.validatorService.isSSIDExist(3)]);
        const ssid = new FormControl('', [Validators.required]);
        const name = new FormControl('', [Validators.required, Validators.maxLength(100)]);
        const email = new FormControl('', [Validators.required, CustomValidators.email], this.validatorService.checkEmailNotTaken(3));
        const phone = new FormControl('',[Validators.required]);
        const address = new FormControl();
        const city = new FormControl();
        const postCode = new FormControl();
        const recaptcha = new FormControl(null, [Validators.required]);

        this.form = new FormGroup({
            ssid: ssid,
            name: name,
            email: email,
            phone: phone,
            address: address,
            city: city,
            postCode: postCode,
            recaptcha: recaptcha
        });
    }

    onSubmit() {
        super.onSubmit();

        if (this.form.valid) {
            this.loading = true;

            let newCustomer: ISelfSignupRequestStoreCustomerParams = {
                number: this.ssidCtrl.value,
                name: this.nameCtrl.value,
                contact_email: this.emailCtrl.value,
                phone: this.phoneCtrl.value,
                address: this.addressCtrl.value,
                city: this.cityCtrl.value,
                postal_code: this.postCodeCtrl.value
            };

            this.store.dispatch(new selfSignupActions.StepPayment());
            this.store.dispatch(new selfSignupAccountsActions.SetCustomer(newCustomer));
            this.store.dispatch(new selfSignupAccountsActions.SetCustomerNumber(this.ssidCtrl.value));
        }
    }

    private createValidationMessages(){
        this.validationMessages = new Map([
            [this.nameCtrl, {
                'required': 'Name is required.',
            }],
            [this.emailCtrl, {
                'required': 'Email is required.',
                'email': 'Email must be a valid email address.',
                "emailIsTaken": "The email address is already taken.",
            }],
            [this.phoneCtrl, {
                'required': 'Phone Number is required.',
                'number': 'Phone Number must be numeric.'
            }],

        ]);

        if(this.ssidCtrl) {
            this.validationMessages.set(this.ssidCtrl, {
                'required': 'Customer ID is required.',
                'ssid': 'Customer ID is not valid.',
                'SSIDExists': 'Customer ID is already taken.',
                'number': 'Customer ID must be numeric.',
            });
        }
    }
}
