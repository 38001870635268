import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CustomerSystemMessages } from '../../models';
import { Actions, ActionTypes } from '../actions/customer-system-messages.actions';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';


function selectId(entity: CustomerSystemMessages): number {
    return entity.id;
}

export const adapter: EntityAdapter<CustomerSystemMessages> = createEntityAdapter<CustomerSystemMessages>({
    selectId: selectId,
    sortComparer: false,
});

export interface State extends EntityState<CustomerSystemMessages> {
    loading: boolean;
    error: NGRXError<Actions>;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null,
});

export const entitySelectors = adapter.getSelectors();

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {

        case ActionTypes.GET:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.GET_SUCCESS:
            return adapter.upsertMany(action.payload, {
                ...state,
                loading: false,
            });

        case ActionTypes.ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case ActionTypes.UPSERT_MANY:
            return adapter.upsertMany(action.payload, {
                ...state,
            });

        case ActionTypes.REMOVE_MANY_BY_CUSTOMER_IDS: {
            let ids = (<number[]>state.ids).map((id: any) => (state.entities)[id])
                .filter(e => !!~action.payload.indexOf(e.customer_id) )
                .map(e => e.id);

            return adapter.removeMany(ids, {
                ...state,
            });
        }
        default:
            return state;
    }
}
