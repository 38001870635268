import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPaginatedResponse } from '@appRoot/core/interfaces';
import { SettingsService } from '@appRoot/core/services/settings.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    IRoleRequestDestroyParams,
    IRoleRequestIndexParams,
    IRoleRequestShowParams,
    IRoleRequestStoreParams,
    IRoleRequestUpdateParams,
    IRoleResponse,
    Role
} from '../models';


@Injectable()
export class RoleHttpService {

    public readonly edge: string = 'roles';

    constructor(
        private http: HttpClient,
        private settings: SettingsService,
    ) { }

    fetch({uid, sort, order, page, size, search, ids}: IRoleRequestIndexParams): Observable<Role[]> {
        let params = new HttpParams();
        params = params.append('uid', '' + uid);
        params = page ? params.append('page[number]', '' + page) : params;
        params = size ? params.append('page[size]', '' + size) : params;
        params = search ? params.append('search', search) : params;
        params = sort ? params.append('sort', sort) : params;
        params = order ? params.append('order', order) : params;
        params = ids ? params.append('ids', ids.join(',')) : params;

        return this.http.get<{ data: IPaginatedResponse<IRoleResponse[]> }>(`${this.settings.API_PATH}/${this.edge}`,
            {params: params}
        )
        .pipe(
            map(response => {
                return response.data.data.map(e => new Role(e));
            })
        );
    }

    store(requestData: IRoleRequestStoreParams): Observable<Role> {
        return this.http.post<{ data: IRoleResponse }>(`${this.settings.API_PATH}/${this.edge}`, requestData)
        .pipe(
            map(response => new Role(response.data))
        );
    }

    show(id: number, requestData: IRoleRequestShowParams): Observable<Role> {
        let params = new HttpParams({fromObject: <any>{...requestData}});

        return this.http.get<{ data: IRoleResponse }>(`${this.settings.API_PATH}/${this.edge}/${id}`, {params: params})
        .pipe(
            map(response => new Role(response.data))
        );
    }

    update(id: number, requestData: IRoleRequestUpdateParams): Observable<Role> {
        return this.http.patch<{ data: IRoleResponse }>(`${this.settings.API_PATH}/${this.edge}/${id}`, requestData)
        .pipe(
            map(response => new Role(response.data))
        );
    }

    destroy(ids: number | number[], requestData: IRoleRequestDestroyParams): Observable<number> {
        let passIds = Array.isArray(ids) ? ids.join(',') : ids;
        let params = new HttpParams({fromObject: <any>{...requestData}});
        return this.http.delete<{ data: number }>(`${this.settings.API_PATH}/${this.edge}/${passIds}`, {params: params})
        .pipe(
            map(response => response.data)
        );
    }
}
