import { Action } from '@ngrx/store';
import { CustomerAdminMessages } from '@appRoot/core/customer/models';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';


export enum ActionTypes {
    GET = '[Customer Admin Messages] get',
    GET_SUCCESS = '[Customer Admin Messages] get success',

    ERROR = '[Customer data] error',

    CREATE = '[Customer Admin Messages] create',
    CREATE_SUCCESS = '[Customer Admin Messages] create success',
    CREATE_FAILED = '[Customer Admin Messages] create failed',

    UPDATE = '[Customer Admin Messages] update',
    UPDATE_SUCCESS = 'Customer Admin Messages] update success',
    UPDATE_FAILED = 'Customer Admin Messages] update failed',

    DELETE = '[Customer Admin Messages] delete',
    DELETE_SUCCESS = '[Customer Admin Messages] delete success',
    DELETE_FAILED = '[Customer Admin Messages] delete failed',

    UPSERT_MANY = '[Customer Admin Messages] UPSERT_MANY',
    // UPSERT_ONE = '[Customer data] UPSERT_ONE',

    // REMOVE_MANY = '[Customer data] REMOVE_MANY',
    REMOVE_MANY_BY_CUSTOMER_IDS = '[Customer Admin Messages] REMOVE_MANY_BY_CUSTOMER_IDS',
}

export class Get implements Action {
    readonly type = ActionTypes.GET;

    constructor(readonly payload: number) {}
}

export class GetSuccess implements Action {
    readonly type = ActionTypes.GET_SUCCESS;

    constructor(readonly payload: CustomerAdminMessages[]) {}
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;

    constructor(readonly payload: NGRXError<Actions>) { }
}

export class Create implements Action {
    readonly type = ActionTypes.CREATE;
    constructor(readonly payload: CustomerAdminMessages) { }
}
export class CreateSuccess implements Action {
    readonly type = ActionTypes.CREATE_SUCCESS;
}
export class CreateFailed implements Action {
    readonly type = ActionTypes.CREATE_FAILED;
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;
    constructor(readonly payload: {customer_admin_messages: CustomerAdminMessages, message_id: boolean | number}) {}
}
export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
}
export class UpdateFailed implements Action {
    readonly type = ActionTypes.UPDATE_FAILED;
}

export class Delete implements Action {
    readonly type = ActionTypes.DELETE;
    constructor(readonly payload: CustomerAdminMessages) {}
}
export class DeleteSuccess implements Action {
    readonly type = ActionTypes.DELETE_SUCCESS;
}
export class DeleteFailed implements Action {
    readonly type = ActionTypes.DELETE_FAILED;
}

export class UpsertMany implements Action {
    readonly type = ActionTypes.UPSERT_MANY;

    constructor(readonly payload: CustomerAdminMessages[]) {}
}

// export class UpsertOne implements Action {
//     readonly type = ActionTypes.UPSERT_ONE;
//
//     constructor(readonly payload: CustomerData) {}
// }

// export class RemoveMany implements Action {
//     readonly type = ActionTypes.REMOVE_MANY;
//
//     constructor(readonly payload: number[]) {}
// }

export class RemoveManyByCustomerIds implements Action {
    readonly type = ActionTypes.REMOVE_MANY_BY_CUSTOMER_IDS;

    constructor(readonly payload: number[]) {}
}

export type Actions =
    | Get
    | GetSuccess
    | Error
    | Create
    | CreateSuccess
    | CreateFailed
    | Update
    | UpdateSuccess
    | UpdateFailed
    | Delete
    | DeleteSuccess
    | DeleteFailed
    | UpsertMany
    // | UpsertOne
    // | RemoveMany
    | RemoveManyByCustomerIds
    ;
