import { Action } from '@ngrx/store';
import { CustomerGroups } from '@appRoot/core/customer/models';


export enum ActionTypes {
    RESET = '[Customer groups storage] RESET',
    ADD_ONE = '[Customer groups storage] ADD_ONE',
    UPSERT_ONE = '[Customer groups storage] UPSERT_ONE',
    UPSERT_MANY = '[Customer groups storage] UPSERT_MANY',
    UPDATE_ONE = '[Customer groups storage] UPDATE_ONE',
    REMOVE_ONE = '[Customer groups storage] REMOVE_ONE',
    REMOVE_MANY = '[Customer groups storage] REMOVE_MANY',
}

export class Reset implements Action {
    readonly type = ActionTypes.RESET;
}

export class AddOne implements Action {
    readonly type = ActionTypes.ADD_ONE;

    constructor(readonly payload: CustomerGroups) { }
}

export class UpsertOne implements Action {
    readonly type = ActionTypes.UPSERT_ONE;

    constructor(readonly payload: CustomerGroups) { }
}

export class UpsertMany implements Action {
    readonly type = ActionTypes.UPSERT_MANY;

    constructor(readonly payload: CustomerGroups[]) { }
}

export class UpdateOne implements Action {
    readonly type = ActionTypes.UPDATE_ONE;

    constructor(readonly payload: CustomerGroups) { }
}

export class RemoveOne implements Action {
    readonly type = ActionTypes.REMOVE_ONE;

    constructor(readonly payload: CustomerGroups) { }
}

export class RemoveMany implements Action {
    readonly type = ActionTypes.REMOVE_MANY;

    constructor(readonly payload: CustomerGroups[]) { }
}


export type Actions =
    | Reset
    | AddOne
    | UpsertOne
    | UpsertMany
    | UpdateOne
    | RemoveOne
    | RemoveMany
    ;
