import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SettingsService } from '@appRoot/core/services/settings.service';
import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import { ActionsSubject, select, Store } from "@ngrx/store";
import * as selfSignupSelectors from "@appRoot/lazy-modules/self-signup/ngrx-store/selectors/self-signup.selectors";
import * as selfSignupAccountsSelectors from "@appRoot/lazy-modules/self-signup/ngrx-store/selectors/self-signup-accounts.selectors";
import {filter, skip, takeUntil} from "rxjs/operators";
import { fadeInAnimation } from "@appRoot/lazy-modules/self-signup/_animations";
import {
    SelfSignupPackages,
    ISelfSignupRequestStoreCustomerParams,
    ISelfSignupRequestStorePaymentParams,
    ISelfSignupRequestCreateParams
} from "@appRoot/lazy-modules/self-signup/models";
import * as selfSignupActions from "@appRoot/lazy-modules/self-signup/ngrx-store/actions/self-signup.actions";
import { ErrorCatcherService } from "@appRoot/error-catcher/error-catcher.service";
import { AuthenticationService } from "@appRoot/core/authentication/services/authentication.service";
import { UserService } from "@appRoot/core/user/services/user.service";


@Component({
    selector: 'app-step-completion',
    templateUrl: './step-completion.component.html',
    styleUrls: ['./step-completion.component.scss'],
    animations: [ fadeInAnimation ],
    host: {'[@fadeInAnimation]': ''}
})
export class StepCompletionComponent implements OnInit {

    readonly ngUnsubscribe = new Subject();
    
    private customer: ISelfSignupRequestStoreCustomerParams = null;
    private package_id: number = null;
    private domain: string = null;
    private customer_number: string = null;
    private customer_card: ISelfSignupRequestStorePaymentParams = null;
    private user_id: number = null;

    public loadingStepService: boolean = false;
    public loading: boolean = false;
    public errorMessage = null;
    public errorShow = false;

    @Input() selectedPlan: SelfSignupPackages = null;

    constructor(
        private router: Router,
        public settings: SettingsService,
        private store: Store<any>,
        private cdr: ChangeDetectorRef,
        private errorCatcherService: ErrorCatcherService,
        private actionsSubject: ActionsSubject,
        private authService: AuthenticationService,
        private userService: UserService,
    ) { }

    ngOnInit() {
        this.loadingSubscriber();
        this.errorSubscriber();

        this.loadingStepService$().subscribe(loading => {
            this.loadingStepService = loading;
            this.cdr.detectChanges();
        });

        this.loadingSelectCustomer$().subscribe(customer => {
            this.customer = customer;
            this.cdr.detectChanges();
        });

        this.loadingSelectPackageId$().subscribe(package_id => {
            this.package_id = package_id;
            this.cdr.detectChanges();
        });

        this.loadingSelectDomain$().subscribe(domain => {
            this.domain = domain;
            this.cdr.detectChanges();
        });

        this.loadingCustomerNumber$().subscribe(customer_number => {
            this.customer_number = customer_number;
            this.cdr.detectChanges();
        });

        this.loadingCustomerCard$().subscribe(customer_card => {
            this.customer_card = customer_card;
            this.cdr.detectChanges();
        });

        this.userService.getActiveUserId$
            .pipe(filter(e => !!e),)
            .subscribe(e => {
                this.user_id = e;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    unsubscribe() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private loadingStepService$() {
        return this.store.pipe(
            select(selfSignupSelectors.getStepService),
            takeUntil(this.ngUnsubscribe)
        );
    }

    private loadingSelectCustomer$() {
        return this.store.pipe(
            select(selfSignupAccountsSelectors.getCustomer),
            takeUntil(this.ngUnsubscribe)
        );
    }

    private loadingSelectPackageId$() {
        return this.store.pipe(
            select(selfSignupAccountsSelectors.getPackageId),
            takeUntil(this.ngUnsubscribe)
        );
    }

    private loadingSelectDomain$() {
        return this.store.pipe(
            select(selfSignupAccountsSelectors.getDomain),
            takeUntil(this.ngUnsubscribe)
        );
    }

    private loadingCustomerNumber$() {
        return this.store.pipe(
            select(selfSignupAccountsSelectors.getCustomerNumber),
            takeUntil(this.ngUnsubscribe)
        );
    }

    private loadingCustomerCard$() {
        return this.store.pipe(
            select(selfSignupAccountsSelectors.getCustomerCard),
            takeUntil(this.ngUnsubscribe)
        );
    }
    
    public create() {
        if (this.customer_number) {
            let payload: ISelfSignupRequestCreateParams = {
                customer_number: this.customer_number
            };
            if (this.customer) {
                payload.customers = this.customer;
            }
            payload.account = {
                package_id: this.package_id,
                domain: this.domain
            };
            if (this.customer_card) {
                payload.payment = this.customer_card;
            }
            if (this.user_id) {
                payload.uid = this.user_id;
            }

            this.store.dispatch(new selfSignupActions.Create(payload));
        }
    }

    private loadingSubscriber() {
        this.store.pipe(
            select(selfSignupSelectors.getCreating),
            takeUntil(this.ngUnsubscribe)
        ).subscribe(creating => {
            this.loading = creating;
        });
    }

    private errorSubscriber() {
        this.store.pipe(
            select(selfSignupSelectors.getError)).pipe(takeUntil(this.ngUnsubscribe), skip(1), filter(e => e !== null)).subscribe(error => {
            if (error.action instanceof selfSignupActions.Create && this.errorCatcherService.has(error.instance)) {
                let cError = this.errorCatcherService.get(error.instance);
                this.errorMessage = cError.message;
                if (this.errorMessage) {
                    this.errorShow = true;
                }
                this.cdr.detectChanges();
            }
        });
    }
}