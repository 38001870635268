import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavItemService } from '../shared/components/nav-item/nav-item.service';
import { NavItem } from '../shared/components/nav-item/NavItem';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';

import { menu } from './menu';
import { routes } from './routes';

@NgModule({
    imports: [
        SharedModule,
        PagesModule,
        RouterModule.forRoot(routes),
    ],
    declarations: [],
    exports: [
        RouterModule
    ]
})

export class RoutesModule {
    constructor(nav: NavItemService) {
        nav.addItems(<NavItem[]>menu);
    }
}