import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ActionTypes, SetLanguage } from '../actions/translator.actions';
import { TranslatorService } from '@appRoot/core/translator/translator.service';

@Injectable()
export class TranslatorEffects {
	constructor(
		private actions$: Actions, 
		private translator: TranslatorService, 
		) {}

	@Effect({dispatch: false})
	setLang$: Observable<Action> = this.actions$.pipe(
		ofType(ActionTypes.SET_LANG),
		tap( (action: SetLanguage) => {
			this.translator.setStoreLang(action.payload);			
		}),

	);

}