import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterState } from '../router';
import { RouterReducerState, routerReducer  } from '@ngrx/router-store';

export const getState = createFeatureSelector<RouterReducerState<RouterState>>('router');

export const getRouteState = createSelector(
	getState, 
	(routerReducerState) => routerReducerState.state
);

export const getRouteParams = createSelector(
	getRouteState, 
	(state) => state.params
);

export const getRouteData = createSelector(
	getRouteState, 
	(state) => state.data
);

export const getRouteUrl = createSelector(
	getRouteState, 
	(state) => state.url
);
export const getRouteQueryParams = createSelector(
	getRouteState, 
	(state) => state.queryParams
);