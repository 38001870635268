<ngx-select
    #ngxSelect
    (selectionChanges)="selectionChanges($event)"
    (typed)="typed.next($event)"
    (open)="itemsClear()"
    (close)="itemsClear()"
    [formControl]="selectCtrl"
    [items]="items"
    [searchCallback]="searchCallback.bind(this)"
    [allowClear]="true"
    [autoActiveOnMouseEnter]="false"
    placeholder="Enter Customer number, name or email"
></ngx-select>