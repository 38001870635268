<div class="row mb-3">
    <div class="col-12 text-center">
        <p>Please enter the domain you want.</p>
    </div>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()" role="form" name="accountsForm" novalidate="">
    <div class="form-group">
        <label class="text-muted">{{ 'Domain' | translate }} *</label>
        <div class="input-group with-focus">
            <input formControlName="domain" class="form-control" type="text" maxlength="60" placeholder="{{ 'Enter domain' | translate }}"  />
        </div>
        <mat-error field-error [control]="domainCtrl" [messages]="validationMessages"></mat-error>
    </div>

    <hr class="mt-5">

    <button [disabled]="loading" class="btn btn-primary btn-block" type="submit">{{ 'Next step' | translate }}</button>
</form>