import { IPaginatedResponse, Omit } from '@appRoot/core/interfaces';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Action } from '@ngrx/store';
import { IUserRequestIndexParams, IUserRequestRestoreSelfParams, User} from '../../models';


export enum ActionTypes {
    ERROR = '[User] error',
    RESET = '[User] reset',

    GET_CURRENT = '[User] get current',
    GET_CURRENT_SUCCESS = '[User] get current success',
    GET_CURRENT_FAILED = '[User] get current failed',

    SET_ACTIVE_USER = '[User] set active user',

    LOAD = '[User] load',
    LOAD_SUCCESS = '[User] load success',
    LOAD_FAILED = '[User] load failed',

    PAGINATION_SET = '[User] pagination set',

    CREATE = '[User] create',
    CREATE_SUCCESS = '[User] create success',
    CREATE_FAILED = '[User] create failed',

    GET_BY_ID = '[User] get',
    GET_BY_ID_SUCCESS = '[User] get success',
    GET_BY_ID_FAILED = '[User] get failed',

    UPDATE = '[User] update',
    UPDATE_SUCCESS = '[User] update success',
    UPDATE_FAILED = '[User] update failed',

    CHANGE_PASSWORD = '[User] change password',
    CHANGE_EMAIL = '[User] change email',

    DELETE = '[User] delete',
    DELETE_SUCCESS = '[User] delete success',
    DELETE_FAILED = '[User] delete failed',

    DELETE_SELF = '[User] delete_self',
    DELETE_SELF_SUCCESS = '[User] delete_self success',
    DELETE_SELF_FAILED = '[User] delete_self failed',

    RESTORE = '[User] restore',
    RESTORE_SUCCESS = '[User] restore success',
    RESTORE_FAILED = '[User] restore failed',

    RESTORE_SELF = '[User] restore self',
    RESTORE_SELF_SUCCESS = '[User] restore self success',
    RESTORE_SELF_FAILED = '[User] restore self failed',

    RELOAD = '[User] reload',

    GET_BY_NAME_OR_EMAIL = '[User] get by name or email',
    GET_BY_NAME_EMAIL_CUSTOMER_NAME = '[User] get by name or email or customer name',
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;
    constructor(readonly payload: NGRXError<Actions>) { }
}

export class Reset implements Action {
    readonly type = ActionTypes.RESET;
}

export class GetCurrent implements Action {
    readonly type = ActionTypes.GET_CURRENT;
}
export class GetCurrentSuccess implements Action {
    readonly type = ActionTypes.GET_CURRENT_SUCCESS;
    constructor(readonly payload: {user: User} ) { }
}
export class GetCurrentFailed implements Action {
    readonly type = ActionTypes.GET_CURRENT_FAILED;
}

export class SetActiveUser implements Action {
    readonly type = ActionTypes.SET_ACTIVE_USER;
    constructor(readonly payload: {user: User}) { }
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;
    constructor(readonly payload: Omit<IUserRequestIndexParams, 'uid'> = {}, readonly selector: string = null) { }
}
export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;
    constructor(readonly payload: IPaginatedResponse<User[]>, readonly selector: string = null) { }
}
export class LoadFailed implements Action {
    readonly type = ActionTypes.LOAD_FAILED;
}

export class PaginationSet implements Action {
    readonly type = ActionTypes.PAGINATION_SET;
    constructor(readonly payload: IPaginatedResponse<number[]>) { }
}

export class Create implements Action {
    readonly type = ActionTypes.CREATE;
    constructor(readonly payload: {user: User; password?: string; passwordConfirmation?: string; notify?: boolean;}) { }
}
export class CreateSuccess implements Action {
    readonly type = ActionTypes.CREATE_SUCCESS;
    constructor(readonly payload: {user: User}) { }
}
export class CreateFailed implements Action {
    readonly type = ActionTypes.CREATE_FAILED;
}

export class GetById implements Action {
    readonly type = ActionTypes.GET_BY_ID;
    constructor(readonly payload: number) { }
}
export class GetByIdSuccess implements Action {
    readonly type = ActionTypes.GET_BY_ID_SUCCESS;
    constructor(readonly payload: {user: User}) { }
}
export class GetByIdFailed implements Action {
    readonly type = ActionTypes.GET_BY_ID_FAILED;
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;
    constructor(readonly payload: {
        user: User;
        changes: {user: User; password?: string;};
    }) { }
}
export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
    constructor(readonly payload: {user: User}) { }
}
export class UpdateFailed implements Action {
    readonly type = ActionTypes.UPDATE_FAILED;
}

export class ChangePassword implements Action {
    readonly type = ActionTypes.CHANGE_PASSWORD;
    constructor(readonly payload: {
        user: User;
        changes: { newPassword: string; passwordConfirmation: string, currentPassword: string; }
    }) {}
}

export class ChangeEmail implements Action {
    readonly type = ActionTypes.CHANGE_EMAIL;
    constructor(readonly payload: {
        user: User;
        changes: { newEmail: string; currentPassword: string; }
    }) {}
}

export class Delete implements Action {
    readonly type = ActionTypes.DELETE;
    constructor(readonly payload: {users: User[]; force?: boolean}) {}
}
export class DeleteSuccess implements Action {
    readonly type = ActionTypes.DELETE_SUCCESS;
    constructor(readonly payload: {users: User[]; force?: boolean}) { }
}
export class DeleteFailed implements Action {
    readonly type = ActionTypes.DELETE_FAILED;
}

export class DeleteSelf implements Action {
    readonly type = ActionTypes.DELETE_SELF;
}
export class DeleteSelfSuccess implements Action {
    readonly type = ActionTypes.DELETE_SELF_SUCCESS;
    constructor(readonly payload: {users: User}) { }
}
export class DeleteSelfFailed implements Action {
    readonly type = ActionTypes.DELETE_SELF_FAILED;
}

export class Restore implements Action {
    readonly type = ActionTypes.RESTORE;
    constructor(readonly payload: {users: User[]}) { }
}
export class RestoreSuccess implements Action {
    readonly type = ActionTypes.RESTORE_SUCCESS;
    constructor(readonly payload: {users: User[]}) { }
}
export class RestoreFailed implements Action {
    readonly type = ActionTypes.RESTORE_FAILED;
}

export class RestoreSelf implements Action {
    readonly type = ActionTypes.RESTORE_SELF;
    constructor(readonly payload: IUserRequestRestoreSelfParams) { }
}
export class RestoreSelfSuccess implements Action {
    readonly type = ActionTypes.RESTORE_SELF_SUCCESS;
    constructor(readonly payload: {user: User}) { }
}
export class RestoreSelfFailed implements Action {
    readonly type = ActionTypes.RESTORE_SELF_FAILED;
}

export class Reload implements Action {
    readonly type = ActionTypes.RELOAD;
}

export class GetByNameOrEmail implements Action {
    readonly type = ActionTypes.GET_BY_NAME_OR_EMAIL;
    constructor(readonly payload: string) { }
}

export class GetByNameEmailCustomerName implements Action {
    readonly type = ActionTypes.GET_BY_NAME_EMAIL_CUSTOMER_NAME;
    constructor(readonly payload: string) { }
}


export type Actions =
    | GetCurrent
    | GetCurrentSuccess
    | GetCurrentFailed
    | SetActiveUser
    | Error
    | Reset
    | Load
    | LoadSuccess
    | LoadFailed
    | PaginationSet
    | Create
    | CreateSuccess
    | CreateFailed
    | GetById
    | GetByIdSuccess
    | GetByIdFailed
    | Update
    | UpdateSuccess
    | UpdateFailed
    | ChangePassword
    | ChangeEmail
    | Delete
    | DeleteSuccess
    | DeleteFailed
    | DeleteSelf
    | DeleteSelfSuccess
    | DeleteSelfFailed
    | Restore
    | RestoreSuccess
    | RestoreFailed
    | RestoreSelf
    | RestoreSelfSuccess
    | RestoreSelfFailed
    | Reload
    | GetByNameOrEmail
    | GetByNameEmailCustomerName
    ;
