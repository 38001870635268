<div class="wrapper">
    <div class="block-center mt-4 wd-xl">
        <!-- START card-->
        <div class="card card-flat">
            <div class="card-header text-center bg-dark">
                <a href="#">
                    <img class="block-center rounded" src="{{settings.app.logo.default}}" alt="Image" />
                </a>
            </div>
            <div class="card-body">
                <p class="text-center py-2">SIGN IN TO CONTINUE.</p>

                <app-login></app-login>

                <ng-container *ngIf="settings.registrationMethod === 'public'">
                    <p class="pt-3 text-center">Need to Signup?</p>
                    <a class="btn btn-block btn-secondary" [routerLink]="'/register'">Sign Up</a>
                </ng-container>
            </div>
        </div>

    </div>
</div>
