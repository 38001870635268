import { NgModule } from '@angular/core';

import { LayoutModule } from '@appRoot/layout/layout.module';
import { CoreModule } from '@appRoot/core/core.module';
import { SharedModule } from '@appRoot/shared/shared.module';
import { RoutesModule } from '@appRoot/routes/routes.module';

import { AppComponent } from './app.component';


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        CoreModule,
        SharedModule.forRoot(),
        LayoutModule,
        RoutesModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {
}
