<div class="wrapper">
    <div class="block-center m-5">

        <div class="card card-flat">
            <div class="card-header text-center bg-dark">
                <a href="#">
                    <img class="block-center rounded" src="{{settings.app.logo.default}}" alt="Image" />
                </a>
            </div>

            <div class="card-body block-center">
                <app-self-signup-container [service]="service"></app-self-signup-container>
            </div>
        </div>

    </div>
</div>