import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPaginatedResponse } from '@appRoot/core/interfaces';
import { SettingsService } from '@appRoot/core/services/settings.service';
import { isBoolean, isEmpty, isNumber } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    ICustomerContactsRequestIndexParams,
    ICustomerContactsResponse,
    ICustomerContactsRequestShowParams,
    ICustomerContactsRequestUpdateRequiredParams,
    ICustomerContactsRequestUpdate,
    ICustomerContactsRequestStore,
    ICustomerContactsRequestDestroyParams
} from '../models';


@Injectable()
export class CustomerContactsHttpService {

    public readonly edge: string = 'customer-contacts';

    constructor(
        private http: HttpClient,
        private settings: SettingsService,
    ) { }

    fetch(data: ICustomerContactsRequestIndexParams): Observable<IPaginatedResponse<ICustomerContactsResponse[]>> {
        let params = new HttpParams();

        params = params.append('uid', ''+data.uid);
        params = data.customer_id ? params.append('customer_id', ''+data.customer_id) : params;
        params = data.page ? params.append('page[number]', ''+data.page) : params;
        params = data.size ? params.append('page[size]', ''+data.size) : params;
        params = data.sort ? params.append('sort', data.sort) : params;
        params = data.order ? params.append('order',data.order) : params;

        if (data.search) {
            for (let prop in data.search) {
                // noinspection JSUnfilteredForInLoop
                let value = isBoolean(data.search[prop]) ? +data.search[prop] : data.search[prop];
                params = !isNumber(value) && isEmpty(value) ? params : params.append('search[' + prop + ']', ''+value);
            }
        }

        return this.http.get<{ data: IPaginatedResponse<ICustomerContactsResponse[]> }>(`${this.settings.API_PATH}/${this.edge}`, {params: params}).pipe(
            map(response => response.data),
        );
    }

    store(requestData: ICustomerContactsRequestStore): Observable<ICustomerContactsResponse> {
        return this.http.post<{ data: ICustomerContactsResponse }>(`${this.settings.API_PATH}/${this.edge}`, requestData).pipe(
            map(response => (response.data)),
        );
    }

    show(data: ICustomerContactsRequestShowParams): Observable<ICustomerContactsResponse> {
        let params = new HttpParams({ fromObject: <any>{ ...data } });
        return this.http.get<{ data: ICustomerContactsResponse }>(`${this.settings.API_PATH}/${this.edge}/${data.contact_id}`, {params: params}).pipe(
            map(response => (response.data)),
        );
    }

    update(data: ICustomerContactsRequestUpdateRequiredParams, changes: ICustomerContactsRequestUpdate): Observable<ICustomerContactsResponse[]> {
        return this.http.patch<{ data: ICustomerContactsResponse[] }>(`${this.settings.API_PATH}/${this.edge}/${data.contact_id}`, {...data, ...changes}).pipe(
            map(response => (response.data)),
        );
    }

    destroy(id: number, params: ICustomerContactsRequestDestroyParams): Observable<boolean> {
        let _params = new HttpParams({ fromObject: <any>{ ...params } });

        return this.http.delete<{ data: boolean }>(`${this.settings.API_PATH}/${this.edge}/${id}`, { params: _params }).pipe(
            map(({ data }) => data),
        );
    }
}