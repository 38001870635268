import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, skip, takeUntil } from 'rxjs/operators';
import { ErrorCatcherService } from '@appRoot/error-catcher/error-catcher.service';
import { AuthenticationService } from '../../services/authentication.service';
import * as confAction from '../../ngrx-store/actions/confirmation.actions';


@Component({
	selector: 'app-confirm',
	templateUrl: './confirm.component.html',
	styleUrls: ['./confirm.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmComponent implements OnInit, OnDestroy {

	@Input() public token: string;
	private ngUnsubscribe = new Subject();
	public errorShow: boolean = false;
	public errorMessage: string = null;
	public errorMessages = null;
	public loading: boolean = false;
	public confirmed: boolean = false;

	constructor(		
		private errorCatcherService: ErrorCatcherService,
		private authService: AuthenticationService,
		private cdr: ChangeDetectorRef,
		) { }

	ngOnInit() {
		if(this.token){
			this.errorSubscriber();
			this.loadingSubscriber();
			this.confirmSubscriber();
			this.authService.dispatchConfirmationConfirm(this.token);
		}
	}

	ngOnDestroy(){
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	private errorSubscriber(){
        return this.authService.getConfirmationError$()
        .pipe(takeUntil(this.ngUnsubscribe), skip(1), filter(e => e !== null)).subscribe(error => {
            if(error.action instanceof confAction.Confirm && this.errorCatcherService.has(error.instance)){
                let cError = this.errorCatcherService.get(error.instance);
                this.errorMessage = cError.message;
                this.errorMessages = cError.errors ? cError.errors: null;
                this.errorShow = true;
                this.cdr.detectChanges();
            }
        });
    }

    private loadingSubscriber(){
    	return this.authService.getConfirmationConfirming$().pipe(takeUntil(this.ngUnsubscribe)).subscribe(e => {
            this.loading = e;
            this.cdr.detectChanges();
        });
    }

    private confirmSubscriber(){
    	return this.authService.getConfirmationConfirmed$().pipe(takeUntil(this.ngUnsubscribe)).subscribe(e => {
            this.confirmed = e;
            this.cdr.detectChanges();
        });
    }

}
