import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';


@Component({
	selector: 'app-paginator',
	templateUrl: './paginator.component.html',
	styleUrls: ['./paginator.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent implements OnInit {

	private _pages: {pageIndex: number, label: string, active?: boolean}[] = [];
	get pages() { return this._pages; }

	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

	@Input() public showPaginator: boolean = true;
	@Input() public showMatPaginator: boolean = true;
	@Input() public showLabel: boolean = true;
	@Input() public siblingCount: number = 2;
	@Input() public labelFirst: string = "First";
	@Input() public labelLast: string = "Last";
	@Input() public matClass: string = '';
	@Input() public paginatorClass: string = '';
	@Input() public paginatorLabelClass: string = '';

	@Input()
	get pageIndex(): number { return this.paginator.pageIndex || 0; }
	set pageIndex(value: number){
		this._previousPageIndex = this.pageIndex;
		this.paginator.pageIndex = value;
		this.renderPaginationButtons();
	}
	private _previousPageIndex: number;

	@Input()
	get pageSize(): number{	return this.paginator.pageSize || 10;	}
	set pageSize(value: number){
		this.paginator.pageSize = value;
		this.renderPaginationButtons();
	}

	@Input()
	get length(): number { return this.paginator.length || 0; }
	set length(value: number) {
		this.paginator.length = value;
		this.renderPaginationButtons();
	}

	@Input()
	get pageSizeOptions(): number[] { return this.paginator!.pageSizeOptions || []; }
	set pageSizeOptions(value: number[]) {
		this.paginator.pageSizeOptions = value;
		this.renderPaginationButtons();
	}

	@Output() pageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

	constructor(
		public paginatorIntl : MatPaginatorIntl,
		private cdr: ChangeDetectorRef,
		) { }

	ngOnInit() {
        this.paginator.page.subscribe((e:PageEvent) => {
            if(e && e.previousPageIndex) this._previousPageIndex = e.previousPageIndex;
            this.pageChange.emit({
                previousPageIndex: this._previousPageIndex,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                length: this.length
            });
            this.renderPaginationButtons();
            this.cdr.markForCheck();
        });
	}

	public goToPage(index){
		if(index !== this.pageIndex) {
            this.pageIndex = index;
            this.paginator['_emitPageEvent'](this._previousPageIndex);
        }
	}

	private renderPaginationButtons(){
		if(!this.showPaginator || !this.length){
			this._pages = [];
			return;
		}
		let totalPages = Math.ceil(this.length / this.pageSize);

		let first = {pageIndex: 0, label: this.labelFirst};
		let last = {pageIndex: Math.max(0, totalPages-1), label: this.labelLast};

		let currentPageIndex = this.pageIndex;

		this._pages = [];
		this._pages.push({pageIndex: currentPageIndex, label: currentPageIndex+1+'', active: true});

		for (let i = this.siblingCount; i > 0; i--) {
			if(totalPages > (currentPageIndex+i)) {
				this._pages.push({pageIndex: currentPageIndex+i, label: currentPageIndex+1+i+''});
			}
			if(currentPageIndex-i >= 0) {
				this._pages.push({pageIndex: currentPageIndex-i, label: currentPageIndex+1-i+''});
			}
		}

		this._pages.sort((a,b) => a.pageIndex - b.pageIndex );

		this._pages.unshift(first);
		this._pages.push(last);
	}

}
