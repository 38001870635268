<div *ngIf="(customer$ | async) as customer" class="customer-short-info alert alert-light border">

    <div class="item row">
        <span class="col-auto">Number:</span>
        <span>
            <app-customer-render-info #customerInfoCmp
                                      (click)="goToCustomerView(customerInfoCmp)"
                                      [fields]="['number']"
                                      [showTooltip]="false"
                                      [customerId]="customer.id"
                                      class="d-inline-block btn-link clickable">
            </app-customer-render-info>
        </span>
    </div>
    <div class="item row">
        <span class="col-auto">Name:</span>
        <span>{{ customer.name }}</span>
    </div>
    <div class="item row" *ngIf="customer.email">
        <span class="col-auto">Email:</span>
        <span>{{ customer.email }}</span>
    </div>

</div>
