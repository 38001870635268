import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as mailingReducer from './reducers/mailing.reducer';
import * as notificationReducer from './reducers/notification.reducer';


export interface State {
    mailing: mailingReducer.State;
    notification: notificationReducer.State;
}

export const reducers: ActionReducerMap<State> = {
    mailing: mailingReducer.reducer,
    notification: notificationReducer.reducer,
};

export const ModuleStore = createFeatureSelector<State>('Notification');
