import { Injectable, OnDestroy } from '@angular/core';
import { HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { ErrorCatcherNotice } from "./error-catcher.notice";
import { ErrorCatcherMessageService } from "./message/error-catcher-message.service";
import { ErrorResponse } from "./error-catcher.types";

@Injectable({
	providedIn: 'root'
})
export class ErrorCatcherService implements OnDestroy {


	private errors: Map< HttpErrorResponse | HttpResponse<any>, ErrorCatcherNotice >;

	constructor(private messageService: ErrorCatcherMessageService ){
    this.errors = new Map();
  }

	add<T>(key: HttpErrorResponse | HttpResponse<T>, value: ErrorResponse, maxSize: number = 100) {
		if(maxSize && this.size > maxSize) this.removeFirst();
		this.errors.set(key, new ErrorCatcherNotice(value, this.messageService));
	}

	remove<T>(key: HttpErrorResponse | HttpResponse<T>): boolean{
		return this.errors.delete(key);
	}

	get<T>(key: HttpErrorResponse | HttpResponse<T>): ErrorCatcherNotice {
		return this.errors.get(key);
	}

	clear(){
		this.errors.clear();
	}

	has<T>(key: HttpErrorResponse | HttpResponse<T>): boolean {
		return this.errors.has(key);
	}

	removeFirst(): boolean{
		return this.remove(this.errors.keys().next().value);
	}

	get size(){
		return this.errors.size;
	}

	ngOnDestroy(){
		this.clear();
	}

}


