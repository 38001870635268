import { createSelector } from '@ngrx/store';
import { ModuleStore } from '../reducers';
import { adapter } from '../reducers/mailing.reducer';


export const getState = createSelector(
    ModuleStore,
    (store) => store.mailing
);

export const getLoading = createSelector(
    getState,
    (state) => state.loading
);

export const getCreating = createSelector(
    getState,
    (state) => state.creating
);

export const getUpdating = createSelector(
    getState,
    (state) => state.updating
);

export const getDeleting = createSelector(
    getState,
    (state) => state.deleting
);

export const getGetting = createSelector(
    getState,
    (state) => state.getting
);

export let getLoaded = createSelector(
    getState,
    (state) => state.loaded
);

export const getGettingProviders = createSelector(
    getState,
    (state) => state.gettingProviders
);

export const getProviders = createSelector(
    getState,
    (state) => state.providers
);

const entitySelectors = adapter.getSelectors();

export const getTemplatesArray = createSelector(
    getState,
    entitySelectors.selectAll
);

export const getTemplatesDictionary = createSelector(
    getState,
    entitySelectors.selectEntities
);

export const getTemplateById = (id: number) => createSelector(
    getTemplatesDictionary,
    (e) => e[id]
);

export const getTemplateByMailable = (mailable: string) => createSelector(
    getTemplatesArray,
    (templates) => templates.find(template => template.mailable == mailable)
);
