import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPaginatedResponse } from '@appRoot/core/interfaces';
import { SettingsService } from '@appRoot/core/services/settings.service';
import { isBoolean, isEmpty, isNumber } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    ICustomerRequestDestroyParams,
    ICustomerRequestGetByParams,
    ICustomerRequestImportFormDK,
    ICustomerRequestIndexParams,
    ICustomerRequestRestoreParams,
    ICustomerRequestShowParams,
    ICustomerRequestStoreParams,
    ICustomerRequestUpdateRequiredParams,
    ICustomerRequestUpdateWithPermission,
    ICustomerResponse,
    ICustomerRequestAddUser,
    ICustomerRequestRemoveUser,
    ICustomerRequestGetSubscriptionParams,
    ICustomerGetSubscriptionResponse,
    ICustomerAdminMessagesRequestCreate,
    ICustomerAdminMessagesRequestUpdate,
    ICustomerAdminMessagesRequestDelete,
    ICustomerRequestChangeUserRole,
    ICustomerRequestGetPaymentModeParams,
    ICustomerGetPaymentModeResponse,
    ICustomerRequestUpdatePaymentMode,
    ICustomerUpdatePaymentModeResponse,
    ICustomerRequestUpdateCreditCard,
    ICustomerUpdateCreditCardResponse,
    ICustomerRequestSendEmail,
    ICustomerResendNewUserMail,
    ICustomerGetHistoryParams,
    CustomerActionHistory,
    ICustomerActionHistoryResponse,
    ICustomerContactGetHistoryParams,
    CustomerContactActionHistory,
    ICustomerContactActionHistoryResponse,
    ICustomerCommentsRequestCreate,
    ICustomerCommentsRequestUpdate,
    ICustomerCommentsRequestDelete,
    ICustomerRequestGetInvoiceParams,
    ICustomerGetInvoiceResponse,
    ICustomerRequestGetInvoicePdfParams,
    ICustomerGetInvoicePdfResponse,
    ICustomerRequestGetInvoiceHtmlParams,
    ICustomerGetInvoiceHtmlResponse,
    ICustomerRequestEmailInvoiceParams,
    ICustomerResponseImportFormDK,
    CustomerExpense,
    ICustomerEmailLogsRequestIndexParams,
    CustomerEmailLogs,
    ICustomerEmailLogsRequestResendParams,
    ICustomerRequestGetTimeTrackingParams,
    ICustomerGeTimeTrackingResponse
} from '../models';


@Injectable()
export class CustomerHttpService {

    public readonly edge: string = 'customers';
    public readonly edgeForEmailLogs: string = 'email-logs';

    constructor(
        private http: HttpClient,
        private settings: SettingsService,
    ) { }

    fetch(data: ICustomerRequestIndexParams): Observable<IPaginatedResponse<ICustomerResponse[]>> {
        let params = new HttpParams();

        params = params.append('uid', ''+data.uid);
        params = data.page ? params.append('page[number]', ''+data.page) : params;
        params = data.size ? params.append('page[size]', ''+data.size) : params;
        params = data.sort ? params.append('sort', data.sort) : params;
        params = data.order ? params.append('order',data.order) : params;
        params = data.status ? params.append('status', data.status) : params;
        params = data.source_id ? params.append('source_id', ''+data.source_id) : params;

        if (data.search) {
            for (let prop in data.search) {
                // noinspection JSUnfilteredForInLoop
                let value = isBoolean(data.search[prop]) ? +data.search[prop] : data.search[prop];
                params = !isNumber(value) && isEmpty(value) ? params : params.append('search[' + prop + ']', ''+value);
            }
        }

        return this.http.get<{ data: IPaginatedResponse<ICustomerResponse[]> }>(`${this.settings.API_PATH}/${this.edge}`, {params: params}).pipe(
            map(response => response.data),
        );
    }

    create(data: ICustomerRequestStoreParams): Observable<ICustomerResponse> {
        return this.http.post<{ data: ICustomerResponse }>(`${this.settings.API_PATH}/${this.edge}`, data).pipe(
            map(response => response.data),
        );
    }

    show(data: ICustomerRequestShowParams): Observable<ICustomerResponse> {
        let params = new HttpParams({ fromObject: <any>{ ...data } });
        return this.http.get<{ data: ICustomerResponse }>(`${this.settings.API_PATH}/${this.edge}/${data.customer_id}`, {params: params}).pipe(
            map(response => {
                return response.data
            }),
        );
    }

    update(data: ICustomerRequestUpdateRequiredParams, changes: ICustomerRequestUpdateWithPermission): Observable<ICustomerResponse> {
        return this.http.patch<{ data: ICustomerResponse }>(`${this.settings.API_PATH}/${this.edge}/${data.customer_id}`, {...data, ...changes}).pipe(
            map(response => (response.data)),
        );
    }

    destroy(ids: number[], data: ICustomerRequestDestroyParams): Observable<ICustomerResponse[]> {
        let idsStr = ids.join(',');
        let params = new HttpParams();
        params = params.append('uid', <any>data.uid);
        if(data.force){
            params = params.append('force', <any>+data.force)
        }
        return this.http.delete<{ data: ICustomerResponse[] }>(`${this.settings.API_PATH}/${this.edge}/${idsStr}`, {params: params}).pipe(
            map(response => response.data),
        );
    }

    restore(ids: number[], data: ICustomerRequestRestoreParams): Observable<ICustomerResponse[]> {
        let idsStr = ids.join(',');
        return this.http.patch<{ data: ICustomerResponse[] }>(`${this.settings.API_PATH}/${this.edge}/${idsStr}/restore`, data).pipe(
            map(response => response.data),
        );
    }

    fetchBy(data: ICustomerRequestGetByParams): Observable<ICustomerResponse[]> {
        return this.http.get<{ data: ICustomerResponse[] }>(`${this.settings.API_PATH}/${this.edge}/by/?${data.key}=${data.value}`).pipe(
            map(response => response.data),
        );
    }

    addUserToCustomer(data: ICustomerRequestAddUser): Observable<ICustomerResponse > {
        return this.http.post<{ data: ICustomerResponse }>(`${this.settings.API_PATH}/${this.edge}/add-user`, data).pipe(
            map(response => response.data),
        );
    }

    removeUserFromCustomer(data: ICustomerRequestRemoveUser): Observable<ICustomerResponse > {
        return this.http.post<{ data: ICustomerResponse }>(`${this.settings.API_PATH}/${this.edge}/remove-user`, data).pipe(
            map(response => response.data),
        );
    }

    changeUserCustomerRole(data: ICustomerRequestChangeUserRole): Observable<ICustomerResponse > {
        return this.http.post<{ data: ICustomerResponse }>(`${this.settings.API_PATH}/${this.edge}/change-user-role`, data).pipe(
            map(response => response.data),
        );
    }

    resendNewUserMail(data: ICustomerResendNewUserMail): Observable<ICustomerResponse > {
        return this.http.post<{ data: ICustomerResponse }>(`${this.settings.API_PATH}/${this.edge}/resend-new-user-mail`, data).pipe(
            map(response => response.data),
        );
    }

    importFormDK(data: ICustomerRequestImportFormDK): Observable<ICustomerResponseImportFormDK> {
        return this.http.post<ICustomerResponseImportFormDK>(`${this.settings.API_PATH}/${this.edge}/dk-import`, data).pipe(
            map(response => response),
        );
    }

    customerIdExist(customerId: number): Observable<boolean>  {
        let params = new HttpParams({fromString: 'customer_id='+customerId});

        return this.http.get<{ data: boolean }>(`${this.settings.API_PATH}/${this.edge}/check-customer-id`, {params: params}).pipe(
            map(response => response.data),
        );
    }

    getEmailLogs(data: ICustomerEmailLogsRequestIndexParams): Observable<CustomerEmailLogs[]> {
        let params = new HttpParams();
        params = params.append('uid', (data.uid).toString());
        params = params.append('number', (data.number).toString());
        params = params.append('customer_id', (data.customer_id).toString());

        return this.http.get<{ data: CustomerEmailLogs[] }>(`${this.settings.API_PATH}/${this.edge}/email-logs`, { params: params }).pipe(
            map(response => response.data),
        );
    }

    resendEmailLogs(data: ICustomerEmailLogsRequestResendParams): Observable<boolean> {
        return this.http.post<{ data: boolean }>(`${this.settings.API_PATH}/${this.edgeForEmailLogs}/resend`, data).pipe(
            map(response => response.data),
        );
    }

    getSubscription(data: ICustomerRequestGetSubscriptionParams): Observable<ICustomerGetSubscriptionResponse[]> {
        let params = new HttpParams();
        params = params.append('uid', (data.uid).toString());
        params = params.append('number', (data.number).toString());
        params = params.append('customer_id', (data.customer_id).toString());

        return this.http.get<{ data: ICustomerGetSubscriptionResponse[] }>(`${this.settings.API_PATH}/${this.edge}/subscription`, { params: params }).pipe(
            map(response => response.data),
        );
    }

    getTimeTracking(data: ICustomerRequestGetTimeTrackingParams): Observable<ICustomerGeTimeTrackingResponse[]> {
        let params = new HttpParams();
        params = params.append('uid', (data.uid).toString());
        params = params.append('number', (data.number).toString());
        params = params.append('customer_id', (data.customer_id).toString());

        return this.http.get<{ data: ICustomerGeTimeTrackingResponse[] }>(`${this.settings.API_PATH}/${this.edge}/time-tracking`, { params: params }).pipe(
            map(response => response.data),
        );
    }

    getInvoice(data: ICustomerRequestGetInvoiceParams): Observable<ICustomerGetInvoiceResponse[]> {
        let params = new HttpParams();
        params = params.append('uid', (data.uid).toString());
        params = params.append('number', (data.number).toString());
        params = params.append('customer_id', (data.customer_id).toString());

        return this.http.get<{ data: ICustomerGetInvoiceResponse[] }>(`${this.settings.API_PATH}/${this.edge}/invoice`, { params: params }).pipe(
            map(response => response.data),
        );
    }

    getInvoicePdf(data: ICustomerRequestGetInvoicePdfParams): Observable<ICustomerGetInvoicePdfResponse> {
        let params = new HttpParams();
        params = params.append('uid', (data.uid).toString());
        params = params.append('customer_id', (data.customer_id).toString());
        params = params.append('invoice_number', (data.invoice.Number).toString());

        return this.http.get<ICustomerGetInvoicePdfResponse>(`${this.settings.API_PATH}/${this.edge}/invoice-pdf`, { params: params }).pipe(
            map(response => response),
        );
    }

    getInvoiceHtml(data: ICustomerRequestGetInvoiceHtmlParams): Observable<ICustomerGetInvoiceHtmlResponse> {
        let params = new HttpParams();
        params = params.append('uid', (data.uid).toString());
        params = params.append('customer_id', (data.customer_id).toString());
        params = params.append('invoice_number', (data.invoice.Number).toString());

        return this.http.get<ICustomerGetInvoiceHtmlResponse>(`${this.settings.API_PATH}/${this.edge}/invoice-html`, { params: params }).pipe(
            map(response => response),
        );
    }

    emailInvoice(data: ICustomerRequestEmailInvoiceParams): Observable<boolean> {
        return this.http.post<{ data: boolean }>(`${this.settings.API_PATH}/${this.edge}/invoice-email`, {...data}).pipe(
            map(response => response.data),
        );
    }

    getPaymentMode(data: ICustomerRequestGetPaymentModeParams): Observable<ICustomerGetPaymentModeResponse> {
        let params = new HttpParams();
        params = params.append('uid', (data.uid).toString());
        params = params.append('number', (data.number).toString());
        params = params.append('customer_id', (data.customer_id).toString());

        return this.http.get<{ data: ICustomerGetPaymentModeResponse }>(`${this.settings.API_PATH}/${this.edge}/payment-mode`, { params: params }).pipe(
            map(response => response.data),
        );
    }

    updatePaymentMode(data: ICustomerRequestUpdateRequiredParams, changes: ICustomerRequestUpdatePaymentMode): Observable<ICustomerUpdatePaymentModeResponse> {
        return this.http.post<{ data: ICustomerUpdatePaymentModeResponse }>(`${this.settings.API_PATH}/${this.edge}/update-payment-mode`, {...data, ...changes}).pipe(
            map(response => response.data),
        );
    }

    updateCreditCard(data: ICustomerRequestUpdateRequiredParams, changes: ICustomerRequestUpdateCreditCard): Observable<ICustomerUpdateCreditCardResponse> {
        return this.http.post<{ data: ICustomerUpdateCreditCardResponse }>(`${this.settings.API_PATH}/${this.edge}/update-credit-card`, {...data, ...changes}).pipe(
            map(response => response.data),
        );
    }

    createAdminMesage(data: ICustomerAdminMessagesRequestCreate): Observable<ICustomerResponse> {
        return this.http.post<{ data: ICustomerResponse }>(`${this.settings.API_PATH}/${this.edge}/store-admin-message`, data).pipe(
            map(response => response.data),
        );
    }

    updateAdminMesage(data: ICustomerAdminMessagesRequestUpdate): Observable<ICustomerResponse> {
        return this.http.post<{ data: ICustomerResponse }>(`${this.settings.API_PATH}/${this.edge}/update-admin-message`, data).pipe(
            map(response => response.data),
        );
    }

    deleteAdminMesage(data: ICustomerAdminMessagesRequestDelete): Observable<ICustomerResponse> {
        return this.http.post<{ data: ICustomerResponse }>(`${this.settings.API_PATH}/${this.edge}/delete-admin-message`, data).pipe(
            map(response => response.data),
        );
    }

    sendEmail(data: ICustomerRequestSendEmail): Observable<boolean> {
        return this.http.post<{ data: boolean }>(`${this.settings.API_PATH}/${this.edge}/send-email`, data).pipe(
            map(response => response.data),
        );
    }

    getHistory(data: ICustomerGetHistoryParams): Observable<CustomerActionHistory[]> {
        let params = new HttpParams({ fromObject: <any>{ ...data } });

        return this.http.get<{ data: ICustomerActionHistoryResponse[] }>(`${this.settings.API_PATH}/customer-history`, { params: params }).pipe(
            map(({ data }) => data.map(e => new CustomerActionHistory(e)))
        );
    }

    getContactHistory(data: ICustomerContactGetHistoryParams): Observable<CustomerContactActionHistory[]> {
        let params = new HttpParams({ fromObject: <any>{ ...data } });

        return this.http.get<{ data: ICustomerContactActionHistoryResponse[] }>(`${this.settings.API_PATH}/customer-contact-history`, { params: params }).pipe(
            map(({ data }) => data.map(e => new CustomerContactActionHistory(e)))
        );
    }

    createComment(data: ICustomerCommentsRequestCreate): Observable<ICustomerResponse> {
        return this.http.post<{ data: ICustomerResponse }>(`${this.settings.API_PATH}/${this.edge}/store-comment`, data).pipe(
            map(response => response.data),
        );
    }

    updateComment(data: ICustomerCommentsRequestUpdate): Observable<ICustomerResponse> {
        return this.http.post<{ data: ICustomerResponse }>(`${this.settings.API_PATH}/${this.edge}/update-comment`, data).pipe(
            map(response => response.data),
        );
    }

    deleteComment(data: ICustomerCommentsRequestDelete): Observable<ICustomerResponse> {
        return this.http.post<{ data: ICustomerResponse }>(`${this.settings.API_PATH}/${this.edge}/delete-comment`, data).pipe(
            map(response => response.data),
        );
    }

    createExpense(data: ICustomerRequestUpdateRequiredParams, changes: CustomerExpense): Observable<boolean> {
        return this.http.post<{ data: boolean }>(`${this.settings.API_PATH}/${this.edge}/create-expense`, {...data, ...changes}).pipe(
            map(response => response.data),
        );
    }
}