import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { isEqual } from 'lodash';

import { User } from '../models';
import { State } from '../ngrx-store/reducers/user.reducer';
import * as userSelectors from '../ngrx-store/selectors/user.selectors';
import * as auzSelectors from '../ngrx-store/selectors/authorization.selectors';
import { Go } from '@appRoot/core/ngrx-store/actions/navigation.actions';


@Injectable()
export class UserService {

	constructor(
		private store: Store<State>,
    ) { }

	get getUserId$(): Observable<number> {
		return this.store.pipe(select(userSelectors.getSessionUserId));
	}
	get user$(): Observable<User> {
		return this.store.pipe(select(userSelectors.getSessionUser));
	}

	get getActiveUserId$(): Observable<number> {
		return this.store.pipe(select(userSelectors.getActiveUserId));
	}

	get activeUser$(): Observable<User> {
		return this.store.pipe(select(userSelectors.getActiveUser));
	}

	getUserPermissionIds$(userId: number) {
		return this.store.pipe(
			withLatestFrom(this.store.pipe(select(auzSelectors.isAuthorized))),
			filter(([state, isAuthorized]) => isAuthorized),
			switchMap( e => this.store.pipe(select(userSelectors.getUserPermissionIds(userId))) ),
		);
	}
	getActiveUserPermissionIds$() {
		return this.activeUser$.pipe(
			filter(user => !!user),
			switchMap(user => this.getUserPermissionIds$(user.id))
		);
	}
	getUserPermissions$(userId: number) {
		return this.store.pipe(
			withLatestFrom(this.store.pipe(select(auzSelectors.isAuthorized))),
			filter(([state, isAuthorized]) => isAuthorized),
			switchMap( e => this.store.pipe(select(userSelectors.getUserPermissions(userId))) ),
			distinctUntilChanged((a,b) => isEqual(a,b)),
		);
	}
	getActiveUserPermissions$() {
		return this.activeUser$.pipe(
			filter(user => !!user),
			switchMap(user => this.getUserPermissions$(user.id))
		);
	}


	getUserRoleIds$(userId: number) {
		return this.store.pipe(
			withLatestFrom(this.store.pipe(select(auzSelectors.isAuthorized))),
			filter(([state, isAuthorized]) => isAuthorized),
			switchMap( e => this.store.pipe(select(userSelectors.getUserRoleIds(userId))) ),
		);
	}
	getActiveUserRoleIds$() {
		return this.activeUser$.pipe(
			filter(user => !!user),
			switchMap(user => this.getUserRoleIds$(user.id))
		);
	}
	getUserRoles$(userId: number) {
		return this.store.pipe(
			withLatestFrom(this.store.pipe(select(auzSelectors.isAuthorized))),
			filter(([state, isAuthorized]) => isAuthorized),
			switchMap( e => this.store.pipe(select(userSelectors.getUserRoles(userId))) ),
			distinctUntilChanged((a,b) => isEqual(a,b)),
		);
	}
	getActiveUserRoles$() {
		return this.activeUser$.pipe(
			filter(user => !!user),
			switchMap(user => this.getUserRoles$(user.id))
		);
    }

    goToUserView(user: User) {
        let path = ['/users/' + user.id];
        this.store.dispatch(new Go({ path: path }))
    }

}

