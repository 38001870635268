export class CustomerEmailLogs {
    id: number;
    date: string;
    from: string;
    to: string;
    cc: string;
    bcc: string;
    subject: string;
    body: string;
    headers: string;
}