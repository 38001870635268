import { Injectable } from '@angular/core';
import * as auzActions from '@appRoot/core/user/ngrx-store/actions/authorization.actions';
import * as userActions from '@appRoot/core/user/ngrx-store/actions/user.actions';
import { UserService } from '@appRoot/core/user/services/user.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { xor } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { NGRXError } from '../../../ngrx-store/models/NGRXError';
import { User } from '../../../user/models';
import { IRoleRequestStoreParams, IRoleRequestUpdateParams, Permission } from '../../models';
import { RoleHttpService } from '../../services/role-http.service';
import * as permActions from '../actions/permission.actions';
import * as roleActions from '../actions/role.actions';
import * as permSelectors from '../selectors/permission.selectors';


@Injectable()
export class RoleEffects {

	constructor(
		private store: Store<any>,
		private actions$: Actions,
		private httpService: RoleHttpService,
		private userService: UserService,
		) {}

	@Effect()
	load$: Observable<Action> = this.actions$.pipe(
		ofType(roleActions.ActionTypes.LOAD),
		withLatestFrom(this.userService.activeUser$),
		filter(([action, user]: [roleActions.Load, User]) => !!user),
		switchMap(([action, user]) =>
			this.httpService.fetch({
				...action.payload,
				uid: user.id,
			})
			.pipe(
				switchMap(response => {
					return [
						new roleActions.AddMany({roles: response}),
						new roleActions.LoadSuccess({roles: response}),
					];
				}),
				catchError(error => [
					new roleActions.Error(new NGRXError(action, error)),
					new roleActions.LoadFailed,
				]),
			)),
	);

	@Effect()
	loadAll$: Observable<Action> = this.actions$.pipe(
		ofType(roleActions.ActionTypes.LOAD_ALL),
		withLatestFrom(this.userService.activeUser$),
		filter(([action, user]: [roleActions.LoadAll, User]) => !!user),
		switchMap(([action, user]) =>
			this.httpService.fetch({
				...action.payload,
				uid: user.id,
				size: 'all',
			}).pipe(
				switchMap(response => {
					return [
						new roleActions.AddMany({roles: response}),
						new roleActions.LoadAllSuccess({roles: response}),
					];
				}),
				catchError(error => [
					new roleActions.Error(new NGRXError(action, error)),
					new roleActions.LoadAllFailed,
				]),
			)),
	);

	@Effect()
	get$: Observable<Action> = this.actions$.pipe(
		ofType(roleActions.ActionTypes.GET),
		withLatestFrom(this.userService.activeUser$),
		filter(([action, user]: [roleActions.Get, User]) => !!user),
		mergeMap(([action, user]) =>
			this.httpService.show(action.payload.id, {uid: user.id}).pipe(
				switchMap(response => {
					return [
						new roleActions.GetSuccess({role: response}),
					];
				}),
				catchError(error => [
					new roleActions.Error(new NGRXError(action, error)),
				]),
		)),
	);

	@Effect()
	create$: Observable<Action> = this.actions$.pipe(
		ofType(roleActions.ActionTypes.CREATE),
		withLatestFrom(this.userService.activeUser$),
		filter(([action, user]: [roleActions.Create, User]) => !!user),
		switchMap(([action, user]) => this.store.pipe(
			select(permSelectors.getEntitiesByIds(action.payload.role.permission_ids)),
			map(permission => [action,user,permission] as [roleActions.Create, User, Permission[]]),
			take(1),
		)),
		switchMap(([action, user, permissions]) => {
			let request: IRoleRequestStoreParams = {
				uid: user.id,
				name: action.payload.role.name
			};
			if (action.payload.role.label) request.label = action.payload.role.label;
			if (action.payload.role.description) request.description = action.payload.role.description;
			if (permissions.length) request.permissions = permissions.map(p => p.name);

			return this.httpService.store(request).pipe(
				switchMap(response => {
					return [
						new roleActions.CreateSuccess({role: response}),
					];
				}),
				catchError(error => [
					new roleActions.Error(new NGRXError(action, error)),
				]),
			)
		}),
	);

	@Effect()
	remove$: Observable<Action> = this.actions$.pipe(
		ofType(roleActions.ActionTypes.REMOVE),
		withLatestFrom(this.userService.activeUser$),
		filter(([action, user]: [roleActions.Remove, User]) => !!user),
		mergeMap( ([action, user]) => {
			return this.httpService.destroy(action.payload.role.id, {uid: user.id}).pipe(
				switchMap(response => [
					new roleActions.RemoveSuccess({role: action.payload.role}),
				]),
				catchError( error => [
					new roleActions.RemoveFailed({role: action.payload.role}),
					new roleActions.Error(new NGRXError(action, error)),
				]),
			);
		}),
	);

	@Effect()
	removeSuccess$: Observable<Action> = this.actions$.pipe(
		ofType(roleActions.ActionTypes.REMOVE_SUCCESS),
		withLatestFrom(this.userService.activeUser$),
		switchMap(([action, activeUser]: [roleActions.RemoveSuccess, User]) => {
			let actions: Action[] = [];

			// mark related permissions after removing the role
			if(Array.isArray(action.payload.role.permission_ids) && action.payload.role.permission_ids.length){
				actions.push( new permActions.MarkAsChanged({ids: action.payload.role.permission_ids}) );
			}

			// Load active User access if this role was belonged to him
			let userHasRole = activeUser.roleIds.find(rid => rid === action.payload.role.id);
			if(!!userHasRole){
				actions.push( new userActions.GetById(activeUser.id) );
				actions.push( new auzActions.GetActiveUserPermissions );
			}

			return actions;
		}),
	);

	@Effect()
	update$: Observable<Action> = this.actions$.pipe(
		ofType(roleActions.ActionTypes.UPDATE),
		withLatestFrom(this.userService.activeUser$),
		filter(([action, user]: [roleActions.Update, User]) => !!user),
		switchMap(([action, user]) => this.store.pipe(
			select(permSelectors.getEntitiesByIds(action.payload.changed.permission_ids)),
			map(permission => [action,user,permission] as [roleActions.Update, User, Permission[]]),
			take(1),
		)),
		switchMap(([action, user, permissions]) => {
			let request: IRoleRequestUpdateParams = {
				uid: user.id,
				label: action.payload.changed.label,
				description: action.payload.changed.description,
				permissions: permissions.map(permission => permission.name),
			};
			return this.httpService.update(action.payload.origin.id, request).pipe(
				map(response => new roleActions.UpdateSuccess({origin: action.payload.origin, changed: response})),
				catchError(error => [
					new roleActions.Error(new NGRXError(action, error)),
				]),
			);
		}),
	);

	@Effect({})
	updateSuccess$: Observable<Action> = this.actions$.pipe(
		ofType(roleActions.ActionTypes.UPDATE_SUCCESS),
		withLatestFrom(this.userService.activeUser$),
		switchMap(([action, user]: [roleActions.UpdateSuccess, User]) => {
			let actions: Action[] = [];
			let changed_pids = xor(action.payload.origin.permission_ids, action.payload.changed.permission_ids);

			if (changed_pids.length) {
				// mark roles which permissions was changed
				actions.push(new permActions.MarkAsChanged({ids: changed_pids}));
				// Active User has this role
				if (user.roleIds.find(id => id === action.payload.changed.id)) {
					actions.push(new auzActions.GetActiveUserPermissions);
				}
			}
			return actions;
		}),
	);

}
