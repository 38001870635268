import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl } from '@angular/forms';


@Directive({
    selector: '[field-error]',
})
export class FieldErrorDirective implements OnInit{

	@Input() control: AbstractControl;
    @Input() messages: Map<AbstractControl, {[key:string]: string}>;

    constructor(private renderer: Renderer2, private el: ElementRef){
    }

    ngOnInit() {
    	this.showErrors();
    }

    showErrors(){
    	if(this.control && this.control instanceof AbstractControl){
    		this.control.statusChanges.subscribe(status => {
                this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.getMessage());
    		});
    	}
    }

    private getMessage(): string {
    	const error: string = this.control.errors? Object.keys(this.control.errors)[0]: null;
    	return (!this.control.pristine && this.messages && this.messages.has(this.control) && this.messages.get(this.control)[error])? this.messages.get(this.control)[error] : '';
    }

}
