import { IInvoiceLines, IInvoiceVariations } from '../../customer/models/ICUstomerRequestParams';
import { InvoiceLinesVariations } from './InvoiceLinesVariations';

export class InvoiceLines {
	SequenceNumber: number;
	ItemCode: string;
	Text: string;
	Text2: string;
	Warehouse: string;
	Quantity: number;
	UnitQuantity: number;
	UnitCode: string;
	UnitPrice: number;
	UnitPriceWithTax: number;
	Discount: number;
	DiscountAmount: number;
	DiscountAmountWithTax: number;
	TotalAmount: number;
	TotalAmountWithTax: number;
	Dim1: string;
	Variations: IInvoiceVariations[];

	constructor(data?: IInvoiceLines){
		if(data){
			this.SequenceNumber = data.SequenceNumber;
			this.ItemCode = data.ItemCode;
			this.Text = data.Text;
			this.Text2 = data.Text2;
			this.Warehouse = data.Warehouse;
			this.Quantity = data.Quantity;
			this.UnitQuantity = data.UnitQuantity;
			this.UnitCode = data.UnitCode;
			this.UnitPrice = data.UnitPrice;
			this.UnitPriceWithTax = data.UnitPriceWithTax;
			this.Discount = data.Discount;
			this.DiscountAmount = data.DiscountAmount;
			this.DiscountAmountWithTax = data.DiscountAmountWithTax;
			this.TotalAmount = data.TotalAmount;
			this.TotalAmountWithTax = data.TotalAmountWithTax;
			this.Dim1 = data.Dim1;

			data.Variations.map(variant => {
				this.Variations.push(new InvoiceLinesVariations(variant));
			})
		}
	}
}