import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from '../reducers/registration.reducer';

export const getStore = createFeatureSelector<State>('registration');

export const getLoading = createSelector(
  getStore,
  (state) => state.loading
);

export const getError = createSelector(
  getStore,
  (state) => state.error
);

export const getComplete = createSelector(
  getStore,
  (state) => state.complete
);