import { isNil } from 'lodash';

export interface IOneTimeLinkResponse {
    id: number,
    token: string,
    entity_id: number,
    expires_at: string,
    link: string,
}

export class OneTimeLink {
    id: number;
    token: string;
    entity_id: number;
    expires_at: Date;
    url: string;

    constructor(response?: IOneTimeLinkResponse) {
        if (response) {
            this.createFromResponse(response);
        }
    }

    createFromResponse(response: IOneTimeLinkResponse) {
        this.id = response.id;
        this.token = response.token;
        this.entity_id = response.entity_id;
        this.expires_at = !isNil(response.expires_at) ? new Date(response.expires_at) : null;
        this.url = response.link;
    }
}