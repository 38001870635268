<button
    (click)="onClick()"
    [className]="btnClass + ' ' + additionalClass"
    [isDisabled]="!showTooltip"
    [tooltip]="tooltip" placement="top" type="button"
>
    <fa-icon *ngIf="type === 'back'" [icon]="['fas', 'reply']"></fa-icon>
    <fa-icon *ngIf="type === 'forward'" [icon]="['fas', 'share']"></fa-icon>
    <fa-icon *ngIf="type === 'up'" [icon]="['fas', 'level-up-alt']"></fa-icon>
    <fa-icon *ngIf="type === 'reload'" [icon]="['fas', 'sync']"></fa-icon>
</button>
