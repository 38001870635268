import { Actions, ActionTypes } from '../actions/self-signup-accounts.actions';
import {
    ISelfSignupRequestStoreCustomerParams,
    ISelfSignupRequestStorePaymentParams
} from "@appRoot/lazy-modules/self-signup/models";


export interface State {
    package_id: number;
    domain: string;
    customer: ISelfSignupRequestStoreCustomerParams;
    customer_number: string;
    customer_id: number;
    customer_card: ISelfSignupRequestStorePaymentParams;
}

export const initialState: State = {
    package_id: null,
    domain: null,
    customer: null,
    customer_number: null,
    customer_id: null,
    customer_card: null
};

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {

        case ActionTypes.SET_PACKAGE_ID:
            return {
                ...state,
                package_id: action.package_id
            };

        case ActionTypes.SET_DOMAIN:
            return {
                ...state,
                domain: action.domain
            };

        case ActionTypes.SET_CUSTOMER:
            return {
                ...state,
                customer: action.customer
            };

        case ActionTypes.SET_CUSTOMER_NUMBER:
            return {
                ...state,
                customer_number: action.customer_number
            };

        case ActionTypes.SET_CUSTOMER_ID:
            return {
                ...state,
                customer_id: action.id
            };

        case ActionTypes.SET_CUSTOMER_CARD:
            return {
                ...state,
                customer_card: action.card
            };

        case ActionTypes.RESET:
            return {
                ...initialState
            };

        default:
            return state;
    }
}