<div class="card mb-3 border plan-card mr-3 {{card_class}}">

    <div class="card-header text-white text-center d-flex justify-content-center align-items-center {{header_class}}">
        {{package.name}}
    </div>

    <div class="card-body bg-light">
        <div [innerHTML]="package.description | safeHtml"></div>
        <div><hr></div>
        <div class="font-weight-bold" *ngIf="package.price">Price: {{ (package.price | number : '1.0-0':'es') + ' kr/month' }}</div>
    </div>

    <div class="card-footer text-white text-center d-flex justify-content-center align-items-center text-uppercase {{footer_class}}">
        <ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
    </div>

</div>