import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgUnsubscribe } from '@appRoot/core/interfaces';
import { Role } from '@appRoot/core/roles-permissions/models';
import * as rolesSelectors from '@appRoot/core/roles-permissions/ngrx-store/selectors/role.selectors';
import { User } from '@appRoot/core/user/models';
import * as userActions from '@appRoot/core/user/ngrx-store/actions/user.actions';
import * as userSelectors from '@appRoot/core/user/ngrx-store/selectors/user.selectors';
import { select, Store } from '@ngrx/store';
import { isEmpty, uniqBy } from 'lodash';
import { INgxSelectOption, NgxSelectComponent } from 'ngx-select-ex';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';


@Component({
    selector: 'app-user-autocomplete',
    templateUrl: './user-autocomplete.component.html',
    styleUrls: ['./user-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAutocompleteComponent implements OnInit, NgUnsubscribe {

    readonly ngUnsubscribe = new Subject;

    selectCtrl = new FormControl(null);
    items: {user: User, text: string }[] = [];
    typed: Subject<string> = new Subject;
    roles$: BehaviorSubject<string> = new BehaviorSubject(null);

    @ViewChild('ngxSelect', { static: true }) ngxSelect: NgxSelectComponent;
    @Output() userChanges: EventEmitter<User> = new EventEmitter();
    @Input() callback: (data: {user: User}, search: string) => boolean = (val, string) => true;
    @Input() searchParamsCallback: (search: string) => ConstructorParameters<typeof userActions.Load>[0];
    @Input()
    set roles(roleName: string) {
        this.roles$.next(roleName);
    }
    private role: Role;

    constructor(
        private store: Store<any>,
        private cdRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this.getRole$().subscribe(role => {
            this.role = role;
        });

        this.searchChanges$().subscribe(search => {
            let params = this.searchParamsCallback ? this.searchParamsCallback(search) : this.getRoleParams(search);
            this.store.dispatch(new userActions.Load(params));
        });

        this.getUser$().subscribe(results => {
            this.items = results.map(item => {
                return Object.assign({}, item, { text: this.searchFormat(item) });
            });
            this.cdRef.detectChanges();
        });

    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    unsubscribe() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    searchFormat(val: {user: User;}): string {
        let output = '';
        if (val.user && val.user.name) {
            output = output + val.user.name + ' ';
        }
        if(val.user && val.user.email) {
            output = output + val.user.email + ' ';
        }
        output = output.trim();
        return output;
    }

    searchIn(val: {user: User;}): string {
        let output = '';
        if (val.user && val.user.name) {
            output = output + val.user.name + ' ';
        }
        if(val.user && val.user.email){
            output = output + val.user.email + ' ';
        }
        output = output.trim().toLowerCase();
        return output;

    }

    selectionChanges($event: INgxSelectOption[]) {
        if(!isEmpty($event)) {
            this.userChanges.next(<User>$event[0].data.user)
        } else {
            this.userChanges.next(null);
        }
    }

    private getRoleParams(search: string): ConstructorParameters<typeof userActions.Load>[0] {
        return {
            search: {
                strict: false,
                method: 'OR',
                name: search,
                email: search,
            },
            page: 1,
            size: 20,
            roles: this.role ? [this.role] : [],
        };
    }

    private getRole$() {
        return this.roles$.pipe(
            filter(e => !!e),
            switchMap(roleName => this.store.pipe(select(rolesSelectors.getEntityByName(roleName)))),
            takeUntil(this.ngUnsubscribe)
        );
    }

    private searchChanges$() {
        return this.typed.pipe(
            takeUntil(this.ngUnsubscribe),
            map(e => e.trim()),
            filter(e => !isEmpty(e) && e.length > 1),
            debounceTime(300),
        )
    }

    private users$(): Observable<User[]> {
        return this.searchChanges$().pipe(
            switchMap(search => combineLatest([
                this.store.pipe(select(userSelectors.findUsersBy('name', search)), startWith([] as User[])),
                this.store.pipe(select(userSelectors.findUsersBy('email', search)), startWith([] as User[])),
            ])),
            takeUntil(this.ngUnsubscribe),
            filter(e => Array.isArray(e)),
            map(([us1, us2]) => uniqBy([...us1, ...us2], 'id')),
        );
    }

    private getUsers$() {
        return this.users$().pipe(
            map(users => this.role ? users.filter(e => e.roleIds.includes(this.role.id)) : users)
        )
    }

    private getUser$(): Observable<{user: User}[]> {
        return this.getUsers$().pipe(
            switchMap(users => {
                let ids = users.map(item => item.id);
                return this.store.pipe(
                    select(userSelectors.getUsersByIds(ids)),
                    map((): {user: User}[] => {
                        return users.map(user => {
                            return {
                                user: user,
                            };
                        });
                    })
                );
            }),
            takeUntil(this.ngUnsubscribe),
        );
    }

}
