import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Customer, CustomerContacts } from "@appRoot/core/customer/models";
import * as customerContactsSelectors from '@appRoot/core/customer/ngrx-store/selectors/customer-contacts.selectors';
import { CustomerService } from '@appRoot/core/customer/services/customer.service';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { NgUnsubscribe } from '../../../../core/interfaces';
import {CustomerRenderInfoComponent} from "@appRoot/shared/components/customer-info/customer-render-info/customer-render-info.component";


@Component({
    selector: 'app-customer-short-info',
    templateUrl: './customer-short-info.component.html',
    styleUrls: ['./customer-short-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerShortInfoComponent implements OnInit, NgUnsubscribe {

    readonly ngUnsubscribe = new Subject();

    public customer$ = new BehaviorSubject<Customer>(null);
    public faCheck = faCheck;
    private customerContacts: CustomerContacts;

    @Input()
    set customer(customer: Customer) {
        this.customer$.next(customer);
    };

    constructor(
        public cdr: ChangeDetectorRef,
        private customerService: CustomerService,
        private store: Store<any>
    ) {}

    ngOnInit() {
        this.getCustomerContacts().subscribe(data => {
            this.customerContacts = data;
            this.cdr.detectChanges();
        });
    }

    ngOnDestroy() {
        this.unsubscribe();
    }

    unsubscribe() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    getCustomerContacts() {
        return this.customer$.pipe(
            filter(e => !!e),
            switchMap(customer => this.store.pipe(select(customerContactsSelectors.getEntityById(customer.id)))),
            takeUntil(this.ngUnsubscribe),
        );
    }

    goToCustomerView(customerInfoCmp: CustomerRenderInfoComponent) {
        if (customerInfoCmp.customer) {
            this.customerService.goToCustomerView(customerInfoCmp.customer)
        }
    }
}
