import { NgModule } from '@angular/core';
import { SelfSignupContainer } from './components/self-signup-container/self-signup-container.component';
import { LoginFormComponent } from './components/step-register/login-form/login-form.component';
import { PlanSelectTemplateComponent } from './components/plan-select-template/plan-select-template.component';
import { CustomerRegisterFormComponent } from './components/step-register/customer-register-form/customer-register-form.component';
import { StepProgressContainer } from './components/step-progress/step-progress.component';
import { StepRegisterContainer } from './components/step-register/step-register-container/step-register-container.component';
import { StepServiceContainer } from './components/step-service/step-service-container/step-service-container.component';
import { AccountsFormComponent } from './components/step-service/accounts-form/accounts-form.component';
import { PaymentFormComponent } from './components/step-payment/payment-form/payment-form.component';
import { StepCompletionComponent } from './components/step-completion/step-completion.component';
import { SharedModule } from "@appRoot/shared/shared.module";
import { EffectsModule } from "@ngrx/effects";
import { effects, reducers } from "@appRoot/lazy-modules/self-signup/ngrx-store";
import { StoreModule } from "@ngrx/store";


@NgModule({
    imports: [
        SharedModule,
        EffectsModule.forFeature(effects),
        StoreModule.forFeature('SelfSignupModule', reducers),
    ],
    declarations: [
    	SelfSignupContainer,
        PlanSelectTemplateComponent,
        LoginFormComponent,
        CustomerRegisterFormComponent,
        StepProgressContainer,
        StepRegisterContainer,
        StepServiceContainer,
        AccountsFormComponent,
        PaymentFormComponent,
        StepCompletionComponent
    ],
    exports: [
        SelfSignupContainer
    ],
    id: 'SelfSignupLazyModule'
})
export class SelfSignupModule {}