import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { BasicForm } from '@appRoot/core/forms/form-basic.form';
import { ErrorCatcherService } from '@appRoot/error-catcher/error-catcher.service';
import { CustomValidators } from 'ngx-custom-validators';
import { filter, skip, takeUntil } from 'rxjs/operators';
import * as resetAction from '../../ngrx-store/actions/reset-password.actions';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordComponent extends BasicForm {

	@Input() public token: string = null;
	public errorShow: boolean = false;
	public errorMessage: string = null;
	public errorMessages = null;
	public validationMessages: Map<AbstractControl, {[key:string]: string}>;
	public isTokenValid: boolean = false;
	public complete: boolean = false;


	constructor(
		private authService: AuthenticationService,
		private errorCatcherService: ErrorCatcherService,
		private cdr: ChangeDetectorRef,
		) {
		super();
	}

	ngOnInit() {
		super.ngOnInit();

		this.authService.dispatchResetPasswordResetState();

		this.errorSubscriber();
		this.loadingSubscriber();
		this.isTokenValidSubscriber();
		this.completeSubscriber();

		if(this.token){
			this.authService.dispatchResetPasswordCheck(this.token);
		}
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	createForm(){
		let password = new FormControl(null, [ Validators.required, Validators.minLength(6) ]);
		let confirmPassword = new FormControl(null, [Validators.required, CustomValidators.equalTo(password)]);

		this.form = new FormGroup({
			password: password,
			confirmPassword: confirmPassword,
		});
	}

	get password() { return this.form.get('password'); }
	get confirmPassword() { return this.form.get('confirmPassword'); }

	onSubmit(): void {
        super.onSubmit();

        if(this.form.valid && this.isTokenValid) {
        	this.errorShow = false;
        	this.authService.dispatchResetPassword({
        		password: this.password.value , 
        		password_confirmation: this.confirmPassword.value,
        		token: this.token,
        	})
        }
    }

    private errorSubscriber(){
        return this.authService.getResetPasswordError$()
        .pipe(takeUntil(this.ngUnsubscribe), skip(1), filter(e => e !== null))
		.subscribe(error => {
        	if( (error.action instanceof resetAction.Check || error.action instanceof resetAction.ResetPassword) 
        		&& this.errorCatcherService.has(error.instance) ){
                let cError = this.errorCatcherService.get(error.instance);
                this.errorMessage = cError.message;
                this.errorMessages = cError.errors ? cError.errors: null;
                this.errorShow = true;
                this.cdr.detectChanges();
            }
        });
    }

    private loadingSubscriber(){
    	return this.authService.getResetPasswordLoading$().pipe(takeUntil(this.ngUnsubscribe))
		.subscribe(e => {
            this.loading = e;
            this.cdr.detectChanges();
        });
    }

    private isTokenValidSubscriber(){
    	return this.authService.getResetPasswordValid$().pipe(takeUntil(this.ngUnsubscribe)).subscribe(e => {
    		this.isTokenValid = e;
    		this.cdr.detectChanges();
    	});
    }

    private completeSubscriber(){
    	return this.authService.getResetPasswordComplete$().pipe(takeUntil(this.ngUnsubscribe)).subscribe(e => {
    		this.complete = e;
    		this.cdr.detectChanges();
    	});
    }



}
