import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { of, Subject } from 'rxjs';
import * as userSelectors from '@appRoot/core/user/ngrx-store/selectors/user.selectors';
import * as userActions from '@appRoot/core/user/ngrx-store/actions/user.actions';
import {
    concatMap,
    delay,
    distinctUntilChanged,
    filter,
    switchMap,
    take,
    takeWhile,
    tap,
    withLatestFrom
} from 'rxjs/operators';


@Injectable({
    providedIn: 'root',
})
export class UserInfoService {
    public toggleAllTooltips$: Subject<'show' | 'hide'> = new Subject();

    private userLoader = new Subject<number>();
    private userSubscriberCreated = false;

    constructor(
        private store: Store<any>
    ){}

    public loadUser(id: number){
        if(!this.userSubscriberCreated){
            this.userSubscriberCreated = true;
            this.userLoader.pipe(
                distinctUntilChanged(),
                concatMap(x => of(x).pipe(
                    delay(10),
                    switchMap(id => this.store.pipe(select(userSelectors.getGetting) ),(id, loading) => ({id, loading}) ),
                    filter(({id, loading}) => !loading),
                    withLatestFrom( this.store.pipe(select(userSelectors.getUserById(x))), ({id, loading},account) => ({id, loading, account})),
                    takeWhile(({id, loading, account}) => !account),
                    tap(({id, loading, account}) => {
                        this.store.dispatch( new userActions.GetById(id) )
                    }),
                    take(1),
                ))
            ).subscribe();
        }
        this.userLoader.next(id);
    }
}
