import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { getGetting, getUserById } from '@appRoot/core/user/ngrx-store/selectors/user.selectors';
import { GetById } from '@appRoot/core/user/ngrx-store/actions/user.actions';


export const loadUserById = (store: Store<any>, id: number, maxAttempts: number = 3): Observable<any> => {
    return store.pipe(
        select(getGetting),
        filter(loading => !loading),
        switchMap(loading => store.pipe(select(getUserById(id)))),
        take(maxAttempts),
        takeWhile(e => !e),
        tap(e => store.dispatch(new GetById(id))),
    );
};
