import { Action } from '@ngrx/store';
import { CustomerGroupsChangeNotificationMain } from '../../models';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';


export enum ActionTypes {
    GET_BY_GROUP_ID = '[Customer Groups Change Notifications Main] get by Customer group id',
    GET_BY_GROUP_ID_SUCCESS = '[Customer Groups Change Notifications Main] get by Customer group id success',
    GET_BY_GROUP_ID_FAILED = '[Customer Groups Change Notifications Main] get by Customer group id failed',

    ERROR = '[Customer Groups Change Notifications Main] error',

    UPDATE = '[Customer Groups Change Notifications Main] update',
    UPDATE_SUCCESS = 'Customer Groups Change Notifications Main] update success',
    UPDATE_FAILED = 'Customer Groups Change Notifications Main] update failed',
}

export class GetByGroupId implements Action {
    readonly type = ActionTypes.GET_BY_GROUP_ID;

    constructor(readonly payload: number) {}
}
export class GetByGroupIdSuccess implements Action {
    readonly type = ActionTypes.GET_BY_GROUP_ID_SUCCESS;
    constructor(readonly payload: CustomerGroupsChangeNotificationMain) {}
}
export class GetByGroupIdFailed implements Action {
    readonly type = ActionTypes.GET_BY_GROUP_ID_FAILED;
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;
    constructor(readonly payload: NGRXError<Actions>) { }
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;
    constructor(readonly payload: {
        notification: CustomerGroupsChangeNotificationMain;
        changes: {notification: CustomerGroupsChangeNotificationMain;};
    }) { }
}
export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
    constructor(readonly payload: CustomerGroupsChangeNotificationMain) {}
}
export class UpdateFailed implements Action {
    readonly type = ActionTypes.UPDATE_FAILED;
}

export type Actions =
    | GetByGroupId
    | GetByGroupIdSuccess
    | GetByGroupIdFailed
    | Error
    | Update
    | UpdateSuccess
    | UpdateFailed
    ;