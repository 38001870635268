<div class="row mb-3">
    <div class="col-12 text-center">
        <p>Please enter your card details.</p>
    </div>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()" role="form" name="paymentForm" novalidate="">
    <div class="card">
        <div class="card-header text-muted">
            <small>Enter your card details</small>
        </div>

        <div class="card-body bg-light">
            <div class="row mb-2">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="name" class="font-weight-bold">Name</label>
                        <input class="form-control"
                               id="name"
                               type="text"
                               [formControl]="nameCtrl"
                               formControlName="name"
                               placeholder="Enter your name">
                        <mat-error field-error [control]="nameCtrl" [messages]="validationMessages"></mat-error>
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="edit-billing-card-form-number" class="font-weight-bold">Credit Card Number</label>
                        <div class="input-group">
                            <input class="form-control"
                                   type="tel"
                                   placeholder="0000 0000 0000 0000"
                                   maxlength="20"
                                   [formControl]="creditCardCtrl"
                                   formControlName="creditCard"
                                   id="edit-billing-card-form-number"
                                   autocomplete="cc-number"
                                   ccNumber
                                   #ccNumber
                                   (keyup)="cardNumberSpacing()">
                            <div class="input-group-append">
                                    <span class="input-group-text">
                                        <fa-icon [icon]="['fas', 'credit-card']"></fa-icon>
                                    </span>
                            </div>
                        </div>
                        <mat-error field-error [control]="creditCardCtrl" [messages]="validationMessages"></mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-sm-12">
                    <label for="cc-exp-date" class="font-weight-bold">Month/Year</label>
                    <input class="form-control"
                           id="cc-exp-date"
                           type="tel"
                           placeholder="mm/yy"
                           maxlength="5"
                           [formControl]="expirationDateCtrl"
                           formControlName="expirationDate"
                           autocomplete="cc-exp"
                           ccExp
                           #ccExp
                           (keyup)="dateMask()">
                </div>
            </div>
        </div>

        <div class="card-footer text-muted">
            <small>Your card info will be securely stored and used for automatic billing</small>
        </div>
    </div>

    <hr class="mt-5">

    <button [disabled]="loading" class="btn btn-primary btn-block" type="submit">{{ 'Next step' | translate }}</button>
</form>