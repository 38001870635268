import { ROUTE_NAMES as CUSTOMERS_ROUTE_NAMES } from "@appRoot/lazy-modules/customers/routes.names";
import { WHM_COMMON_MAP } from '@appRoot/lazy-modules/whm-module/whm-routing-module/whm_common.route.map';
import { ROUTE_PATHS } from "@appRoot/routes/routes.names";
import { NavItem } from "../shared/components/nav-item/NavItem";


const Home: NavItem = {
    text: 'Home',
    link: '/home',
    icon: ['fas', 'home']
};


const Pages: NavItem = {
    text: 'Pages',
    link: '/pages',
    icon: ['fas', 'file'],
    submenu: [
        {
            text: 'Login',
            link: '/login'
        },
        {
            text: 'Register',
            link: '/register'
        },
        {
            text: 'Recover',
            link: '/recover'
        },
        {
            text: 'Lock',
            link: '/lock'
        },
        {
            text: '404',
            link: '/404'
        },
        {
            text: '500',
            link: '/500'
        },
        {
            text: 'Maintenance',
            link: '/maintenance'
        }
    ]
};


const headingMain: NavItem = {
    text: 'Main Navigation',
    heading: true
};


const Settings: NavItem = {
    text: 'General Settings',
    link: '/settings',
    icon: ['fas', 'cog'],
    permissions: [
        'view_permission',
        'view_role',
        'view_user',
        'view_ip_restrictions',
        'edit_packages',
        'edit_products',
        'edit_sources',
        'view_email_log',
        'view_login_logs'
    ],
    submenu: [
        {
            text: 'All users',
            link: '/users/all',
            permissions: ['view_user'],
        },
        {
            text: 'Permissions',
            link: '/settings/permissions',
            permissions: ['view_permission'],
        },
        {
            text: 'Permission Groups',
            link: '/settings/permissions/groups',
            permissions: ['view_permission'],
        },
        {
            text: 'Roles',
            link: '/settings/roles',
            permissions: ['view_role'],
        },
        {
            text: 'Ip Restrictions',
            link: '/settings/restrictions',
            permissions: ['view_ip_restrictions'],
        },
        {
            text: 'Packages',
            link: '/settings/packages',
            permissions: ['edit_packages'],
        },
        {
            text: 'Products',
            link: '/settings/products',
            permissions: ['edit_products'],
        },
        {
            text: 'Sources',
            link: '/settings/sources',
            permissions: ['edit_sources'],
        },
        {
            text: 'Sent emails',
            link: '/settings/email-logs',
            permissions: ['view_email_log'],
        },
        {
            text: 'Login Logs',
            link: '/settings/login-logs',
            permissions: ['view_login_logs'],
        },
    ]
};


const Modules: NavItem = {
    text: 'Modules',
    link: '/modules',
    icon: ['fas', 'cog'],
    permissions: ['modules_view'],
    submenu: [
        {
            text: 'WHM Module',
            submenu: [
                {
                    text: 'Production Servers',
                    link: '/modules/whm/prod',
                    permissions: ['setting_whm_account'],
                },
                {
                    text: 'DEV Servers',
                    link: '/modules/whm/dev',
                    permissions: ['setting_whm_dev_account'],
                },
                {
                    text: 'Import Accounts',
                    link: '/modules/whm/import',
                    permissions: ['grant_access_services'],
                },
                {
                    text: 'Manage Accounts',
                    link: '/modules/whm/all/manage',
                    permissions: ['grant_access_services'],
                },
                {
                    text: 'Accounts History',
                    link: '/modules/whm/history',
                    permissions: ['view_whm_history'],
                },
            ]
        },
        {
            text: 'Customer Module',
            submenu: [
                {
                    text: 'Import Customers',
                    link: '/modules/'+ROUTE_PATHS.CustomersModule+'/'+CUSTOMERS_ROUTE_NAMES.dkImport,
                    permissions: ['import_customers'],
                },
                {
                    text: 'Customer groups',
                    link: '/modules/'+ROUTE_PATHS.CustomersModule+'/'+CUSTOMERS_ROUTE_NAMES.groups+'/all/1',
                    permissions: ['view_customer_groups'],
                },
                {
                    text: 'Customer history',
                    link: '/modules/'+ROUTE_PATHS.CustomersModule+'/'+CUSTOMERS_ROUTE_NAMES.history+'/customer',
                    permissions: ['view_customer_history'],
                },
                {
                    text: 'Mail templates',
                    link: '/modules/'+ROUTE_PATHS.CustomersModule+'/'+CUSTOMERS_ROUTE_NAMES.mailTemplates+'/all',
                    permissions: ['edit_customers_mail_templates'],
                },
                {
                    text: 'Expense settings',
                    link: '/modules/'+ROUTE_PATHS.CustomersModule+'/'+CUSTOMERS_ROUTE_NAMES.expenseSettings,
                    permissions: ['update_customer_expense_settings'],
                }
            ],
            permissions: ['customer_modules_view'],
        },
        {
            text: 'Pricelist Module',
            submenu: [
                {
                    text: 'Import Products',
                    link: '/modules/'+ROUTE_PATHS.DkPricelistsModule+'/import',
                    permissions: ['import_dk_pricelists'],
                },
            ]
        },
        {
            text: 'Notifications',
            link: '/modules/'+ROUTE_PATHS.NotificationModule,
            permissions: ['view_notification'],
        }
    ]
};


const Customers: NavItem = {
    text: 'Customers',
    link: '/customers',
    icon: ['fas', 'users'],
    permissions: ['user_view_customer', 'user_view_all_customer'],
    route_name: ROUTE_PATHS.CustomersModule
};


const AllAccounts: NavItem = {
    text: 'All Accounts',
    link: `/modules/whm/${WHM_COMMON_MAP.container}/${WHM_COMMON_MAP.list}`,
    icon: ['fas', 'clipboard-list'],
    permissions: ['grant_access_services', 'view_all_accounts'],
};


const Resellers: NavItem = {
    text: 'Resellers',
    link: '/resellers',
    icon: ['fas', 'users'],
    permissions: ['view_user'],
    submenu: [
        {
            text: 'List customers',
            link: '/resellers/list'
        },
    ]
};


const Services: NavItem = {
    text: 'Services',
    link: '/'+ROUTE_PATHS.TacServicesModule,
    icon: ['fas', 'info'],
    permissions: ['view_own_services'],
};


const Billing: NavItem = {
    text: 'Billing',
    link: '/billing',
    icon: ['fas', 'credit-card'],
    permissions: ['view_billing'],
    submenu: [
        {
            text: 'Settings',
            link: '/billing/settings'
        },
    ]
};


const SharedServices: NavItem = {
    text: 'Shared services',
    link: '/services/shared',
    icon: ['fas', 'info'],
    permissions: ['view_own_services'],
    submenu: [
        {
            text: 'List shared services',
            link: '/services/shared/list'
        },
    ]
};


const Notifications: NavItem = {
    text: 'Notifications',
    link: '/notifications',
    icon: ['fas', 'bell'],
    permissions: ['view_notification'],
    submenu: [
        {
            text: 'My notifications',
            link: '/notifications/list'
        },
    ]
};

const SharedAccounts: NavItem = {
    text: 'Shared Accounts',
    link: '/'+ROUTE_PATHS.UsersModule+'/:userId/shared-accounts/whm/usershared/list',
    icon: ['fas', 'share-square'],
    permissions: ['view_own_user'],
    route_name: 'shared'
};

const Pricelists: NavItem = {
    text: 'Pricelist',
    link: '/pricelists',
    icon: ['fas', 'list'],
    permissions: ['view_dk_pricelists'],
    route_name: ROUTE_PATHS.DkPricelistsModule
};

export const menu = [
    Home,
    Customers,
    AllAccounts,
    SharedAccounts,
    Pricelists,
    Settings,
    Modules
];
