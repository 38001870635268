import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Action } from '@ngrx/store';
import { CustomerExpenseSettings } from '../../models';


export enum ActionTypes {
    ERROR = '[Customer expense settings] error',

    LOAD = '[Customer expense settings] load',
    LOAD_SUCCESS = '[Customer expense settings] load success',
    LOAD_FAILED = '[Customer expense settings] load failed',

    UPDATE = '[Customer expense settings] update',
    UPDATE_SUCCESS = '[Customer expense settings] update success',
    UPDATE_FAILED = '[Customer expense settings] update failed'
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;
    constructor(readonly payload: NGRXError<Actions>) {}
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;
}
export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;
    constructor(readonly payload: CustomerExpenseSettings) {}
}
export class LoadFailed implements Action {
    readonly type = ActionTypes.LOAD_FAILED;
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;
    constructor(readonly payload: {
        settings: CustomerExpenseSettings;
        changes: {settings: CustomerExpenseSettings;};
    }) { }
}
export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
    constructor(readonly payload: {settings: CustomerExpenseSettings}) { }
}
export class UpdateFailed implements Action {
    readonly type = ActionTypes.UPDATE_FAILED;
}

export type Actions =
    | Error
    | Load
    | LoadSuccess
    | LoadFailed
    | Update
    | UpdateSuccess
    | UpdateFailed
    ;
