import { ChangeDetectionStrategy, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { SettingsService } from '@appRoot/core/services/settings.service';
import { ThemesService } from '@appRoot/core/services/themes.service';
import { TranslatorService } from '@appRoot/core/translator/translator.service';


@Component({
    selector: 'app-offsidebar',
    templateUrl: './offsidebar.component.html',
    styleUrls: ['./offsidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OffsidebarComponent implements OnInit, OnDestroy {

    currentTheme: any;
    selectedLanguage: string;
    private lang$: Subscription;

    constructor(
        public settings: SettingsService,
        public themes: ThemesService,
        public translator: TranslatorService,
        private eRef: ElementRef,
        ) {
        this.currentTheme = themes.getDefaultTheme();
    }

    ngOnInit() {
         this.lang$ = this.translator.getCurrentLanguage().subscribe(lang => {
             if(lang) {
                 this.selectedLanguage = this.getLangs().find(item => item.code === lang).code;
             }

        });
    }

    ngOnDestroy() {
        this.lang$.unsubscribe();
    }

    setTheme() {
        this.themes.setTheme(this.currentTheme);
    }

    getLangs() {
        return this.translator.getAvailableLanguages();
    }

    setLang(value) {
        this.translator.useLanguage(value);
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if(!this.eRef.nativeElement.contains(event.target)) {
            this.settings.layout.offsidebarOpen = false;
        }
    }




}
