import { OmitUID, SortDirection } from '@appRoot/core/interfaces';
import { Action } from '@ngrx/store';
import { NGRXError } from '../../../ngrx-store/models/NGRXError';
import { IRoleRequestIndexParams, Role } from '../../models';

export enum ActionTypes {
    ADD_MANY = '[Roles] add many',
    LOAD = '[Roles] load',
    LOAD_SUCCESS = '[Roles] load success',
    LOAD_FAILED = '[Roles] load failed',
    LOAD_ALL = '[Roles] load all',
    LOAD_ALL_SUCCESS = '[Roles] load all success',
    LOAD_ALL_FAILED = '[Roles] load all failed',
    GET = '[Roles] get',
    GET_SUCCESS = '[Roles] get success',
    REMOVE = '[Roles] remove',
    REMOVE_SUCCESS = '[Roles] remove success',
    REMOVE_FAILED = '[Roles] remove failed',
    CREATE = '[Roles] create',
    CREATE_SUCCESS = '[Roles] create success',
    UPDATE = '[Roles] update',
    UPDATE_SUCCESS = '[Roles] update success',
    ERROR = '[Roles] error',
    RESET = '[Roles] reset',
    MARK_AS_CHANGED = '[Roles] mark as changed',
}

export class AddMany implements Action {
    readonly type = ActionTypes.ADD_MANY;

    constructor(readonly payload: { roles: Role[] }) { }
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;

    constructor(readonly payload: OmitUID<IRoleRequestIndexParams> = {}) { }
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(readonly payload: { roles: Role[] }) { }
}

export class LoadFailed implements Action {
    readonly type = ActionTypes.LOAD_FAILED;
}


export class LoadAll implements Action {
    readonly type = ActionTypes.LOAD_ALL;

    constructor(readonly payload: {
        sort?: string,
        order?: SortDirection,
    } = {}) {
    }
}

export class LoadAllSuccess implements Action {
    readonly type = ActionTypes.LOAD_ALL_SUCCESS;

    constructor(readonly payload: { roles: Role[] }) { }
}

export class LoadAllFailed implements Action {
    readonly type = ActionTypes.LOAD_ALL_FAILED;
}


export class Get implements Action {
    readonly type = ActionTypes.GET;

    constructor(readonly payload: { id: number }) { }
}

export class GetSuccess implements Action {
    readonly type = ActionTypes.GET_SUCCESS;

    constructor(readonly payload: { role: Role }) { }
}

export class Remove implements Action {
    readonly type = ActionTypes.REMOVE;

    constructor(readonly payload: { role: Role }) { }
}

export class RemoveSuccess implements Action {
    readonly type = ActionTypes.REMOVE_SUCCESS;

    constructor(readonly payload: { role: Role }) { }
}

export class RemoveFailed implements Action {
    readonly type = ActionTypes.REMOVE_FAILED;

    constructor(readonly payload: { role: Role }) { }
}

export class Create implements Action {
    readonly type = ActionTypes.CREATE;

    constructor(readonly payload: { role: Role }) { }
}

export class CreateSuccess implements Action {
    readonly type = ActionTypes.CREATE_SUCCESS;

    constructor(readonly payload: { role: Role }) { }
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;

    constructor(readonly payload: { origin: Role, changed: Role }) { }
}

export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;

    constructor(readonly payload: { origin: Role, changed: Role }) { }
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;

    constructor(readonly payload: NGRXError<Actions>) { }
}

export class Reset implements Action {
    readonly type = ActionTypes.RESET;
}

export class MarkAsChanged implements Action {
    readonly type = ActionTypes.MARK_AS_CHANGED;

    constructor(readonly payload: { ids: number[] }) { }
}

export type Actions =
    | AddMany
    | Load
    | LoadSuccess
    | LoadFailed
    | Get
    | GetSuccess
    | Remove
    | RemoveSuccess
    | Create
    | CreateSuccess
    | Update
    | UpdateSuccess
    | Error
    | Reset
    | MarkAsChanged
    | LoadAll
    | LoadAllSuccess
    | LoadAllFailed
    ;