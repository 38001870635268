import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { ErrorCatcherModule } from '@appRoot/error-catcher/error-catcher.module';
import { CookieService } from 'ngx-cookie-service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AuthenticationModule } from './authentication/authentication.module';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { NGRXStoreModule } from './ngrx-store/ngrx-store.module';
import { NotificationModule } from './notification/notification.module';
import { RolesPermissionsModule } from './roles-permissions/roles-permissions.module';
import { ServiceMetadataInterceptor } from './services/service-metadata-interceptor.service';
import { SettingsService } from './services/settings.service';
import { TranslatorModule } from './translator/translator.module';
import { UserModule } from './user/user.module';
import { CustomerModule } from './customer/customer.module';
import { AvailableDataModule } from './available-data/available-data.module';


export function initAppConfig(settings: SettingsService ) {
    return () => settings.loadConfig().toPromise();
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule, // required for ng2-tag-input, ngx-toastr
        HttpClientModule,
        NgxPermissionsModule.forRoot(),
        NgxWebstorageModule.forRoot({ prefix: 'tactica-storage', caseSensitive: true }),

        NGRXStoreModule,
        TranslatorModule,
        ErrorCatcherModule.forRoot(),
        AuthenticationModule.forRoot(),
        UserModule,
        CustomerModule,
        RolesPermissionsModule,
        NotificationModule,
        AvailableDataModule
    ],
    providers: [
        CookieService,
        { provide: APP_INITIALIZER, useFactory: initAppConfig, deps: [SettingsService], multi: true },
        { provide: "RootInjector", useExisting: Injector },
        { provide: HTTP_INTERCEPTORS, useClass: ServiceMetadataInterceptor, multi: true },
    ],
    declarations: [
    ],
    exports: [
    ]
})
export class CoreModule {
    constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
