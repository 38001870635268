import { CustomerMailTemplate } from '../../models';
import { Actions, ActionTypes } from '../actions/customer-mail-templates.actions';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

function selectId(entity: CustomerMailTemplate): number {
    return entity.id;
}

export const adapter: EntityAdapter<CustomerMailTemplate> = createEntityAdapter({
    selectId: selectId,
    sortComparer: false,
});

export interface State extends EntityState<CustomerMailTemplate> {
    variables: string[];
    loading: boolean;
    loaded: boolean;
    creating: boolean;
    updating: boolean;
    deleting: boolean;
    error: NGRXError<Actions>;
}

export const initialState: State = adapter.getInitialState({
    variables: null,
    loading: false,
    loaded: false,
    creating: false,
    updating: false,
    deleting: false,
    error: null,
});



export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {

        case ActionTypes.GET:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.GET_SUCCESS:
            return adapter.addAll(action.payload.templates, {
                ...state,
                variables: action.payload.variables,
                loading: false,
                loaded: true,
            });
        case ActionTypes.GET_FAILED:
            return {
                ...state,
                loading: false,
                loaded: false,
            };

        case ActionTypes.CREATE:
            return {
                ...state,
                creating: true,
            };
        case ActionTypes.CREATE_SUCCESS:
            return adapter.addOne(action.payload.notification, {
                ...state,
                creating: false,
            });
        case ActionTypes.CREATE_FAILED:
            return {
                ...state,
                creating: false,
            };

        case ActionTypes.UPDATE:
            return {
                ...state,
                updating: true,
            };
        case ActionTypes.UPDATE_SUCCESS:
            return adapter.updateOne({
                id: selectId(action.payload.notification),
                changes: action.payload.notification,
            }, {
                ...state,
                updating: false,
            });
        case ActionTypes.UPDATE_FAILED:
            return {
                ...state,
                updating: false,
            };

        case ActionTypes.DELETE:
            return {
                ...state,
                deleting: true,
            };
        case ActionTypes.DELETE_SUCCESS:
            return adapter.removeOne(selectId(action.payload.notification), {
                ...state,
                deleting: false,
            });
        case ActionTypes.DELETE_FAILED:
            return {
                ...state,
                deleting: false,
            };

        case ActionTypes.ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}
