import { ISubscriptionLines } from '../../customer/models/ICUstomerRequestParams';

export class SubscriptionLines {
	ItemCode: string;
	Description: string;
	Description2: string;
	Quantity: number;
	UnitPrice: number;
	UnitPriceWithTax: number;
	DiscountPercent: number;
	Amount: number;
	TaxAmount: number;
	Memo: string;

	constructor(data?: ISubscriptionLines){
		if (data) {
            this.ItemCode = data.ItemCode;
            this.Description = data.Description;
            this.Description2 = data.Description2;
            this.Quantity = data.Quantity;
            this.UnitPrice = data.UnitPrice;
            this.UnitPriceWithTax = data.UnitPriceWithTax;
            this.DiscountPercent = data.DiscountPercent;
            this.Amount = data.Amount;
            this.TaxAmount = data.TaxAmount;
            this.Memo = data.Memo;
		}
	}
}