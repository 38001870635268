import { Action } from '@ngrx/store';
import { ActionTypes, Actions } from '../actions/navigation.actions';


export interface State {
	currentIndex: number;
	history: string[];	
}

export const initialState: State = {
	currentIndex: null,
	history: [],
}

const MAX_SIZE = 30;

export function reducer(state = initialState, action: Actions): State {

	switch (action.type) {
		case ActionTypes.ADD:
			if(action.payload.url == state.history[state.currentIndex]) 
				return state;

			let history = state.history.slice(-MAX_SIZE,state.currentIndex+1);
			let currentIndex = history.push(action.payload.url) - 1;
			return {
				history: history,
				currentIndex: currentIndex,
			};

		case ActionTypes.BACK: 
			return {
				...state,
				currentIndex: state.currentIndex > 0 ? state.currentIndex-1 : 0,
			};

		case ActionTypes.FORWARD: 
			return {
				...state,
				currentIndex: state.currentIndex < state.history.length-1 ? state.currentIndex+1 : state.currentIndex,
			};

		default:
			return state;
	}

}
