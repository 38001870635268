import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgUnsubscribe } from '@appRoot/core/interfaces';
import { Customer, CustomerContacts } from '@appRoot/core/customer/models';
import * as customerActions from '@appRoot/core/customer/ngrx-store/actions/customer.actions';
import * as customerSelectors from '@appRoot/core/customer/ngrx-store/selectors/customer.selectors';
import * as customerContactsSelectors from '@appRoot/core/customer/ngrx-store/selectors/customer-contacts.selectors';
import { select, Store } from '@ngrx/store';
import { isEmpty, uniqBy } from 'lodash';
import { INgxSelectOption, NgxSelectComponent } from 'ngx-select-ex';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-customer-autocomplete',
    templateUrl: './customer-autocomplete.component.html',
    styleUrls: ['./customer-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CustomerAutocompleteComponent implements OnInit, NgUnsubscribe {

    readonly ngUnsubscribe = new Subject;

    selectCtrl = new FormControl(null);
    items: {customer: Customer, text: string }[] = [];
    typed: Subject<string> = new Subject;

    @ViewChild('ngxSelect', { static: true }) ngxSelect: NgxSelectComponent;

    @Output() customerChanges: EventEmitter<Customer> = new EventEmitter();

    @Input() callback: (data: {customer: Customer, data: CustomerContacts}, search: string) => boolean = (val, string) => true;
    @Input() searchParamsCallback: (search: string) => ConstructorParameters<typeof customerActions.Load>[0];
    @Input() autoClear: boolean = false;
    @Input() withoutDisabled: boolean = false;

    constructor(
        private store: Store<any>,
        private cdRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this.ngxSelect.keyCodeToNavigateFirst = "";
        this.ngxSelect.keyCodeToNavigateLast = "";

        this.searchChanges$().subscribe(search => {
            let params = this.searchParamsCallback ? this.searchParamsCallback(search) : this.getParams(search);
            this.store.dispatch(new customerActions.Load(params));
        });

        this.getCustomerAndContacts$().subscribe(results => {
            this.items = results.map(item => {
                return Object.assign({}, item, { text: this.searchFormat(item) });
            });
            this.cdRef.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    unsubscribe() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    searchCallback(search: string, item: INgxSelectOption): boolean {
        let data = <{customer: Customer; data: CustomerContacts}>item.data;
        return this.callback(data, search) === false ? false : this.searchIn(data).includes(search.toLowerCase());
    }

    searchFormat(val: {customer: Customer; data: CustomerContacts}): string {
        let output = '';
        if (val.customer && val.customer.number) {
            output = output + val.customer.number + ' ';
        }
        if (val.customer && val.customer.name) {
            output = output + val.customer.name + ' ';
        }
        if (val.customer && val.customer.email) {
            output = output + val.customer.email + ' ';
        }
        output = output.trim();

        return output;
    }

    searchIn(val: {customer: Customer; data: CustomerContacts}): string {
        let output = '';
        if (val.customer && val.customer.number) {
            output = output + val.customer.number + ' ';
        }
        if (val.customer && val.customer.name) {
            output = output + val.customer.name + ' ';
        }
        if (val.customer && val.customer.email) {
            output = output + val.customer.email + ' ';
        }
        output = output.trim().toLowerCase();

        return output;
    }

    selectionChanges($event: INgxSelectOption[]) {
        if(!isEmpty($event)) {
            this.customerChanges.next(<Customer>$event[0].data.customer)
        } else {
            this.customerChanges.next(null);
            this.items = [];
        }
    }

    private getParams(search: string): ConstructorParameters<typeof customerActions.Load>[0] {
        return {
            search: {
                strict: false,
                method: 'OR',
                name: search,
                email: search,
                number: search
            },
            page: 1,
            size: 20,
        };
    }

    private searchChanges$() {
        return this.typed.pipe(
            takeUntil(this.ngUnsubscribe),
            map(e => e.trim()),
            filter(e => !isEmpty(e) && e.length > 1),
            debounceTime(300),
        )
    }

    private customer$(): Observable<Customer[]> {
        return this.searchChanges$().pipe(
            switchMap(search => combineLatest([
                this.store.pipe(select(customerSelectors.findCustomersBy('name', search)), startWith([] as Customer[])),
                this.store.pipe(select(customerSelectors.findCustomersBy('email', search)), startWith([] as Customer[])),
                this.store.pipe(select(customerSelectors.findCustomersBy('number', search)), startWith([] as Customer[])),
            ])),
            takeUntil(this.ngUnsubscribe),
            filter(e => Array.isArray(e)),
            map(([us1, us2, us3]) => uniqBy([...us1, ...us2, ...us3], 'id')),
        );
    }

    private getCustomers$() {
        if (this.withoutDisabled) {
            return this.customer$().pipe(
                map(customers => customers),
                map(e => e.filter(e => e.status != 'disabled'))
            )
        } else {
            return this.customer$().pipe(
                map(customers => customers)
            )
        }
    }

    private getCustomerAndContacts$(): Observable<{customer: Customer, data: CustomerContacts}[]> {
        return this.getCustomers$().pipe(
            switchMap(customers => {
                let ids = customers.map(item => item.id);
                return this.store.pipe(
                    select(customerContactsSelectors.getCustomersByCustomerIds(ids)),
                    map((customerContacts: CustomerContacts[]): {customer: Customer, data: CustomerContacts}[] => {
                        return customers.map(customer => {
                            return {
                                customer: customer,
                                data: customerContacts.find(e => e.customer_id == customer.id) || <CustomerContacts>{}
                            };
                        });
                    })
                );
            }),
            takeUntil(this.ngUnsubscribe),
        );
    }

    public itemsClear() {
        if (this.autoClear) this.items = [];
    }
}
