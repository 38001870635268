import { Injectable } from '@angular/core';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { isEmpty, pickBy } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import {
    CustomerContacts,
    ICustomerContactsRequestUpdate,
    ICustomerContactsRequestStore
} from '../../models';
import { User } from '@appRoot/core/user/models';
import { CustomerContactsHttpService } from '../../services/customer-contacts-http.service';
import { UserService } from '@appRoot/core/user/services/user.service';
import * as customerContactsStorageActions from '../actions/customer-contacts-storage.actions';
import * as customerContactsActions from '../actions/customer-contacts.actions';
import * as customerStorageActions from "@appRoot/core/customer/ngrx-store/actions/customer-storage.actions";


@Injectable()
export class CustomerContactsEffects {

    constructor(
        private actions$: Actions,
        private httpService: CustomerContactsHttpService,
        private userService: UserService,
        private store: Store<any>,
    ) {}

    @Effect()
    load$: Observable<Action> = this.actions$.pipe(
        ofType(customerContactsActions.ActionTypes.LOAD),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerContactsActions.Load, User]) => !!user),
        switchMap(([action, user]) => this.httpService
                .fetch({...action.payload, uid: user.id}).pipe(
                switchMap(response => {
                    let customer_contacts: CustomerContacts[] = [];

                    response.data.forEach(contact => {
                        customer_contacts.push(new CustomerContacts(contact));
                    });

                    return [
                        new customerContactsStorageActions.UpsertMany(customer_contacts),
                        new customerContactsActions.LoadSuccess({
                            ...response,
                            data: customer_contacts
                        }, action.selector),
                    ];
                }),
                catchError(error => [
                    new customerContactsActions.Error(new NGRXError(action, error)),
                    new customerContactsActions.LoadFailed,
                ]),
            )
        )
    );

    @Effect()
    getById$: Observable<Action> = this.actions$.pipe(
        ofType(customerContactsActions.ActionTypes.GET_BY_ID),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerContactsActions.GetById, User]) => !!user),
        switchMap(([action, user]) =>
            this.httpService.show({
                uid: user.id,
                customer_id: action.payload.customer_contact.customer_id,
                contact_id: action.payload.customer_contact.id
            }).pipe(
                switchMap((response) => {
                    let contact = new CustomerContacts(response);

                    return [
                        new customerContactsStorageActions.UpsertOne(contact),
                        new customerContactsActions.GetByIdSuccess({ customer_contact: contact }),
                    ];
                }),
                catchError(error => [
                    new customerContactsActions.Error(new NGRXError(action, error)),
                    new customerContactsActions.GetByIdFailed,
                ])
            )
        )
    );

    @Effect()
    create$: Observable<Action> = this.actions$.pipe(
        ofType(customerContactsActions.ActionTypes.CREATE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerContactsActions.Create, User]) => !!user),
        switchMap(([action, user]) => {
            let request: ICustomerContactsRequestStore = {
                uid: user.id,
                customer_id: action.payload.customer_contact.customer_id
            };
            if (action.payload.customer_contact.name) request.name = action.payload.customer_contact.name;
            if (action.payload.customer_contact.contact_email) request.contact_email = action.payload.customer_contact.contact_email;
            if (action.payload.customer_contact.phone) request.phone = action.payload.customer_contact.phone;
            if (action.payload.customer_contact.city) request.city = action.payload.customer_contact.city;
            if (action.payload.customer_contact.address) request.address = action.payload.customer_contact.address;
            if (action.payload.customer_contact.postal_code) request.postal_code = action.payload.customer_contact.postal_code;
            if (action.payload.customer_contact.note) request.note = action.payload.customer_contact.note;
            if (action.payload.customer_contact.billing) request.billing = action.payload.customer_contact.billing;
            if (action.payload.customer_contact.purchaser) request.purchaser = action.payload.customer_contact.purchaser;
            if (action.payload.customer_contact.primary) request.primary = action.payload.customer_contact.primary;

            return this.httpService.store(request).pipe(
                switchMap((response) => {
                    let new_contact = new CustomerContacts(response);
                    let create = [];

                    create.push(new customerContactsStorageActions.AddOne(new_contact));
                    create.push(new customerContactsActions.CreateSuccess({customer_contact: new_contact}));
                    create.push(new customerContactsActions.Reload());
                    if (new_contact.primary) {
                        create.push(new customerStorageActions.UpdateEmail({
                            id: new_contact.customer_id,
                            email: new_contact.contact_email
                        }));
                    }

                    return create;
                }),
                catchError(error => [
                    new customerContactsActions.Error(new NGRXError(action, error)),
                    new customerContactsActions.CreateFailed,
                ])
            )
        }),
    );

    @Effect()
    update$: Observable<Action> = this.actions$.pipe(
        ofType(customerContactsActions.ActionTypes.UPDATE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerContactsActions.Update, User]) => !!user),
        switchMap(([action, user]) => {
            let data: ICustomerContactsRequestUpdate = {};
            if (action instanceof customerContactsActions.Update) {
                data.name = (action.payload.customer_contact.name !== action.payload.changes.customer_contact.name)
                    ? action.payload.changes.customer_contact.name
                    : undefined;
                data.contact_email = (action.payload.customer_contact.contact_email !== action.payload.changes.customer_contact.contact_email)
                    ? action.payload.changes.customer_contact.contact_email
                    : undefined;
                data.phone = (action.payload.customer_contact.phone !== action.payload.changes.customer_contact.phone)
                    ? action.payload.changes.customer_contact.phone
                    : undefined;
                data.city = (action.payload.customer_contact.city !== action.payload.changes.customer_contact.city)
                    ? action.payload.changes.customer_contact.city
                    : undefined;
                data.address = (action.payload.customer_contact.address !== action.payload.changes.customer_contact.address)
                    ? action.payload.changes.customer_contact.address
                    : undefined;
                data.postal_code = (action.payload.customer_contact.postal_code !== action.payload.changes.customer_contact.postal_code)
                    ? action.payload.changes.customer_contact.postal_code
                    : undefined;
                data.note = (action.payload.customer_contact.note !== action.payload.changes.customer_contact.note)
                    ? action.payload.changes.customer_contact.note
                    : undefined;
                data.billing = (action.payload.customer_contact.billing !== action.payload.changes.customer_contact.billing)
                    ? action.payload.changes.customer_contact.billing
                    : undefined;
                data.purchaser = (action.payload.customer_contact.purchaser !== action.payload.changes.customer_contact.purchaser)
                    ? action.payload.changes.customer_contact.purchaser
                    : undefined;
                data.primary = (action.payload.customer_contact.primary !== action.payload.changes.customer_contact.primary)
                    ? action.payload.changes.customer_contact.primary
                    : undefined;
            }

            const cleanedData = pickBy(data, v => v !== undefined);
            if (isEmpty(cleanedData)) {
                return [new customerContactsActions.UpdateFailed];
            }

            return this.httpService.update({
                uid: user.id,
                customer_id: action.payload.customer_contact.customer_id,
                contact_id: action.payload.customer_contact.id
            }, cleanedData).pipe(
                switchMap((response) => {
                    let customer_contacts: CustomerContacts[] = [];
                    let customer_email: {id: number, email: string} = {id: null, email: null};

                    response.forEach(contact => {
                        customer_contacts.push(new CustomerContacts(contact));
                        if (contact.primary) {
                            customer_email.id = contact.customer_id;
                            customer_email.email = contact.contact_email;
                        }
                    });

                    return [
                        new customerContactsStorageActions.UpsertMany(customer_contacts),
                        new customerContactsActions.UpdateSuccess(customer_contacts),
                        new customerStorageActions.UpdateEmail(customer_email)
                    ];
                }),
                catchError(error => [
                    new customerContactsActions.Error(new NGRXError(action, error)),
                    new customerContactsActions.UpdateFailed,
                ])
            );
        }),
    );

    @Effect()
    delete$: Observable<Action> = this.actions$.pipe(
        ofType(customerContactsActions.ActionTypes.DELETE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerContactsActions.Delete, User]) => !!user),
        mergeMap(([action, user]) => {
            return this.httpService.destroy( action.payload.customer_contact.id,{
                uid: user.id,
                customer_id: action.payload.customer_contact.customer_id
            }).pipe(
                switchMap(response => {
                    return [
                        new customerContactsStorageActions.RemoveOne(action.payload.customer_contact),
                        new customerContactsActions.DeleteSuccess,
                        new customerContactsActions.Reload()
                    ];
                }),
                catchError(error => [
                    new customerContactsActions.Error(new NGRXError(action, error)),
                    new customerContactsActions.DeleteFailed
                ]),
            );
        }),
    );
}