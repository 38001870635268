import { Injectable } from '@angular/core';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { User } from '@appRoot/core/user/models';
import { UserService } from '@appRoot/core/user/services/user.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AvailableData } from '../../models';
import { AvailableDataHttpService } from '../../services/available-data-http.service';
import * as availableDataActions from '../actions/available-data.actions';
import * as availableDataSelectors from '../selectors/available-data.selectors';


@Injectable()
export class AvailableDataEffects {

    constructor(
        private actions$: Actions,
        private httpService: AvailableDataHttpService,
        private userService: UserService,
        private store: Store<any>,
    ) {}

    @Effect()
    load$: Observable<Action> = this.actions$.pipe(
        ofType(availableDataActions.ActionTypes.LOAD),
        withLatestFrom(
            this.userService.activeUser$,
            this.store.select(availableDataSelectors.getLoaded),
            this.store.select(availableDataSelectors.getData)
        ),
        filter(([action, user, loaded, data]: [availableDataActions.Load, User, boolean, AvailableData]) => !!user),
        mergeMap(([action, user, loaded, data]) => {
            if (loaded) {
                return [
                    new availableDataActions.LoadSuccess(data),
                ];
            }

            return this.httpService.fetch({ uid: user.id }).pipe(
                switchMap(response => {
                    return [
                        new availableDataActions.LoadSuccess(response),
                    ];
                }),
                catchError(error => [
                    new availableDataActions.Error(new NGRXError(action, error)),
                    new availableDataActions.LoadFailed
                ]),
            );
        })
    );
}
