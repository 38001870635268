import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { BasicForm } from '@appRoot/core/forms/form-basic.form';
import { ActionsSubject, select, Store } from "@ngrx/store";
import * as selfSignupActions from "../../../ngrx-store/actions/self-signup.actions";
import * as selfSignupAccountsActions from "../../../ngrx-store/actions/self-signup-accounts.actions";
import { fadeInAnimation } from '../../../_animations';
import { ValidatorService } from "@appRoot/core/services/validator.service";


@Component({
    selector: 'app-accounts-form',
    templateUrl: './accounts-form.component.html',
    styleUrls: ['./accounts-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [ fadeInAnimation ],
    host: {'[@fadeInAnimation]': ''}
})
export class AccountsFormComponent extends BasicForm implements OnInit {

    public validationMessages: Map<AbstractControl, {[key:string]: string}>;

    constructor(
        private store: Store<any>,
        private cdr: ChangeDetectorRef,
        private actionsSubject: ActionsSubject,
        private validatorService: ValidatorService
    ) {
        super();
    }

    get domainCtrl() { return this.form.get('domain') as FormControl; }

    ngOnInit() {
        super.ngOnInit();
        this.createValidationMessages();
    }

    createForm() {
        let domain = new FormControl('', [Validators.required, ValidatorService.domain]);

        this.form = new FormGroup({
            domain: domain
        });

        this.domainCtrl.setAsyncValidators(this.validatorService.domainSelfSignupProdCheck(3));
    }

    onSubmit() {
        super.onSubmit();

        if (this.form.valid) {
            this.loading = true;

            this.store.dispatch(new selfSignupActions.StepRegister());
            this.store.dispatch(new selfSignupAccountsActions.SetDomain(this.domainCtrl.value));
        }
    }

    private createValidationMessages(){
        this.validationMessages = new Map([
            [this.domainCtrl, {
                'required': 'Domain is required.',
                'domain-valid': 'You cannot use this domain.',
                'domain': 'The entered domain is not valid.'
            }]
        ]);
    }
}
