import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SettingsService } from '@appRoot/core/services/settings.service';
import { Subject } from 'rxjs';
import { fadeInAnimation } from "@appRoot/lazy-modules/self-signup/_animations";
import { skip, takeUntil } from "rxjs/operators";
import { ofType } from "@ngrx/effects";
import * as selfSignupActions from "@appRoot/lazy-modules/self-signup/ngrx-store/actions/self-signup.actions";
import * as customerActions from "@appRoot/core/customer/ngrx-store/actions/customer.actions";
import { ActionsSubject, select, Store } from "@ngrx/store";
import * as selfSignupSelectors from "@appRoot/lazy-modules/self-signup/ngrx-store/selectors/self-signup.selectors";
import { IUserCustomersResponse } from "@appRoot/lazy-modules/self-signup/models";
import * as selfSignupAccountsActions from "@appRoot/lazy-modules/self-signup/ngrx-store/actions/self-signup-accounts.actions";


@Component({
    selector: 'app-step-register-container',
    templateUrl: './step-register-container.component.html',
    styleUrls: ['./step-register-container.component.scss'],
    animations: [ fadeInAnimation ],
    host: {'[@fadeInAnimation]': ''}
})
export class StepRegisterContainer implements OnInit {

    readonly ngUnsubscribe = new Subject();

    public registerAction: boolean = true;
    public userCustomers: IUserCustomersResponse[] = [];

    public userCustomersFlag = false;
    public userNewCustomerFlag = false;

    constructor(
        public settings: SettingsService,
        private store: Store<any>,
        private actionsSubject: ActionsSubject,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.actionsSubject.pipe(
            skip(1),
            ofType(selfSignupActions.ActionTypes.GET_USER_CUSTOMERS_SUCCESS),
            takeUntil(this.ngUnsubscribe),
        ).subscribe((action: customerActions.UpdateSuccess) => {
            this.store.pipe(
                select(selfSignupSelectors.getUserCustomers)
            ).subscribe(e => {
                if (e.length === 0) {
                    this.switchRegister();
                    this.userNewCustomerFlag = true;
                } else if (e.length === 1) {
                    this.dispatchCustomer(e[0]);
                    if (e[0].paymentMode) {
                        this.store.dispatch(new selfSignupActions.StepPayment());
                    } else {
                        this.store.dispatch(new selfSignupActions.StepCompletion());
                    }
                } else {
                    this.userCustomersFlag = true;
                    this.userCustomers = e;
                }

                this.cdr.detectChanges();
            });
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    unsubscribe() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public switchRegister() {
        this.registerAction = !this.registerAction;
    }

    public chooseCustomer(event, customer: IUserCustomersResponse) {
        event.preventDefault();
        this.dispatchCustomer(customer);
        if (customer.paymentMode) {
            this.store.dispatch(new selfSignupActions.StepPayment());
        } else {
            this.store.dispatch(new selfSignupActions.StepCompletion());
        }
    }

    private dispatchCustomer(customer: IUserCustomersResponse) {
        this.store.dispatch(new selfSignupAccountsActions.SetCustomerNumber(customer.number));
        this.store.dispatch(new selfSignupAccountsActions.SetCustomerId(customer.id));
    }
}