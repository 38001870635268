import { Component, OnDestroy } from '@angular/core';
import { ErrorCatcherMessageService } from './error-catcher-message.service';
import { ListMessages } from "../error-catcher.types";
import { Subscription } from "rxjs";

@Component({
    selector: 'error-catcher-message',
    templateUrl: './error-catcher-message.component.html',
    styleUrls: ['./error-catcher-message.component.scss'],
})
export class ErrorCatcherMessageComponent implements OnDestroy {

  public show: boolean;
  public message: string;
  public messages: ListMessages;
  public type: string;
  public messageSubscription$: Subscription;

  constructor(private messageService: ErrorCatcherMessageService){

      this.messageSubscription$ = this.messageService.isShows().subscribe({
      next: (e) => {
        this.show = e;
        this.type = this.messageService.type;
        if(e){
          this.message = this.messageService.message;
          this.messages = this.messageService.messages;
        } else {
          this.message = null;
          this.messages = null;
        }        
      },
      error: (e) => {
        this.show = false;
      },
    });

  }

  ngOnDestroy(){
    this.messageSubscription$.unsubscribe()
  }

}
