export class MailTemplate {
    id: number = null;
    mailable: string = null;
    subject: string = null;
    htmlTemplate: string = null;
    textTemplate: string = null;
    created: Date = null;
    updated: Date = null;
    variables: string[] = [];
    initial_variables: Object;

    constructor(response?: IMailTemplateResponse){
        if(response){
            this.id = response.id;
            this.mailable = response.mailable;
            this.subject = response.subject;
            this.htmlTemplate = response.html_template;
            this.textTemplate = response.text_template;
            this.created = response.created_at? new Date(response.created_at*1000): null;
            this.updated = response.updated_at? new Date(response.updated_at*1000): null;
            this.variables = response.variables;
            this.initial_variables = response.initial_variables;
        }
    }
}

export interface IMailTemplateResponse {
    id: number;
    mailable: string;
    subject: string;
    html_template: string;
    text_template: string;
    created_at: number;
    updated_at: number;
    variables: string[];
    initial_variables: Object;
}
