import { Action } from '@ngrx/store';
import { IMetadataResponse } from '../../models';


export enum ActionTypes {
    ADD_METADATA = '[whmMetadata] ADD_METADATA',
}

export class AddMetadata implements Action {
    readonly type = ActionTypes.ADD_METADATA;
    constructor(readonly payload: IMetadataResponse) {}
}
export type Actions = AddMetadata;
