import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPaginatedResponse } from '@appRoot/core/interfaces';
import { SettingsService } from '@appRoot/core/services/settings.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    IPermissionGroup,
    IPermissionResponse,
    IPermissionsGroupRequestDestroyParams,
    IPermissionsGroupRequestIndexParams,
    IPermissionsGroupRequestShowParams,
    IPermissionsGroupRequestStoreParams,
    IPermissionsGroupRequestUpdateParams,
    IPermissionRequestIndexParams,
    IPermissionRequestShowParams,
    IPermissionRequestUpdateParams,
    Permission
} from '../models';


@Injectable()
export class PermissionHttpService {

    public readonly edge: string = 'permissions';
    public readonly edgeForGroups: string = 'permission-groups';

    constructor(
        private http: HttpClient,
        private settings: SettingsService,
    ) { }

    fetch(
        {uid, sort, order, page, size, search, ids}: IPermissionRequestIndexParams): Observable<Permission[]> {
        let params = new HttpParams();
        params = params.append('uid', '' + uid);
        params = page ? params.append('page[number]', '' + page) : params;
        params = size ? params.append('page[size]', '' + size) : params;
        params = search ? params.append('search', search) : params;
        params = sort ? params.append('sort', sort) : params;
        params = order ? params.append('order', order) : params;
        params = ids ? params.append('ids', ids.join(',')) : params;

        return this.http.get<{ data: IPaginatedResponse<IPermissionResponse[]> }>(
            `${this.settings.API_PATH}/${this.edge}`, {params: params})
        .pipe(
            map(response => {
                return response.data.data.map(e => new Permission(e));
            })
        );
    }

    show(id: number, requestData: IPermissionRequestShowParams): Observable<Permission> {
        let params = new HttpParams({fromObject: <any>{...requestData}});

        return this.http.get<{ data: IPermissionResponse }>(`${this.settings.API_PATH}/${this.edge}/${id}`,
            {params: params}
        )
        .pipe(
            map(response => new Permission(response.data))
        );
    }

    update(id: number, requestData: IPermissionRequestUpdateParams): Observable<Permission> {
        return this.http.patch<{ data: IPermissionResponse }>(`${this.settings.API_PATH}/${this.edge}/${id}`,
            requestData
        )
        .pipe(
            map(response => new Permission(response.data)),
        );
    }

    fetchGroup(requestData: IPermissionsGroupRequestIndexParams): Observable<IPermissionGroup[]> {
        let params = new HttpParams({fromObject: <any>{...requestData}});
        return this.http.get<{ data: IPermissionGroup[] }>(`${this.settings.API_PATH}/${this.edgeForGroups}`,
            {params: params}
        )
        .pipe(
            map(response => response.data)
        );
    }

    showGroup(id: number, requestData: IPermissionsGroupRequestShowParams): Observable<IPermissionGroup> {
        let params = new HttpParams({fromObject: <any>{...requestData}});
        return this.http.get<{ data: IPermissionGroup }>(`${this.settings.API_PATH}/${this.edgeForGroups}/${id}`,
            {params: params}
        )
        .pipe(
            map(response => response.data)
        );
    }

    storeGroup(requestData: IPermissionsGroupRequestStoreParams): Observable<IPermissionGroup> {
        return this.http.post<{ data: IPermissionGroup }>(`${this.settings.API_PATH}/${this.edgeForGroups}`,
            requestData
        )
        .pipe(
            map(response => response.data)
        );
    }

    updateGroup(id: number, requestData: IPermissionsGroupRequestUpdateParams): Observable<IPermissionGroup> {
        return this.http.patch<{ data: IPermissionGroup }>(`${this.settings.API_PATH}/${this.edgeForGroups}/${id}`,
            requestData
        )
        .pipe(
            map(response => response.data)
        );
    }

    destroyGroup(ids: number | number[], requestData: IPermissionsGroupRequestDestroyParams): Observable<number> {
        let passIds = Array.isArray(ids) ? ids.join(',') : ids;
        let params = new HttpParams({fromObject: <any>{...requestData}});
        return this.http.delete<{ data: number }>(`${this.settings.API_PATH}/${this.edgeForGroups}/${passIds}`,
            {params: params}
        )
        .pipe(
            map(response => response.data)
        );
    }

}
