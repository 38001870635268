import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Actions, ActionTypes } from '../actions/authentication.actions';

export interface State {
	loading: boolean;
	isAuthenticated: boolean;
	authComplete: boolean;
	refresh: boolean | null;
	error: NGRXError<Actions>
}

export const initialState: State = {
	loading: false,
	isAuthenticated: false,
	authComplete: false,
	refresh: null,
	error: null,
};

export function reducer(state = initialState, action: Actions): State {
	switch (action.type) {
		case ActionTypes.LOGIN:
		case ActionTypes.ATTEMPT:
		case ActionTypes.LOGOUT: 
			return {
				...state,
				loading: true,
				authComplete: false,
			};

		case ActionTypes.LOGIN_SUCCESS:
		case ActionTypes.LOGIN_FAILED:
		case ActionTypes.LOGOUT_SUCCESS:
		case ActionTypes.ATTEMPT_COMPLETE:
			return {
				...state,
				loading: false,
			};

		case ActionTypes.AUTHENTICATE:
			return {
				...state,
				authComplete: true,
				isAuthenticated: true,
			};

		case ActionTypes.UNAUTHENTICATE: 
			return {
				...state,
				authComplete: true,
				isAuthenticated: false,
			};

		case ActionTypes.REFRESH_TOKEN: 
			return {
				...state,
				loading: true,
				authComplete: false,
				refresh: null,
			};
		case ActionTypes.REFRESH_TOKEN_COMPLETE: 
			return {
				...state,
				loading: false,
				authComplete: true,
				refresh: action.payload,
			};

		case ActionTypes.ERROR:
			return {
				...state,
				loading: false,
				error: new NGRXError(action.payload.action, action.payload.error),
			};

		default:
			return state;
	}
}