<!-- START sidebar nav-->
<ul *ngIf="availableData" class="sidebar-nav" [ngClass]="{'sidebar-nav': true, 'sidebar-subnav': (level !== 0)}" [@openClose]="openClose ? 'open' : 'close'">
    <li *ngFor='let item of navItems; let index = index' [ngClass]="{'nav-heading': item.heading}" [routerLinkActive]="['active']" >

        <ng-container *ngIf="item.permissions; else contentTemplate">
            <ng-template [ngxPermissionsOnly]="item.permissions">
                <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
            </ng-template>
        </ng-container>

        <ng-template #contentTemplate>
            <!-- menu heading -->
            <span *ngIf="item.heading">{{(item.translate | translate) || item.text}}</span>
            <!-- external links -->
            <a href *ngIf="!item.heading && !item.submenu && item.elink" [attr.target]="item.target" [attr.href]="item.elink" title="{{item.text}}">
                <span class="float-right" *ngIf="item.alert" [ngClass]="item.label || 'badge badge-success'">{{item.alert}}</span>
                <em><fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon></em>
                <span>{{(item.translate | translate) || item.text}}</span>
            </a>

            <ng-container [ngSwitch]="item.route_name">
                <!-- Customer link -->
                <ng-container *ngSwitchCase="customerLinkName">
                    <ng-container *ngIf="availableData.customer_show">
                        <!-- single menu item -->
                        <ng-template [ngIf]="(level === 0)" [ngIfElse]="subItem">
                            <a href
                               *ngIf="!item.heading && !item.submenu && !item.elink"
                               [routerLink]="singleCustomer(userIdReplace(item.link))"
                               [attr.route]="singleCustomer(userIdReplace(item.link))"
                               title="{{item.text}}"
                               (click)="toggleSubmenuClick($event, index, level)"
                               (mouseenter)="toggleSubmenuHover($event, index, level)">
                                    <span class="float-right" *ngIf="item.alert" [ngClass]="item.label || 'badge badge-success'">{{item.alert}}</span>
                                    <em><fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon></em>
                                    <span>{{(item.translate | translate) || item.text}}</span>
                            </a>
                        </ng-template>
                    </ng-container>
                </ng-container>

                <!-- Shared link -->
                <ng-container *ngSwitchCase="shareLinkName">
                    <ng-container *ngIf="availableData.shared_show">
                        <!-- single menu item -->
                        <ng-template [ngIf]="(level === 0)" [ngIfElse]="subItem">
                            <a href
                               *ngIf="!item.heading && !item.submenu && !item.elink"
                               [routerLink]="userIdReplace(item.link)"
                               [attr.route]="userIdReplace(item.link)"
                               title="{{item.text}}"
                               (click)="toggleSubmenuClick($event, index, level)"
                               (mouseenter)="toggleSubmenuHover($event, index, level)">
                                    <span class="float-right" *ngIf="item.alert" [ngClass]="item.label || 'badge badge-success'">{{item.alert}}</span>
                                    <em><fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon></em>
                                    <span>{{(item.translate | translate) || item.text}}</span>
                            </a>
                        </ng-template>
                    </ng-container>
                </ng-container>

                <!-- Other link -->
                <ng-container *ngSwitchDefault>
                    <!-- single menu item -->
                    <ng-template [ngIf]="(level === 0)" [ngIfElse]="subItem">
                        <a href
                           *ngIf="!item.heading && !item.submenu && !item.elink"
                           [routerLink]="userIdReplace(item.link)"
                           [attr.route]="userIdReplace(item.link)"
                           title="{{item.text}}"
                           (click)="toggleSubmenuClick($event, index, level)"
                           (mouseenter)="toggleSubmenuHover($event, index, level)">
                                <span class="float-right" *ngIf="item.alert" [ngClass]="item.label || 'badge badge-success'">{{item.alert}}</span>
                                <em><fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon></em>
                                <span>{{(item.translate | translate) || item.text}}</span>
                        </a>
                    </ng-template>
                </ng-container>
            </ng-container>

            <ng-template #subItem>
                <a href *ngIf="!item.heading && !item.submenu && !item.elink" [routerLink]="item.link" [attr.route]="item.link" title="{{item.text}}">
                    <span class="float-right" *ngIf="item.alert" [ngClass]="item.label || 'badge badge-success'">{{item.alert}}</span>
                    <em><fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon></em>
                    <span>{{(item.translate | translate) || item.text}}</span>
                </a>
            </ng-template>

            <!-- has submenu -->
            <a href *ngIf="!item.heading && item.submenu" title="{{item.text}}"
               (click)="toggleSubmenuClick($event, index, level)" (mouseenter)="toggleSubmenuHover($event, index, level)">
                <span class="float-right" *ngIf="item.alert" [ngClass]="item.label || 'badge badge-success'">{{item.alert}}</span>
                <em><fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon></em>
                <span>{{(item.translate | translate) || item.text}}</span>
            </a>

            <app-nav-item *ngIf="item.submenu" [navItems]="item.submenu" [level]="(level+1)" [openClose]="openClose" [currentState]="currentState" [index]="index"></app-nav-item>
        </ng-template>

    </li>
</ul>
<!-- END sidebar nav-->
