import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { environment } from 'src/environments/environment';
import { effects } from './effects';
import { metaReducers, reducers } from './reducers';
import { CustomRouterStateSerializer } from './router';
import { clearState } from "@appRoot/core/ngrx-store/reducers/clear-state.reducer";


@NgModule({
    imports: [
        StoreModule.forRoot(reducers, { metaReducers: [clearState], runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true } }),
        EffectsModule.forRoot(effects),
        !environment.production? StoreDevtoolsModule.instrument({ maxAge: 50 }): [],
        StoreRouterConnectingModule.forRoot({ serializer: CustomRouterStateSerializer }),
    ]
})
export class NGRXStoreModule {}
