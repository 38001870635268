import { ICustomerGroupsChangeNotificationMainResponse } from '@appRoot/core/customer/models/ICustomerGroupsChangeNotificationMainRequestParams';


export class CustomerGroupsChangeNotificationMain {
	id: number;
	group_id: number | null;
	group_name: number;
	subject: string;
	to_email: string | null;
	cc_emails: string | null;
	html_template: string;
	text_template: string;
	created_at: number;
	updated_at: number;

	constructor(data?: ICustomerGroupsChangeNotificationMainResponse) {
		if (data) {
            this.id = data.id;
            this.group_id = data.group_id;
            this.group_name = data.group_name;
            this.subject = data.subject;
            this.to_email = data.to_email;
            this.cc_emails = data.cc_emails;
            this.html_template = data.html_template;
            this.text_template = data.text_template;
            this.created_at = data.created_at;
            this.updated_at = data.updated_at;
		}
	}
}