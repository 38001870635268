import { IPaginatedResponse, Omit } from '@appRoot/core/interfaces';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Action } from '@ngrx/store';
import {
    ICustomerRequestIndexParams,
    Customer, CustomerContacts,
    CustomerCard,
    Invoice,
    CustomerExpense,
    ICustomerEmailLogsRequestResendParams
} from '../../models';
import { User } from "@appRoot/core/user/models";


export enum ActionTypes {
    ERROR = '[Customer] error',
    RESET = '[Customer] reset',

    GET_CURRENT = '[Customer] get current',
    GET_CURRENT_SUCCESS = '[Customer] get current success',
    GET_CURRENT_FAILED = '[Customer] get current failed',

    SET_ACTIVE_CUSTOMER = '[Customer] set active customer on Customer pages',

    LOAD = '[Customer] load',
    LOAD_SUCCESS = '[Customer] load success',
    LOAD_FAILED = '[Customer] load failed',

    PAGINATION_SET = '[Customer] pagination set',

    CREATE = '[Customer] create',
    CREATE_SUCCESS = '[Customer] create success',
    CREATE_FAILED = '[Customer] create failed',

    GET_BY_ID = '[Customer] get',
    GET_BY_ID_SUCCESS = '[Customer] get success',
    GET_BY_ID_FAILED = '[Customer] get failed',

    UPDATE = '[Customer] update',
    UPDATE_SUCCESS = '[Customer] update success',
    UPDATE_FAILED = '[Customer] update failed',

    DELETE = '[Customer] delete',
    DELETE_SUCCESS = '[Customer] delete success',
    DELETE_FAILED = '[Customer] delete failed',

    DELETE_SELF = '[Customer] delete_self',
    DELETE_SELF_SUCCESS = '[Customer] delete_self success',
    DELETE_SELF_FAILED = '[Customer] delete_self failed',

    RESTORE = '[Customer] restore',
    RESTORE_SUCCESS = '[Customer] restore success',
    RESTORE_FAILED = '[Customer] restore failed',

    RELOAD = '[Customer] reload',

    GET_BY_NAME_OR_EMAIL = '[Customer] get by name or email',
    GET_BY_NAME_EMAIL_CUSTOMER_NAME = '[Customer] get by name or email or customer name',

    ADD_USER_TO_CUSTOMER = '[Customer] attach User to Customer',
    ADD_USER_TO_CUSTOMER_SUCCESS = '[Customer] attach User to Customer success',
    ADD_USER_TO_CUSTOMER_FAILED = '[Customer] attach User to Customer failed',

    REMOVE_USER_FROM_CUSTOMER = '[Customer] remove User from Customer',
    REMOVE_USER_FROM_CUSTOMER_SUCCESS = '[Customer] remove User from Customer success',
    REMOVE_USER_FROM_CUSTOMER_FAILED  = '[Customer] remove User from Customer failed',

    CHANGE_USER_CUSTOMER_ROLE = '[Customer] change User role on Customer',
    CHANGE_USER_CUSTOMER_ROLE_SUCCESS = '[Customer] change User role on Customer success',
    CHANGE_USER_CUSTOMER_ROLE_FAILED  = '[Customer] change User role on Customer failed',

    RESEND_NEW_USER_MAIL = '[Customer] resend new User mail',
    RESEND_NEW_USER_MAIL_SUCCESS = '[Customer] resend new User mail success',
    RESEND_NEW_USER_MAIL_FAILED = '[Customer] resend new User mail failed',

    IMPORT_FROM_DK = '[Customer] import_DK',
    IMPORT_FROM_DK_SUCCESS = '[Customer] import_DK success',
    IMPORT_FROM_DK_FAILED = '[Customer] import_DK failed',

    GET_SUBSCRIPTION = '[Customer] get subscription',
    GET_SUBSCRIPTION_SUCCESS = '[Customer] get subscription success',
    GET_SUBSCRIPTION_FAILED = '[Customer] get subscription failed',

    SUBSCRIPTION_SET_TAX = '[Customer] set subscription tax state',

    GET_INVOICE = '[Customer] get invoice',
    GET_INVOICE_SUCCESS = '[Customer] get invoice success',
    GET_INVOICE_FAILED = '[Customer] get invoice failed',

    GET_INVOICE_PDF = '[Customer] get invoice pdf',
    GET_INVOICE_PDF_SUCCESS = '[Customer] get invoice pdf success',
    GET_INVOICE_PDF_FAILED = '[Customer] get invoice pdf failed',

    GET_INVOICE_HTML = '[Customer] get invoice html',
    GET_INVOICE_HTML_SUCCESS = '[Customer] get invoice html success',
    GET_INVOICE_HTML_FAILED = '[Customer] get invoice html failed',

    INVOICE_EMAIL = '[Customer] send invoice in email',
    INVOICE_EMAIL_SUCCESS = '[Customer] send invoice in email success',
    INVOICE_EMAIL_FAILED = '[Customer] send invoice in email failed',

    GET_PAYMENT_MODE = '[Customer] get payment mode',
    GET_PAYMENT_MODE_SUCCESS = '[Customer] get payment mode success',
    GET_PAYMENT_MODE_FAILED = '[Customer] get payment mode failed',

    UPDATE_PAYMENT_MODE = '[Customer] update payment mode',
    UPDATE_PAYMENT_MODE_SUCCESS = '[Customer] update payment mode success',
    UPDATE_PAYMENT_MODE_FAILED = '[Customer] update payment mode failed',

    UPDATE_CREDIT_CARD = '[Customer] update credit card',
    UPDATE_CREDIT_CARD_SUCCESS = '[Customer] update credit card success',
    UPDATE_CREDIT_CARD_FAILED = '[Customer] update credit card failed',

    SEND_EMAIL = '[Customer] send email',
    SEND_EMAIL_SUCCESS = '[Customer] send email success',
    SEND_EMAIL_FAILED = '[Customer] send email failed',

    CREATE_EXPENSE = '[Customer] create Customer expense',
    CREATE_EXPENSE_SUCCESS = '[Customer] create Customer expense success',
    CREATE_EXPENSE_FAILED = '[Customer] create Customer expense failed',

    GET_EMAIL_LOGS = '[Customer] get email logs',
    GET_EMAIL_LOGS_SUCCESS = '[Customer] get email logs success',
    GET_EMAIL_LOGS_FAILED = '[Customer] get email logs failed',

    RESEND_EMAIL_LOGS = '[Customer] resend email logs',
    RESEND_EMAIL_LOGS_SUCCESS = '[Customer] resend email logs success',
    RESEND_EMAIL_LOGS_FAILED = '[Customer] resend email logs failed',

    GET_TIME_TRACKING = '[Customer] get time tracking',
    GET_TIME_TRACKING_SUCCESS = '[Customer] get time tracking success',
    GET_TIME_TRACKING_FAILED = '[Customer] get time tracking failed',
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;
    constructor(readonly payload: NGRXError<Actions>) {}
}

export class Reset implements Action {
    readonly type = ActionTypes.RESET;
}

export class GetCurrent implements Action {
    readonly type = ActionTypes.GET_CURRENT;
}
export class GetCurrentSuccess implements Action {
    readonly type = ActionTypes.GET_CURRENT_SUCCESS;
    constructor(readonly payload: {customer: Customer} ) { }
}
export class GetCurrentFailed implements Action {
    readonly type = ActionTypes.GET_CURRENT_FAILED;
}

export class SetActiveCustomer implements Action {
    readonly type = ActionTypes.SET_ACTIVE_CUSTOMER;
    constructor(readonly payload: {customer: Customer}) {}
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;
    constructor(readonly payload: Omit<ICustomerRequestIndexParams, 'uid'> = {'status': 'active'}, readonly selector: string = null) {}
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;
    constructor(readonly payload: IPaginatedResponse<Customer[]>, readonly selector: string = null) {}
}

export class LoadFailed implements Action {
    readonly type = ActionTypes.LOAD_FAILED;
}

export class PaginationSet implements Action {
    readonly type = ActionTypes.PAGINATION_SET;
    constructor(readonly payload: IPaginatedResponse<number[]>) { }
}

export class Create implements Action {
    readonly type = ActionTypes.CREATE;
    constructor(readonly payload: {customer: Customer; customerContact?: CustomerContacts[];}) { }
}
export class CreateSuccess implements Action {
    readonly type = ActionTypes.CREATE_SUCCESS;
    constructor(readonly payload: {customer: Customer}) { }
}
export class CreateFailed implements Action {
    readonly type = ActionTypes.CREATE_FAILED;
}

export class GetById implements Action {
    readonly type = ActionTypes.GET_BY_ID;
    constructor(readonly payload: number) { }
}
export class GetByIdSuccess implements Action {
    readonly type = ActionTypes.GET_BY_ID_SUCCESS;
    constructor(readonly payload: {customer: Customer}) { }
}
export class GetByIdFailed implements Action {
    readonly type = ActionTypes.GET_BY_ID_FAILED;
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;
    constructor(readonly payload: {
        customer: Customer;
        changes: {customer: Customer};
    }) { }
}
export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
    constructor(readonly payload: {customer: Customer}) { }
}
export class UpdateFailed implements Action {
    readonly type = ActionTypes.UPDATE_FAILED;
}

export class Delete implements Action {
    readonly type = ActionTypes.DELETE;
    constructor(readonly payload: {customers: Customer[]; force?: boolean}) {}
}
export class DeleteSuccess implements Action {
    readonly type = ActionTypes.DELETE_SUCCESS;
    constructor(readonly payload: {customers: Customer[]; force?: boolean}) { }
}
export class DeleteFailed implements Action {
    readonly type = ActionTypes.DELETE_FAILED;
}

export class DeleteSelf implements Action {
    readonly type = ActionTypes.DELETE_SELF;
}
export class DeleteSelfSuccess implements Action {
    readonly type = ActionTypes.DELETE_SELF_SUCCESS;
    constructor(readonly payload: {customers: Customer}) { }
}
export class DeleteSelfFailed implements Action {
    readonly type = ActionTypes.DELETE_SELF_FAILED;
}

export class Restore implements Action {
    readonly type = ActionTypes.RESTORE;
    constructor(readonly payload: {customers: Customer[]}) { }
}
export class RestoreSuccess implements Action {
    readonly type = ActionTypes.RESTORE_SUCCESS;
    constructor(readonly payload: {customers: Customer[]}) { }
}
export class RestoreFailed implements Action {
    readonly type = ActionTypes.RESTORE_FAILED;
}

export class Reload implements Action {
    readonly type = ActionTypes.RELOAD;
}

export class GetByNameOrEmail implements Action {
    readonly type = ActionTypes.GET_BY_NAME_OR_EMAIL;
    constructor(readonly payload: string) { }
}

export class GetByNameEmailCustomerName implements Action {
    readonly type = ActionTypes.GET_BY_NAME_EMAIL_CUSTOMER_NAME;
    constructor(readonly payload: string) { }
}

export class AddUserToCustomer implements Action {
    readonly type = ActionTypes.ADD_USER_TO_CUSTOMER;
    constructor(readonly payload: {customer: Customer; email: string; role_id: number}) { }
}
export class AddUserToCustomerSuccess implements Action {
    readonly type = ActionTypes.ADD_USER_TO_CUSTOMER_SUCCESS;
}
export class AddUserToCustomerFailed implements Action {
    readonly type = ActionTypes.ADD_USER_TO_CUSTOMER_FAILED;
}

export class RemoveUserFromCustomer implements Action {
    readonly type = ActionTypes.REMOVE_USER_FROM_CUSTOMER;
    constructor(readonly payload: {customer: Customer; user: User;}) { }
}
export class RemoveUserFromCustomerSuccess implements Action {
    readonly type = ActionTypes.REMOVE_USER_FROM_CUSTOMER_SUCCESS;
}
export class RemoveUserFromCustomerFailed implements Action {
    readonly type = ActionTypes.REMOVE_USER_FROM_CUSTOMER_FAILED;
}

export class ChangeUserCustomerRole implements Action {
    readonly type = ActionTypes.CHANGE_USER_CUSTOMER_ROLE;
    constructor(readonly payload: {customer: Customer; user: User; role_id: number}) { }
}
export class ChangeUserCustomerRoleSuccess implements Action {
    readonly type = ActionTypes.CHANGE_USER_CUSTOMER_ROLE_SUCCESS;
}
export class ChangeUserCustomerRoleFailed implements Action {
    readonly type = ActionTypes.CHANGE_USER_CUSTOMER_ROLE_FAILED;
}

export class ResendNewUserMail implements Action {
    readonly type = ActionTypes.RESEND_NEW_USER_MAIL;
    constructor(readonly payload: {customer: Customer; email: string;}) { }
}
export class ResendNewUserMailSuccess implements Action {
    readonly type = ActionTypes.RESEND_NEW_USER_MAIL_SUCCESS;
}
export class ResendNewUserMailFailed implements Action {
    readonly type = ActionTypes.RESEND_NEW_USER_MAIL_FAILED;
}

export class importFromDK implements Action {
    readonly type = ActionTypes.IMPORT_FROM_DK;
}
export class importFromDKSuccess implements Action {
    readonly type = ActionTypes.IMPORT_FROM_DK_SUCCESS;
    constructor(readonly payload: {data: string}) { }
}
export class importFromDKFailed implements Action {
    readonly type = ActionTypes.IMPORT_FROM_DK_FAILED;
    constructor(readonly payload: {data: string}) { }
}

export class GetSubscription implements Action {
    readonly type = ActionTypes.GET_SUBSCRIPTION;
    constructor(readonly payload: {number: string, customer_id: number}) {}
}
export class GetSubscriptionSuccess implements Action {
    readonly type = ActionTypes.GET_SUBSCRIPTION_SUCCESS;
}
export class GetSubscriptionFailed implements Action {
    readonly type = ActionTypes.GET_SUBSCRIPTION_FAILED;
    constructor(readonly payload: NGRXError<Actions>) { }
}

export class SubscriptionSetTax implements Action {
    readonly type = ActionTypes.SUBSCRIPTION_SET_TAX;
}

export class GetEmailLogs implements Action {
    readonly type = ActionTypes.GET_EMAIL_LOGS;
    constructor(readonly payload: {number: string, customer_id: number}) {}
}
export class GetEmailLogsSuccess implements Action {
    readonly type = ActionTypes.GET_EMAIL_LOGS_SUCCESS;
}
export class GetEmailLogsFailed implements Action {
    readonly type = ActionTypes.GET_EMAIL_LOGS_FAILED;
    constructor(readonly payload: NGRXError<Actions>) { }
}

export class ResendEmailLogs implements Action {
    readonly type = ActionTypes.RESEND_EMAIL_LOGS;
    constructor(readonly payload: Partial<ICustomerEmailLogsRequestResendParams> = {}) { }
}
export class ResendEmailLogsSuccess implements Action {
    readonly type = ActionTypes.RESEND_EMAIL_LOGS_SUCCESS;
}
export class ResendEmailLogsFailed implements Action {
    readonly type = ActionTypes.RESEND_EMAIL_LOGS_FAILED;
}

export class GetInvoice implements Action {
    readonly type = ActionTypes.GET_INVOICE;
    constructor(readonly payload: {number: string, customer_id: number}) {}
}
export class GetInvoiceSuccess implements Action {
    readonly type = ActionTypes.GET_INVOICE_SUCCESS;
}
export class GetInvoiceFailed implements Action {
    readonly type = ActionTypes.GET_INVOICE_FAILED;
}

export class GetInvoicePdf implements Action {
    readonly type = ActionTypes.GET_INVOICE_PDF;
    constructor(readonly payload: {invoice: Invoice, customer_id: number}) {}
}
export class GetInvoicePdfSuccess implements Action {
    readonly type = ActionTypes.GET_INVOICE_PDF_SUCCESS;
}
export class GetInvoicePdfFailed implements Action {
    readonly type = ActionTypes.GET_INVOICE_PDF_FAILED;
}

export class GetInvoiceHtml implements Action {
    readonly type = ActionTypes.GET_INVOICE_HTML;
    constructor(readonly payload: {invoice: Invoice, customer_id: number}) {}
}
export class GetInvoiceHtmlSuccess implements Action {
    readonly type = ActionTypes.GET_INVOICE_HTML_SUCCESS;
    constructor(readonly payload: { id: number, html: string }) { }
}
export class GetInvoiceHtmlFailed implements Action {
    readonly type = ActionTypes.GET_INVOICE_HTML_FAILED;
    constructor() {}
}

export class InvoiceEmail implements Action {
    readonly type = ActionTypes.INVOICE_EMAIL;
    constructor(readonly payload: {invoice: Invoice, customer_id: number, emails: string[]}) {}
}
export class InvoiceEmailSuccess implements Action {
    readonly type = ActionTypes.INVOICE_EMAIL_SUCCESS;
}
export class InvoiceEmailFailed implements Action {
    readonly type = ActionTypes.INVOICE_EMAIL_FAILED;
}

export class GetPaymentMode implements Action {
    readonly type = ActionTypes.GET_PAYMENT_MODE;
    constructor(readonly payload: {number: string, customer_id: number}) {}
}
export class GetPaymentModeSuccess implements Action {
    readonly type = ActionTypes.GET_PAYMENT_MODE_SUCCESS;
}
export class GetPaymentModeFailed implements Action {
    readonly type = ActionTypes.GET_PAYMENT_MODE_FAILED;
}

export class UpdatePaymentMode implements Action {
    readonly type = ActionTypes.UPDATE_PAYMENT_MODE;
    constructor(readonly payload: {
        customer: Customer;
        paymentMode: string;
        updateSubscription: boolean;
    }) { }
}
export class UpdatePaymentModeSuccess implements Action {
    readonly type = ActionTypes.UPDATE_PAYMENT_MODE_SUCCESS;
}
export class UpdatePaymentModeFailed implements Action {
    readonly type = ActionTypes.UPDATE_PAYMENT_MODE_FAILED;
}

export class UpdateCreditCard implements Action {
    readonly type = ActionTypes.UPDATE_CREDIT_CARD;
    constructor(readonly payload: {
        customer: Customer;
        card: CustomerCard;
    }) { }
}
export class UpdateCreditCardSuccess implements Action {
    readonly type = ActionTypes.UPDATE_CREDIT_CARD_SUCCESS;
}
export class UpdateCreditCardFailed implements Action {
    readonly type = ActionTypes.UPDATE_CREDIT_CARD_FAILED;
}

export class SendEmail implements Action {
    readonly type = ActionTypes.SEND_EMAIL;

    constructor(readonly payload: {
        customer_ids: number[];
        subject: string;
        html_template: string;
        text_template: string;
        main_contact: boolean;
    }) { }
}
export class SendEmailSuccess implements Action {
    readonly type = ActionTypes.SEND_EMAIL_SUCCESS;
}
export class SendEmailFailed implements Action {
    readonly type = ActionTypes.SEND_EMAIL_FAILED;
}

export class CreateExpense implements Action {
    readonly type = ActionTypes.CREATE_EXPENSE;
    constructor(readonly payload: {
        customer: Customer;
        expense: CustomerExpense;
    }) { }
}
export class CreateExpenseSuccess implements Action {
    readonly type = ActionTypes.CREATE_EXPENSE_SUCCESS;
}
export class CreateExpenseFailed implements Action {
    readonly type = ActionTypes.CREATE_EXPENSE_FAILED;
}

export class GetTimeTracking implements Action {
    readonly type = ActionTypes.GET_TIME_TRACKING;
    constructor(readonly payload: {number: string, customer_id: number}) {}
}
export class GetTimeTrackingSuccess implements Action {
    readonly type = ActionTypes.GET_TIME_TRACKING_SUCCESS;
}
export class GetTimeTrackingFailed implements Action {
    readonly type = ActionTypes.GET_TIME_TRACKING_FAILED;
    constructor(readonly payload: NGRXError<Actions>) { }
}

export type Actions =
    | GetCurrent
    | GetCurrentSuccess
    | GetCurrentFailed
    | SetActiveCustomer
    | Error
    | Reset
    | Load
    | LoadSuccess
    | LoadFailed
    | PaginationSet
    | Create
    | CreateSuccess
    | CreateFailed
    | GetById
    | GetByIdSuccess
    | GetByIdFailed
    | Update
    | UpdateSuccess
    | UpdateFailed
    | Delete
    | DeleteSuccess
    | DeleteFailed
    | DeleteSelf
    | DeleteSelfSuccess
    | DeleteSelfFailed
    | Restore
    | RestoreSuccess
    | RestoreFailed
    | Reload
    | GetByNameOrEmail
    | GetByNameEmailCustomerName
    | AddUserToCustomer
    | AddUserToCustomerSuccess
    | AddUserToCustomerFailed
    | RemoveUserFromCustomer
    | RemoveUserFromCustomerSuccess
    | RemoveUserFromCustomerFailed
    | ChangeUserCustomerRole
    | ChangeUserCustomerRoleSuccess
    | ChangeUserCustomerRoleFailed
    | ResendNewUserMail
    | ResendNewUserMailSuccess
    | ResendNewUserMailFailed
    | importFromDK
    | importFromDKSuccess
    | importFromDKFailed
    | GetSubscription
    | GetSubscriptionSuccess
    | GetSubscriptionFailed
    | SubscriptionSetTax
    | GetInvoice
    | GetInvoiceSuccess
    | GetInvoiceFailed
    | GetInvoicePdf
    | GetInvoicePdfSuccess
    | GetInvoicePdfFailed
    | GetInvoiceHtml
    | GetInvoiceHtmlSuccess
    | GetInvoiceHtmlFailed
    | InvoiceEmail
    | InvoiceEmailSuccess
    | InvoiceEmailFailed
    | GetPaymentMode
    | GetPaymentModeSuccess
    | GetPaymentModeFailed
    | UpdatePaymentMode
    | UpdatePaymentModeSuccess
    | UpdatePaymentModeFailed
    | UpdateCreditCard
    | UpdateCreditCardSuccess
    | UpdateCreditCardFailed
    | SendEmail
    | SendEmailSuccess
    | SendEmailFailed
    | CreateExpense
    | CreateExpenseSuccess
    | CreateExpenseFailed
    | GetEmailLogs
    | GetEmailLogsSuccess
    | GetEmailLogsFailed
    | ResendEmailLogs
    | ResendEmailLogsSuccess
    | ResendEmailLogsFailed
    | GetTimeTracking
    | GetTimeTrackingSuccess
    | GetTimeTrackingFailed
    ;
