import { Injectable } from '@angular/core';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { User } from '@appRoot/core/user/models';
import { UserService } from '@appRoot/core/user/services/user.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { MailTemplate, Notification } from '../../models';
import { NotificationHttpService } from '../../services/notification-http.service';
import * as mailingActions from '../actions/mailing.actions';
import * as notificationActions from '../actions/notification.actions';
import * as notificationSelectors from '../selectors/notification.selectors';


@Injectable()
export class NotificationEffects {

    constructor(
        private actions$: Actions,
        private httpService: NotificationHttpService,
        private userService: UserService,
        private store: Store<any>,
    ) {}

    @Effect()
    load$: Observable<Action> = this.actions$.pipe(
        ofType(notificationActions.ActionTypes.LOAD),
        withLatestFrom(
            this.userService.activeUser$,
            this.store.select(notificationSelectors.getLoaded),
            this.store.select(notificationSelectors.getNotificationsArray)
        ),
        filter(([action, user, loaded, entities]: [notificationActions.Load, User, boolean, Notification[]]) => !!user),
        switchMap(([action, user, loaded, entities]) => {
            if(loaded){
                return [
                    new notificationActions.LoadSuccess(entities),
                ];
            }

            return this.httpService.fetch({ uid: user.id }).pipe(
                switchMap(response => {
                    let notifications = response.map(e => new Notification(e));
                    let mails = response.map(e => new MailTemplate(e.mail_template));
                    return [
                        new notificationActions.LoadSuccess(notifications),
                        new mailingActions.LoadSuccess(mails),
                    ];
                }),
                catchError(error => [
                    new notificationActions.Error(new NGRXError(action, error)),
                    new notificationActions.LoadFailed,
                ]),
            );
        })
    );

    @Effect()
    update$: Observable<Action> = this.actions$.pipe(
        ofType(notificationActions.ActionTypes.UPDATE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [notificationActions.Update, User]) => !!user),
        mergeMap(([action, user]) => {
            return this.httpService.update( {uid: user.id, notification: action.payload.entity.notification, settings: action.payload.settings}).pipe(
                switchMap(response => {
                    let notification = new Notification(response);
                    let mail = new MailTemplate(response.mail_template);
                    return [
                        new notificationActions.UpdateSuccess(notification),
                        new mailingActions.GetByIdSuccess(mail),
                    ];
                }),
                catchError(error => [
                    new notificationActions.Error(new NGRXError(action, error)),
                    new notificationActions.UpdateFailed,
                ]),
            );
        }),
    );

}
