import { Injectable } from '@angular/core';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { isEmpty, pickBy } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
    CustomerGroups,
    ICustomerGroupsRequestUpdate,
    ICustomerGroupsRequestImportFormDK,
    CustomerGroupsChangeNotification,
    ICustomerGroupsChangeNotificationRequestUpdate,
    ICustomerGroupsChangeNotificationMainRequestUpdate
} from '../../models';
import { User } from '@appRoot/core/user/models';
import { CustomerGroupsHttpService } from '../../services/customer-groups-http.service';
import { UserService } from '@appRoot/core/user/services/user.service';
import * as customerGroupsStorageActions from '../actions/customer-groups-storage.actions';
import * as customerGroupsActions from '../actions/customer-groups.actions';
import * as customerGroupsChangeNotificationsActions from "@appRoot/core/customer/ngrx-store/actions/customer-groups-change-notifications.actions";
import * as customerGroupsChangeNotificationsStorageActions from "@appRoot/core/customer/ngrx-store/actions/customer-groups-change-notifications-storage.actions";
import * as customerGroupsChangeNotificationsMainActions from "@appRoot/core/customer/ngrx-store/actions/customer-groups-change-notifications-main.actions";


@Injectable()
export class CustomerGroupsEffects {

    constructor(
        private actions$: Actions,
        private httpService: CustomerGroupsHttpService,
        private userService: UserService,
        private store: Store<any>,
    ) {}

    @Effect()
    load$: Observable<Action> = this.actions$.pipe(
        ofType(customerGroupsActions.ActionTypes.LOAD),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerGroupsActions.Load, User]) => !!user),
        switchMap(([action, user]) =>
            this.httpService
            .fetch({...action.payload, uid: user.id }).pipe(
                switchMap(response => {
                    let customer_groups: CustomerGroups[] = [];
                    
                    response.data.forEach(group => {
                        customer_groups.push(new CustomerGroups(group));
                    });

                    return [
                        new customerGroupsStorageActions.UpsertMany(customer_groups),
                        new customerGroupsActions.LoadSuccess({...response, data: customer_groups}, action.selector),
                    ];
                }),
                catchError(error => [
                    new customerGroupsActions.Error(new NGRXError(action, error)),
                    new customerGroupsActions.LoadFailed,
                ]),
            )
        )
    );

    @Effect()
    loadActive$: Observable<Action> = this.actions$.pipe(
        ofType(customerGroupsActions.ActionTypes.LOAD_ACTIVE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerGroupsActions.LoadActive, User]) => !!user),
        switchMap(([action, user]) =>
            this.httpService
                .fetch({...action.payload, uid: user.id }).pipe(
                switchMap(response => {
                    let customer_groups: CustomerGroups[] = [];

                    response.data.forEach(group => {
                        customer_groups.push(new CustomerGroups(group));
                    });

                    return [
                        new customerGroupsStorageActions.UpsertMany(customer_groups),
                        new customerGroupsActions.LoadActiveSuccess({...response, data: customer_groups}, action.selector),
                    ];
                }),
                catchError(error => [
                    new customerGroupsActions.Error(new NGRXError(action, error)),
                    new customerGroupsActions.LoadActiveFailed,
                ]),
            )
        )
    );

    @Effect()
    getById$: Observable<Action> = this.actions$.pipe(
        ofType(customerGroupsActions.ActionTypes.GET_BY_ID),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerGroupsActions.GetById, User]) => !!user),
        switchMap(([action, user]) =>
            this.httpService.show({ uid: user.id, group_id: action.payload }).pipe(
                switchMap((response) => {
                    let group = new CustomerGroups(response);

                    return [
                        new customerGroupsStorageActions.UpsertOne(group),
                        new customerGroupsActions.GetByIdSuccess({ customer_group: group }),
                    ];
                }),
                catchError(error => [
                    new customerGroupsActions.Error(new NGRXError(action, error)),
                    new customerGroupsActions.GetByIdFailed,
                ])
            )
        )
    );

    @Effect()
    update$: Observable<Action> = this.actions$.pipe(
        ofType(customerGroupsActions.ActionTypes.UPDATE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerGroupsActions.Update, User]) => !!user),
        switchMap(([action, user]) => {
            let data: ICustomerGroupsRequestUpdate = {};

            if (action instanceof customerGroupsActions.Update) {
                data.type = action.payload.changes.customer_group.type && (action.payload.customer_group.type !== action.payload.changes.customer_group.type)
                    ? action.payload.changes.customer_group.type
                    : undefined;
                data.message = action.payload.changes.customer_group.message && (action.payload.customer_group.message !== action.payload.changes.customer_group.message)
                    ? action.payload.changes.customer_group.message
                    : undefined;
                data.status = (action.payload.customer_group.status !== action.payload.changes.customer_group.status)
                    ? action.payload.changes.customer_group.status
                    : undefined;
            }

            const cleanedData = pickBy(data, v => v !== undefined);
            if (isEmpty(cleanedData)) {
                return [new customerGroupsActions.UpdateFailed];
            }

            return this.httpService.update({uid: user.id, group_id: action.payload.customer_group.id}, cleanedData).pipe(
                switchMap((response) => {
                    let group = new CustomerGroups(response);

                    return [
                        new customerGroupsStorageActions.UpsertOne(group),
                        new customerGroupsActions.UpdateSuccess({ customer_group: group }),
                    ];
                }),
                catchError(error => [
                    new customerGroupsActions.Error(new NGRXError(action, error)),
                    new customerGroupsActions.UpdateFailed,
                ])
            );
        }),
    );

    @Effect()
    importGroupsFromDK$: Observable<Action> = this.actions$.pipe(
        ofType(customerGroupsActions.ActionTypes.IMPORT_GROUPS_FROM_DK),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerGroupsActions.importGroupsFromDK, User]) => !!user),
        switchMap(([action, user]) => {
            let params: ICustomerGroupsRequestImportFormDK = {
                uid: user.id,
            };
            return this.httpService.importGroupsFormDK(params).pipe(
                map(response => new customerGroupsActions.importGroupsFromDKSuccess()),
                catchError(error => [
                    new customerGroupsActions.importGroupsFromDKFailed(),
                    new customerGroupsActions.Error(new NGRXError(action, error)),
                ]),
            );
        }),
    );

    @Effect()
    importGroupsFromDKSuccess$: Observable<Action> = this.actions$.pipe(
        ofType(customerGroupsActions.ActionTypes.IMPORT_GROUPS_FROM_DK_SUCCESS),
        map(action => new customerGroupsActions.Reload)
    );

    @Effect()
    loadChangeNotifications$: Observable<Action> = this.actions$.pipe(
        ofType(customerGroupsChangeNotificationsActions.ActionTypes.GET),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerGroupsChangeNotificationsActions.Get, User]) => !!user),
        switchMap(([action, user]) =>
            this.httpService.getChangeNotifications({ uid: user.id, group_id: action.payload }).pipe(
                switchMap(response => {
                    let change_notifications: CustomerGroupsChangeNotification[] = [];

                    response.forEach(notification => {
                        change_notifications.push(new CustomerGroupsChangeNotification(notification));
                    });

                    return [
                        new customerGroupsChangeNotificationsStorageActions.UpsertMany(change_notifications),
                        new customerGroupsChangeNotificationsActions.GetSuccess
                    ];
                }),
                catchError(error => [
                    new customerGroupsChangeNotificationsActions.Error(new NGRXError(action, error)),
                    new customerGroupsChangeNotificationsActions.GetFailed
                ]),
            )
        )
    );

    @Effect()
    updateChangeNotifications$: Observable<Action> = this.actions$.pipe(
        ofType(customerGroupsChangeNotificationsActions.ActionTypes.UPDATE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerGroupsChangeNotificationsActions.Update, User]) => !!user),
        switchMap(([action, user]) => {
            let data: ICustomerGroupsChangeNotificationRequestUpdate = {};

            if (action instanceof customerGroupsChangeNotificationsActions.Update) {
                data.subject = action.payload.changes.notification.subject && (action.payload.notification.subject !== action.payload.changes.notification.subject)
                    ? action.payload.changes.notification.subject
                    : undefined;
                data.to_email = action.payload.notification.to_email !== action.payload.changes.notification.to_email
                    ? action.payload.changes.notification.to_email
                    : undefined;
                data.cc_emails = action.payload.notification.cc_emails !== action.payload.changes.notification.cc_emails
                    ? action.payload.changes.notification.cc_emails
                    : undefined;
                data.html_template = action.payload.changes.notification.html_template && (action.payload.notification.html_template !== action.payload.changes.notification.html_template)
                    ? action.payload.changes.notification.html_template
                    : undefined;
                data.text_template = action.payload.changes.notification.text_template && (action.payload.notification.text_template !== action.payload.changes.notification.text_template)
                    ? action.payload.changes.notification.text_template
                    : undefined;
                data.status = (action.payload.notification.status !== action.payload.changes.notification.status)
                    ? action.payload.changes.notification.status
                    : undefined;
                data.use_main = (action.payload.notification.use_main !== action.payload.changes.notification.use_main)
                    ? action.payload.changes.notification.use_main
                    : undefined;
            }

            const cleanedData = pickBy(data, v => v !== undefined);
            if (isEmpty(cleanedData)) {
                return [new customerGroupsChangeNotificationsActions.UpdateFailed];
            }

            return this.httpService.updateChangeNotifications({uid: user.id, id: action.payload.notification.id}, cleanedData).pipe(
                switchMap((response) => {
                    let notification = new CustomerGroupsChangeNotification(response);

                    return [
                        new customerGroupsChangeNotificationsStorageActions.UpsertOne(notification),
                        new customerGroupsChangeNotificationsActions.UpdateSuccess({ notification: notification }),
                    ];
                }),
                catchError(error => [
                    new customerGroupsChangeNotificationsActions.Error(new NGRXError(action, error)),
                    new customerGroupsChangeNotificationsActions.UpdateFailed,
                ])
            );
        }),
    );

    @Effect()
    loadChangeNotificationsMain$: Observable<Action> = this.actions$.pipe(
        ofType(customerGroupsChangeNotificationsMainActions.ActionTypes.GET_BY_GROUP_ID),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerGroupsChangeNotificationsMainActions.GetByGroupId, User]) => !!user),
        switchMap(([action, user]) =>
            this.httpService.getChangeNotificationsMain({ uid: user.id, group_id: action.payload }).pipe(
                switchMap(response => [
                    new customerGroupsChangeNotificationsMainActions.GetByGroupIdSuccess(response)
                ]),
                catchError(error => [
                    new customerGroupsChangeNotificationsMainActions.Error(new NGRXError(action, error)),
                    new customerGroupsChangeNotificationsMainActions.GetByGroupIdFailed()
                ]),
            )
        )
    );

    @Effect()
    updateChangeNotificationMain$: Observable<Action> = this.actions$.pipe(
        ofType(customerGroupsChangeNotificationsMainActions.ActionTypes.UPDATE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [customerGroupsChangeNotificationsMainActions.Update, User]) => !!user),
        switchMap(([action, user]) => {
            let data: ICustomerGroupsChangeNotificationMainRequestUpdate = {};

            if (action instanceof customerGroupsChangeNotificationsMainActions.Update) {
                data.subject = action.payload.changes.notification.subject && (action.payload.notification.subject !== action.payload.changes.notification.subject)
                    ? action.payload.changes.notification.subject
                    : undefined;
                data.to_email = action.payload.notification.to_email !== action.payload.changes.notification.to_email
                    ? action.payload.changes.notification.to_email
                    : undefined;
                data.cc_emails = action.payload.notification.cc_emails !== action.payload.changes.notification.cc_emails
                    ? action.payload.changes.notification.cc_emails
                    : undefined;
                data.html_template = action.payload.changes.notification.html_template && (action.payload.notification.html_template !== action.payload.changes.notification.html_template)
                    ? action.payload.changes.notification.html_template
                    : undefined;
                data.text_template = action.payload.changes.notification.text_template && (action.payload.notification.text_template !== action.payload.changes.notification.text_template)
                    ? action.payload.changes.notification.text_template
                    : undefined;
            }

            const cleanedData = pickBy(data, v => v !== undefined);
            if (isEmpty(cleanedData)) {
                return [new customerGroupsChangeNotificationsActions.UpdateFailed];
            }

            return this.httpService.updateChangeNotificationMain({uid: user.id, id: action.payload.notification.id}, cleanedData).pipe(
                switchMap(response => [
                    new customerGroupsChangeNotificationsMainActions.UpdateSuccess(response)
                ]),
                catchError(error => [
                    new customerGroupsChangeNotificationsMainActions.Error(new NGRXError(action, error)),
                    new customerGroupsChangeNotificationsMainActions.UpdateFailed,
                ])
            );
        }),
    );
}