import { NGRXError } from 'src/app/core/ngrx-store/models/NGRXError';
import { Actions, ActionTypes } from '../actions/self-signup.actions';
import { IUserCustomersResponse, SelfSignupPackages } from "@appRoot/lazy-modules/self-signup/models";


export interface State {
    loaded: boolean;
    loading: boolean;

    user_customers_loading: boolean;
    user_customers: IUserCustomersResponse[];

    creating: boolean;

    step_service: boolean;
    step_register: boolean;
    step_payment: boolean;
    step_completion: boolean;

    error: NGRXError<Actions>;
    packages: SelfSignupPackages[];
}

export const initialState: State = {
    loaded: false,
    loading: false,

    user_customers_loading: false,
    user_customers: [],

    creating: false,

    step_service: true,
    step_register: false,
    step_payment: false,
    step_completion: false,

    error: null,
    packages: []
};

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {

        case ActionTypes.ERROR:
            return {
                ...state,
                error: action.payload
            };

        case ActionTypes.RESET:
            return {
                ...initialState
            };

        case ActionTypes.LOAD:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                packages: action.payload,
            };
        case ActionTypes.LOAD_FAILED:
            return {
                ...state,
                loading: false,
                loaded: false,
            };

        case ActionTypes.GET_USER_CUSTOMERS:
            return {
                ...state,
                user_customers_loading: true,
            };
        case ActionTypes.GET_USER_CUSTOMERS_SUCCESS:
            return {
                ...state,
                user_customers_loading: false,
                user_customers: action.payload,
            };
        case ActionTypes.GET_USER_CUSTOMERS_FAILED:
            return {
                ...state,
                user_customers_loading: false,
                user_customers: [],
            };

        case ActionTypes.CREATE:
            return {
                ...state,
                creating: true,
            };
        case ActionTypes.CREATE_SUCCESS:
        case ActionTypes.CREATE_FAILED:
            return {
                ...state,
                creating: false,
            };

        case ActionTypes.STEP_REGISTER:
            return {
                ...state,
                step_register: true,
                step_service: false,
                step_payment: false,
                step_completion: false,
                user_customers: []
            };
        case ActionTypes.STEP_SERVICE:
            return {
                ...state,
                step_register: false,
                step_service: true,
                step_payment: false,
                step_completion: false
            };
        case ActionTypes.STEP_PAYMENT:
            return {
                ...state,
                step_register: false,
                step_service: false,
                step_payment: true,
                step_completion: false
            };
        case ActionTypes.STEP_COMPLETION:
            return {
                ...state,
                step_register: false,
                step_service: false,
                step_payment: false,
                step_completion: true
            };

        default:
            return state;
    }
}