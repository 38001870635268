import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";

import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { SettingsService } from '@appRoot/core/services/settings.service';
import { AuthenticationService } from './authentication.service';


@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

    private refreshInProgress: boolean = false;

    constructor(
        private settings: SettingsService,
        private auth: AuthenticationService,
        ) {}

    addToken(req: HttpRequest<any>, token: string|null): HttpRequest<any> {
        return req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + token),
            withCredentials: true
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if( req.url.startsWith(this.settings.ASSETS_PATH) ) {
            return next.handle(req);
        }

        //Skip if the request is not for api
        if( !req.url.includes(this.settings.API_PATH) ) {
            return next.handle(req);
        }

        //Skip API Request endpoints /login, /login/refresh or /logout
        if(req.url.includes(this.settings.API_PATH+'/login') || req.url.includes(this.settings.API_PATH+'/logout')) {
            return next.handle(this.addToken(req, this.auth.getAccessToken()));
        }

        return next.handle(this.addToken(req, this.auth.getAccessToken())).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    switch ((<HttpErrorResponse>error).status) {
                        case 401:
                            return this.handle401Error(req, next, error);
                        default:
                            return observableThrowError(error);
                    }
                }
                return observableThrowError(error);
            })
        );
    }

    private handle401Error(req: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse): Observable<HttpEvent<any>> {
        if(!this.refreshInProgress) {
            this.refreshInProgress = true;
            this.auth.dispatchRefreshToken();
        }

        return this.auth.getStoreRefresh$()
            .pipe(
                filter( refresh => refresh !== null ),
                take(1),
                switchMap( (refresh) => {
                    this.refreshInProgress = false;
                    if(!refresh) {
                        return observableThrowError(error);
                    }
                    return next.handle(this.addToken(req, this.auth.getAccessToken()));
                })
            );
    }

}
