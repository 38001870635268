import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { User } from "@appRoot/core/user/models";
import { UserService } from '@appRoot/core/user/services/user.service';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { NgUnsubscribe } from '../../../../core/interfaces';


@Component({
    selector: 'app-user-short-info',
    templateUrl: './user-short-info.component.html',
    styleUrls: ['./user-short-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserShortInfoComponent implements OnInit, NgUnsubscribe {

    readonly ngUnsubscribe = new Subject();

    public user$ = new BehaviorSubject<User>(null);
    public faCheck = faCheck;

    @Input()
    set user(user: User) {
        this.user$.next(user);
    };

    constructor(
        public cdr: ChangeDetectorRef,
        private userService: UserService,
        private store: Store<any>
    ) { }

    ngOnInit() {

    }

    ngOnDestroy() {
        this.unsubscribe();
    }

    unsubscribe() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }




}
