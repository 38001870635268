export const ROUTE_NAMES = {
	container: '',
	list: 'list',
	actions: {
		add:  'add',
		edit: 'edit',
		view: 'view',
	},
	dkImport: 'dk-import',
	groups: 'groups',
	contacts: 'contacts',
	history: 'history',
	info: 'info',
	mailTemplates: 'mail-templates',
	expenseSettings: 'expense-settings',
};

export type ROUTE_ACTIONS = keyof typeof ROUTE_NAMES.actions;