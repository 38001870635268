import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UserService } from '@appRoot/core/user/services/user.service';
import { Observable } from 'rxjs';
import { User } from '@appRoot/core/user/models';


@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent implements OnInit {

    public activeUser$: Observable<User>;

    constructor(
        private userService: UserService
    ) { }

    ngOnInit(): void {
        this.activeUser$ =  this.userService.activeUser$;
    }

}
