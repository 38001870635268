import { isNil } from 'lodash';
import { IWhmAccountActionHistoryResponse, IWhmAccountResponse } from './WhmAccount';
import { TAC_SERVICES_TYPES } from '@appRoot/lazy-modules/tac-services/models';


export class WhmAccountActionHistory {
    id: number;
    whm_account_id: number;
    user_id: number;
    username: string;
    action: string;
    created: number; // timestamp
    notice: string;

    origin: Partial<IWhmAccountResponse>;
    changes: Partial<IWhmAccountResponse>;
    service: TAC_SERVICES_TYPES.whm_dev_service | TAC_SERVICES_TYPES.whm_prod_service;

    constructor(data?: IWhmAccountActionHistoryResponse) {
        if(data) {
            this.createFromResponse(data);
        }
    }

    createFromResponse(data: IWhmAccountActionHistoryResponse) {
        this.id = data.id;
        this.whm_account_id = data.whm_account_id;
        this.user_id = data.user_id;
        this.username = data.user?.name;
        this.action = data.action;
        this.created = !isNil(data.created_at) ? (new Date(data.created_at)).getTime() : null;
        this.notice = data.notice;
        this.origin = data.account_data && data.account_data.origin ? data.account_data.origin : null
        this.changes = data.account_data && data.account_data.changes ? data.account_data.changes : null
        this.service = data.account_data && data.account_data.service ? data.account_data.service : null
    }
}
