<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
    <!-- START navbar header-->
    <div class="navbar-header">
        <a class="navbar-brand" [routerLink]="['/home']">
            <div class="brand-logo">
                <img class="img-fluid" [src]="logo" alt="App Logo" />
            </div>
            <div class="brand-logo-collapsed">
                <img class="img-fluid" [src]="logoSingle" alt="App Logo" />
            </div>
        </a>
    </div>
    <!-- END navbar header-->

    <div class="col-md-4 col-lg-4 col-6">
        <ng-container *ngxPermissionsOnly="['user_view_all_customer']">
            <app-customer-autocomplete (customerChanges)="entityChanges($event)" [autoClear]="true" [withoutDisabled]="true"></app-customer-autocomplete>
        </ng-container>
    </div>

    <!-- START Right Navbar-->
    <div class="nav-wrapper navbar-expand align-self-stretch">

        <ul class="nav navbar-nav h-100">
            <li class="nav-item">

                <div class="btn-group h-100" dropdown [insideClick]="true">
                    <button dropdownToggle type="button" class="btn dropdown-toggle btn-inverse">
                        <fa-icon [icon]="['far', 'user']" class="mr-2"></fa-icon>

                        <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right min-w-100">
                        <li>
                            <a class="dropdown-item rounded-0" [routerLink]="['/user-settings/account']">
                                <fa-icon [icon]="['fas', 'user-cog']" class="mr-2"></fa-icon>User settings
                            </a>
                        </li>
                        <ng-template ngxPermissionsOnly="view_own_user">
                            <li class="divider dropdown-divider"></li>
                            <li>
                                <a class="dropdown-item rounded-0" [routerLink]="user_link">
                                    <fa-icon [icon]="['fas', 'user']" class="mr-2"></fa-icon>User account
                                </a>
                            </li>
                        </ng-template>
                        <li class="divider dropdown-divider"></li>
                        <li>
                            <span class="dropdown-item rounded-0">
                                <app-logout className="btn p-0 shadow-none text-inherit"><fa-icon [icon]="['fas', 'sign-out-alt']" class="mr-2"></fa-icon>{{ 'Sign Out' | translate }}</app-logout>
                            </span>
                        </li>
                    </ul>
                </div>

            </li>
        </ul>
    </div>
    <!-- END Right Navbar-->

</nav>
<!-- END Top Navbar-->


