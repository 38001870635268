import { Component } from '@angular/core';
import { SettingsService } from '@appRoot/core/services/settings.service';


@Component({
    selector: 'app-login-page',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginPageComponent {
    constructor(public settings: SettingsService) {}
}
