import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as customer from './reducers/customer.reducer';
import * as customer_storage from './reducers/customer-storage.reducer';
import * as customer_contacts from './reducers/customer-contacts.reducer';
import * as customer_contacts_storage from './reducers/customer-contacts-storage.reducer';
import * as customer_groups from './reducers/customer-groups.reducer';
import * as customer_groups_storage from './reducers/customer-groups-storage.reducer';
import * as customer_admin_messages from './reducers/customer-admin-messages.reducer';
import * as customer_system_messages from './reducers/customer-system-messages.reducer';
import * as customer_mail_templates from './reducers/customer-mail-templates.reducer';
import * as customer_history from './reducers/customer-history.reducer';
import * as customer_contact_history from './reducers/customer-contact-history.reducer';
import * as customer_comments from './reducers/customer-comments.reducer';
import * as customer_groups_change_notifications from './reducers/customer-groups-change-notifications.reducer';
import * as customer_groups_change_notifications_storage from './reducers/customer-groups-change-notifications-storage.reducer';
import * as customer_groups_change_notifications_main from './reducers/customer-groups-change-notifications-main.reducer';
import * as customer_expense_settings from './reducers/customer-expense-settings.reducer';


export interface State {
    customer: customer.State;
    customer_storage: customer_storage.State;
    customer_contacts: customer_contacts.State;
    customer_contacts_storage: customer_contacts_storage.State;
    customer_groups: customer_groups.State;
    customer_groups_storage: customer_groups_storage.State;
    customer_admin_messages: customer_admin_messages.State;
    customer_system_messages: customer_system_messages.State;
    customer_mail_templates: customer_mail_templates.State;
    customer_history: customer_history.State;
    customer_contact_history: customer_contact_history.State;
    customer_comments: customer_comments.State;
    customer_groups_change_notifications: customer_groups_change_notifications.State;
    customer_groups_change_notifications_storage: customer_groups_change_notifications_storage.State;
    customer_groups_change_notifications_main: customer_groups_change_notifications_main.State;
    customer_expense_settings: customer_expense_settings.State;
}

export const reducers: ActionReducerMap<State> = {
    customer: customer.reducer,
    customer_storage: customer_storage.reducer,
    customer_contacts: customer_contacts.reducer,
    customer_contacts_storage: customer_contacts_storage.reducer,
    customer_groups: customer_groups.reducer,
    customer_groups_storage: customer_groups_storage.reducer,
    customer_admin_messages: customer_admin_messages.reducer,
    customer_system_messages: customer_system_messages.reducer,
    customer_mail_templates: customer_mail_templates.reducer,
    customer_history: customer_history.reducer,
    customer_contact_history: customer_contact_history.reducer,
    customer_comments: customer_comments.reducer,
    customer_groups_change_notifications: customer_groups_change_notifications.reducer,
    customer_groups_change_notifications_storage: customer_groups_change_notifications_storage.reducer,
    customer_groups_change_notifications_main: customer_groups_change_notifications_main.reducer,
    customer_expense_settings: customer_expense_settings.reducer
};

export const ModuleStore = createFeatureSelector<State>('Customer');

