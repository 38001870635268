import { IPaginatedResponse, Omit } from '@appRoot/core/interfaces';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Action } from '@ngrx/store';
import { ICustomerGroupsRequestIndexParams, CustomerGroups } from '../../models';


export enum ActionTypes {
    ERROR = '[Customer groups] error',
    RESET = '[Customer groups] reset',

    LOAD = '[Customer groups] load',
    LOAD_SUCCESS = '[Customer groups] load success',
    LOAD_FAILED = '[Customer groups] load failed',

    LOAD_ACTIVE = '[Customer groups] load active groups',
    LOAD_ACTIVE_SUCCESS = '[Customer groups] load active groups success',
    LOAD_ACTIVE_FAILED = '[Customer groups] load active groups failed',

    PAGINATION_SET = '[Customer groups] pagination set',

    CREATE = '[Customer groups] create',
    CREATE_SUCCESS = '[Customer groups] create success',
    CREATE_FAILED = '[Customer groups] create failed',

    GET_BY_ID = '[Customer groups] get',
    GET_BY_ID_SUCCESS = '[Customer groups] get success',
    GET_BY_ID_FAILED = '[Customer groups] get failed',

    UPDATE = '[Customer groups] update',
    UPDATE_SUCCESS = '[Customer groups] update success',
    UPDATE_FAILED = '[Customer groups] update failed',

    DELETE = '[Customer groups] delete',
    DELETE_SUCCESS = '[Customer groups] delete success',
    DELETE_FAILED = '[Customer groups] delete failed',

    RESTORE = '[Customer groups] restore',
    RESTORE_SUCCESS = '[Customer groups] restore success',
    RESTORE_FAILED = '[Customer groups] restore failed',

    RELOAD = '[Customer groups] reload',

    IMPORT_GROUPS_FROM_DK = '[Customer groups] import groups from DK',
    IMPORT_GROUPS_FROM_DK_SUCCESS = '[Customer groups] import groups from DK success',
    IMPORT_GROUPS_FROM_DK_FAILED = '[Customer groups] import groups from DK failed'
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;
    constructor(readonly payload: NGRXError<Actions>) {}
}

export class Reset implements Action {
    readonly type = ActionTypes.RESET;
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;
    constructor(readonly payload: Omit<ICustomerGroupsRequestIndexParams, 'uid'> = {}, readonly selector: string = null) {}
}
export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;
    constructor(readonly payload: IPaginatedResponse<CustomerGroups[]>, readonly selector: string = null) {}
}
export class LoadFailed implements Action {
    readonly type = ActionTypes.LOAD_FAILED;
}

export class LoadActive implements Action {
    readonly type = ActionTypes.LOAD_ACTIVE;
    constructor(readonly payload: Omit<ICustomerGroupsRequestIndexParams, 'uid'> = {search: {status: true}}, readonly selector: string = null) {}
}
export class LoadActiveSuccess implements Action {
    readonly type = ActionTypes.LOAD_ACTIVE_SUCCESS;
    constructor(readonly payload: IPaginatedResponse<CustomerGroups[]>, readonly selector: string = null) {}
}
export class LoadActiveFailed implements Action {
    readonly type = ActionTypes.LOAD_ACTIVE_FAILED;
}

export class PaginationSet implements Action {
    readonly type = ActionTypes.PAGINATION_SET;
    constructor(readonly payload: IPaginatedResponse<number[]>) { }
}

export class Create implements Action {
    readonly type = ActionTypes.CREATE;
    constructor(readonly payload: {customer_group: CustomerGroups;}) { }
}
export class CreateSuccess implements Action {
    readonly type = ActionTypes.CREATE_SUCCESS;
    constructor(readonly payload: {customer_group: CustomerGroups}) { }
}
export class CreateFailed implements Action {
    readonly type = ActionTypes.CREATE_FAILED;
}

export class GetById implements Action {
    readonly type = ActionTypes.GET_BY_ID;
    constructor(readonly payload: number) { }
}
export class GetByIdSuccess implements Action {
    readonly type = ActionTypes.GET_BY_ID_SUCCESS;
    constructor(readonly payload: {customer_group: CustomerGroups}) { }
}
export class GetByIdFailed implements Action {
    readonly type = ActionTypes.GET_BY_ID_FAILED;
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;
    constructor(readonly payload: {
        customer_group: CustomerGroups;
        changes: {customer_group: CustomerGroups;};
    }) { }
}
export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
    constructor(readonly payload: {customer_group: CustomerGroups}) { }
}
export class UpdateFailed implements Action {
    readonly type = ActionTypes.UPDATE_FAILED;
}

export class Delete implements Action {
    readonly type = ActionTypes.DELETE;
    constructor(readonly payload: {customer_groups: CustomerGroups[]; force?: boolean}) {}
}
export class DeleteSuccess implements Action {
    readonly type = ActionTypes.DELETE_SUCCESS;
    constructor(readonly payload: {customer_groups: CustomerGroups[]; force?: boolean}) { }
}
export class DeleteFailed implements Action {
    readonly type = ActionTypes.DELETE_FAILED;
}

export class Restore implements Action {
    readonly type = ActionTypes.RESTORE;
    constructor(readonly payload: {customer_groups: CustomerGroups[]}) { }
}
export class RestoreSuccess implements Action {
    readonly type = ActionTypes.RESTORE_SUCCESS;
    constructor(readonly payload: {customer_groups: CustomerGroups[]}) { }
}
export class RestoreFailed implements Action {
    readonly type = ActionTypes.RESTORE_FAILED;
}

export class Reload implements Action {
    readonly type = ActionTypes.RELOAD;
}

export class importGroupsFromDK implements Action {
    readonly type = ActionTypes.IMPORT_GROUPS_FROM_DK;
}
export class importGroupsFromDKSuccess implements Action {
    readonly type = ActionTypes.IMPORT_GROUPS_FROM_DK_SUCCESS;
}
export class importGroupsFromDKFailed implements Action {
    readonly type = ActionTypes.IMPORT_GROUPS_FROM_DK_FAILED;
}

export type Actions =
    | Error
    | Reset
    | Load
    | LoadSuccess
    | LoadFailed
    | LoadActive
    | LoadActiveSuccess
    | LoadActiveFailed
    | PaginationSet
    | Create
    | CreateSuccess
    | CreateFailed
    | GetById
    | GetByIdSuccess
    | GetByIdFailed
    | Update
    | UpdateSuccess
    | UpdateFailed
    | Delete
    | DeleteSuccess
    | DeleteFailed
    | Restore
    | RestoreSuccess
    | RestoreFailed
    | Reload
    | importGroupsFromDK
    | importGroupsFromDKSuccess
    | importGroupsFromDKFailed
    ;
