import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CustomerAdminMessages } from '../../models';
import { Actions, ActionTypes } from '../actions/customer-admin-messages.actions';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';


function selectId(entity: CustomerAdminMessages): number {
    return entity.id;
}

export const adapter: EntityAdapter<CustomerAdminMessages> = createEntityAdapter<CustomerAdminMessages>({
    selectId: selectId,
    sortComparer: false,
});

export interface State extends EntityState<CustomerAdminMessages> {
    creating: boolean;
    updating: boolean;
    deleting: boolean;
    loading: boolean;
    error: NGRXError<Actions>;
}

export const initialState: State = adapter.getInitialState({
    creating: false,
    updating: false,
    deleting: false,
    loading: false,
    error: null,
});

export const entitySelectors = adapter.getSelectors();

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {

        case ActionTypes.GET:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.GET_SUCCESS:
            return adapter.upsertMany(action.payload, {
                ...state,
                loading: false,
            });

        case ActionTypes.CREATE:
            return {
                ...state,
                creating: true,
            };
        case ActionTypes.CREATE_SUCCESS:
        case ActionTypes.CREATE_FAILED:
            return {
                ...state,
                creating: false,
            };

        case ActionTypes.UPDATE:
            return {
                ...state,
                updating: true,
            };
        case ActionTypes.UPDATE_SUCCESS:
        case ActionTypes.UPDATE_FAILED:
            return {
                ...state,
                updating: false,
            };

        case ActionTypes.DELETE:
            return {
                ...state,
                deleting: true,
            };
        case ActionTypes.DELETE_SUCCESS:
        case ActionTypes.DELETE_FAILED:
            return {
                ...state,
                deleting: false,
            };

        case ActionTypes.ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case ActionTypes.UPSERT_MANY:
            return adapter.upsertMany(action.payload, {
                ...state,
            });

        // case ActionTypes.UPSERT_ONE:
        //     return adapter.upsertOne(action.payload, {
        //         ...state,
        //     });

        // case ActionTypes.REMOVE_MANY:
        //     return adapter.removeMany(action.payload, {
        //         ...state,
        //     });

        case ActionTypes.REMOVE_MANY_BY_CUSTOMER_IDS: {
            let ids = (<number[]>state.ids).map((id: any) => (state.entities)[id])
            .filter(e => !!~action.payload.indexOf(e.customer_id) )
            .map(e => e.id);

            return adapter.removeMany(ids, {
                ...state,
            });
        }

        default:
            return state;
    }
}
