import { createSelector } from '@ngrx/store';
import { ModuleStore } from '../reducers';


export const getCustomerExpenseSettingsState = createSelector(
    ModuleStore,
    (store) => store.customer_expense_settings
);


export const getError = createSelector(
    getCustomerExpenseSettingsState,
    (state) => state.error
);

export const getLoading = createSelector(
    getCustomerExpenseSettingsState,
    (state) => state.loading
);

export const getLoaded = createSelector(
    getCustomerExpenseSettingsState,
    (state) => state.loaded
);

export const getUpdating = createSelector(
    getCustomerExpenseSettingsState,
    (state) => state.updating
);

export const getData = createSelector(
    getCustomerExpenseSettingsState,
    (state) => state.data
);