import { createSelector } from '@ngrx/store';
import { ModuleStore } from '../reducers';


export const getStore = createSelector(
    ModuleStore,
    (store) => store.self_signup
);


export const getLoading = createSelector(
    getStore,
    (state) => state.loading
);

export const getLoaded = createSelector(
    getStore,
    (state) => state.loaded
);

export const getCreating = createSelector(
    getStore,
    (state) => state.creating
);

export const getPackages = createSelector(
    getStore,
    (state) => {
        let arrayForSort = [...state.packages];
        return arrayForSort.sort((a, b) => a.sort_id - b.sort_id);
    }
);

export const getStepRegister = createSelector(
    getStore,
    (state) => state.step_register
);

export const getStepService = createSelector(
    getStore,
    (state) => state.step_service
);

export const getStepPayment = createSelector(
    getStore,
    (state) => state.step_payment
);

export const getStepCompletion = createSelector(
    getStore,
    (state) => state.step_completion
);

export const getUserCustomers = createSelector(
    getStore,
    (state) => state.user_customers
);

export const getError = createSelector(
    getStore,
    (state) => state.error
);