<app-preloader type="sk-fading-circle" *ngIf="!loadingPackages || loading"></app-preloader>

<div class="block-center mt-4 wd-xxl">
    <p class="text-center"></p>
</div>

<div class="d-flex flex-wrap m-4">
    <ng-container *ngIf="loadingPackages">
        <div class="d-flex align-items-stretch block-center" *ngFor="let p of packages">

            <ng-container *ngIf="(selectedPlan && selectedPlan.id == p.id); then selectTemp else noSelectTemp"></ng-container>

            <ng-template #selectTemp>
                <app-plan-select-template
                        class="d-flex align-items-stretch"
                        [type]="'select'"
                        [package]="p"
                        [footerTemplate]="footerSelect">
                </app-plan-select-template>

                <ng-template #footerSelect>
                    Selected plan
                </ng-template>
            </ng-template>

            <ng-template #noSelectTemp>
                <app-plan-select-template
                        class="d-flex align-items-stretch"
                        [type]="'no-select'"
                        [package]="p"
                        [footerTemplate]="footerNoSelect">
                </app-plan-select-template>

                <ng-template #footerNoSelect>
                    <button
                            type="button"
                            class="btn btn-outline-light btn-block no-select-btn text-uppercase"
                            onclick="this.blur();"
                            (click)="selectPackage(p)">
                        Select plan
                    </button>
                </ng-template>
            </ng-template>

        </div>
    </ng-container>
</div>

<ng-template #modalTemplate>
    <div class="modal-content">
        <div class="modal-header">

            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <app-step-progress></app-step-progress>

            <ng-container *ngIf="loadingStepRegister">
                <app-step-register-container></app-step-register-container>
            </ng-container>

            <ng-container *ngIf="loadingStepService">
                <app-step-service-container [service]="service"></app-step-service-container>
            </ng-container>

            <ng-container *ngIf="loadingStepPayment">
                <app-payment-form></app-payment-form>
            </ng-container>

            <ng-container *ngIf="loadingStepCompletion">
                <app-step-completion [selectedPlan]="selectedPlan"></app-step-completion>
            </ng-container>
        </div>
    </div>
</ng-template>
