import { Actions, ActionTypes } from '../actions/confirmation.actions';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';


export interface State {
	error: NGRXError<Actions>
	confirmed: boolean,
	sent: boolean,
	sending: boolean,
	confirming: boolean,
}

export const initialState: State = {
	error: null,
	confirmed: false,
	sent: false,
	sending: false,
	confirming: false,
};

export function reducer(state = initialState, action: Actions): State {
	switch (action.type) {		
		case ActionTypes.SEND: 
			return {
				...state,
				sending: true,
				sent: false,
			};		

		case ActionTypes.SEND_SUCCESS:		
			return {
				...state,
				sent: true,
				sending: false,
			};

		case ActionTypes.CONFIRM: 
			return {
				...state,
				confirming: true,
			};

		case ActionTypes.CONFIRM_SUCCESS:
			return {
				...state,
				confirmed: true,
				confirming: false,
			};
		case ActionTypes.CONFIRM_FAILED:
			return {
				...state,
				confirmed: false,
				confirming: false,
			};

		case ActionTypes.RESET:
			return {
				...initialState
			};


		case ActionTypes.ERROR:
			return {
				...state,
				confirming: false,
				sending: false,
				error: new NGRXError(action.payload.action, action.payload.error),
			};

		default:
			return state;
	}
}