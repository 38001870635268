import { NgModule } from '@angular/core';
import { UserHttpService } from './services/user-http.service';
import { UserService } from './services/user.service';
import { AuthorizationGuard } from './guards/authorization-guard.service';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducers } from './ngrx-store/reducers';
import { effects } from './ngrx-store/effects';


@NgModule({
	declarations: [],
	imports: [
        EffectsModule.forFeature(effects),
        StoreModule.forFeature('User', reducers),
	],
	providers: [
		UserHttpService,
		UserService,
		AuthorizationGuard
	]
})
export class UserModule { }
