import { IUserResponse } from '@appRoot/core/user/models/IUserRequestParams';
import { isNil } from 'lodash';


export class User {
	id: number = null;
	name: string = null;
	email: string = null;
	verified: boolean = false;
	status: string = 'active';
	created: Date = null;
	updated: Date = null;
	deleted: Date = null;
    roleIds: number[] = [];
	customer_role_id: number;
	customerPermissionIds?: number[] = [];
	token_expired?: Date = null;

	constructor(data?: IUserResponse){
		if(data){
            this.id = data.id;
            this.name = data.name;
            this.email = data.email;
            this.verified = data.verified;
			this.status = data.status;
            this.created = data.created_at? new Date(data.created_at*1000): null;
            this.updated = data.updated_at? new Date(data.updated_at*1000): null;
            this.deleted = data.deleted_at? new Date(data.deleted_at*1000): null;
            this.roleIds = data.role_ids;
            this.customer_role_id = data.customer_role_id;
			this.customerPermissionIds = data.customer_permission_ids;
			this.token_expired = !isNil(data.token_expired) ? new Date(data.token_expired) : null;
		}
	}
}

