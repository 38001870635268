export interface IWhmServerResponse {
    id: number;
    host: string;
    port: number;
    username: string;
    created_at: string;
    updated_at: string;
    token?: string;
    data: {
        id?: number;
        whm_server_id?: number;
        ip_address?: string;
        packages?: number[];
        max_accounts?: number;
    };
    total_accounts: number;
}

export interface IWhmServerCreateParams {
    port: number;
    host: string;
    username: string;
    token: string;
    ip_address?: string;
    packages?: number[];
    max_accounts: number;
}

export interface IWhmServerUpdateParams {
    port?: number;
    host?: string;
    username: string;
    token: string;
    ip_address?: string;
    packages?: number[];
    max_accounts: number;
}

export class WhmServer {
    id: number = null;
    host: string = null;
    domain: string = null;
    port: number = null;
    username: string = null;
    token: string = null;
    created: Date;
    updated: Date;
    data: { ip_address?: string; packages?: number[]; maxAccounts?: number; } = {};
    totalAccounts: number;
    isChanged: boolean = false;

    createFromResponse(responseServer: IWhmServerResponse) {
        this.id = +responseServer.id;
        this.host = responseServer.host;
        this.domain = responseServer.host.replace(/(^\w+:|^)\/\//, '');
        this.port = +responseServer.port;
        this.username = responseServer.username;
        this.created = new Date(responseServer.created_at);
        this.updated = new Date(responseServer.updated_at);
        responseServer.token && (this.token = responseServer.token);
        this.data = {
            ip_address: responseServer.data.ip_address ? responseServer.data.ip_address : null,
            packages: responseServer.data.packages ? responseServer.data.packages : [],
            maxAccounts: responseServer.data.max_accounts,
        };
        this.totalAccounts = responseServer.total_accounts;

        return this;
    }

}

