import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserblockService } from './userblock.service';
import { UserService } from '@appRoot/core/user/services/user.service';
import { User } from '@appRoot/core/user/models';
import { AuthenticationService } from '@appRoot/core/authentication/services/authentication.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit, OnDestroy {

    public user: User;
    private userSbn: Subscription;
    public userData: any;
    // private userDataSbn: Subscription;
    
    constructor(
        private userblockService: UserblockService, 
        private userService: UserService,
        private authService: AuthenticationService
        ) {
    }

    ngOnInit() {
        this.userSbn = this.userService.activeUser$.subscribe(user => {
            this.user = user;            
        });

        // this.userDataSbn = this.userService.getUserData$.subscribe(userData => {
        //     this.userData = userData;            
        // });

    }

    ngOnDestroy(){
        this.userSbn.unsubscribe();
        // this.userDataSbn.unsubscribe();
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

    logout(){
        this.authService.dispatchLogout();
    }

}
