import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from '@appRoot/core/services/settings.service';


@Component({
	selector: 'app-password-reset-page',
	templateUrl: './password-reset.component.html',
	styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetPageComponent implements OnInit{

	public token: string = null;

	constructor(
		public settings: SettingsService,
		private route: ActivatedRoute,
		) {}

	ngOnInit(){
		this.route.params.subscribe(e => {
			this.token = e.token? e.token: null;
		})
	}
}
