import { Action } from '@ngrx/store';
import { NGRXError } from '../../../ngrx-store/models/NGRXError';

export enum ActionTypes { 
    ERROR = '[ResetPassword] error',
    CREATE = '[ResetPassword] create',
    CREATE_SUCCESS = '[ResetPassword] create success',
    CHECK = '[ResetPassword] check',
    CHECK_SUCCESS = '[ResetPassword] check success',
    RESET_PASSWORD = '[ResetPassword] reset_password',
    RESET_PASSWORD_SUCCESS = '[ResetPassword] reset_password success',
    RESET = '[ResetPassword] Reset',
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;
    constructor(readonly payload: NGRXError<Actions>) { }
}

export class Reset implements Action {
    readonly type = ActionTypes.RESET;
}

export class Create implements Action {
    readonly type = ActionTypes.CREATE;
    constructor(readonly payload: {email: string} ) { }
}

export class CreateSuccess implements Action {
    readonly type = ActionTypes.CREATE_SUCCESS;
}

export class Check implements Action {
    readonly type = ActionTypes.CHECK;
    constructor(readonly payload: {token: string} ) { }
}

export class CheckSuccess implements Action {
    readonly type = ActionTypes.CHECK_SUCCESS;
}

export class ResetPassword implements Action {
    readonly type = ActionTypes.RESET_PASSWORD;
    constructor(readonly payload: {password: string, password_confirmation: string, token: string} ) { }
}

export class ResetPasswordSuccess implements Action {
    readonly type = ActionTypes.RESET_PASSWORD_SUCCESS;
}


export type Actions =
    | Error
    | Reset
    | Create
    | CreateSuccess
    | Check
    | CheckSuccess
    | ResetPassword
    | ResetPasswordSuccess
    ;