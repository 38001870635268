import { NGRXError } from 'src/app/core/ngrx-store/models/NGRXError';
import { Actions, ActionTypes } from '../actions/available-data.actions';
import { AvailableData } from '../../models';


export interface State {
    error: NGRXError<Actions>;
    loaded: boolean;
    loading: boolean;
    data: AvailableData;
}

export const initialState: State = {
    error: null,
    loaded: false,
    loading: false,
    data: null
};

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {

        case ActionTypes.ERROR:
            return {
                ...state,
                error: action.payload
            };

        case ActionTypes.RESET:
            return {
                ...initialState
            };

        case ActionTypes.LOAD:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                data: new AvailableData(action.payload),
            };
        case ActionTypes.LOAD_FAILED:
            return {
                ...state,
                loading: false,
                loaded: false,
            };

        default:
            return state;
    }
}
