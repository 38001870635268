import { CustomerEffects } from './effects/customer.effects';
import { CustomerContactsEffects } from './effects/customer-contacts.effects';
import { CustomerGroupsEffects } from './effects/customer-groups.effects';
import { CustomerMailTemplatesEffects } from './effects/customer-mail-templates.effects';
import { CustomerExpenseSettingsEffects } from './effects/customer-expense-settings.effects';

export const effects = [
    CustomerEffects,
    CustomerContactsEffects,
    CustomerGroupsEffects,
    CustomerMailTemplatesEffects,
    CustomerExpenseSettingsEffects
];