import { IInvoiceVariations } from '@appRoot/core/customer/models/ICustomerRequestParams';

export class InvoiceLinesVariations {
	Code: string;
	Code2: string;
	Description: string;
	Description2: string;
	Quantity: number;
	QuantityOnBackOrders: number;

	constructor(data?: IInvoiceVariations){
		if(data){
            this.Code = data.Code;
			this.Code2 = data.Code2;
			this.Description = data.Description;
			this.Description2 = data.Description2;
			this.Quantity = data.Quantity;
			this.QuantityOnBackOrders = data.QuantityOnBackOrders;
		}
	}
}