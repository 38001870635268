export const APP_ROOT_ROUTE_PATH = '';

export const ROUTE_PATHS = {
	login: APP_ROOT_ROUTE_PATH+'login',
	logout: APP_ROOT_ROUTE_PATH+'logout',
	register: APP_ROOT_ROUTE_PATH+'register',
	verify: APP_ROOT_ROUTE_PATH+'verify',
	resetPassword: APP_ROOT_ROUTE_PATH+'reset-password',
	lock: APP_ROOT_ROUTE_PATH+'lock',
	maintenance: APP_ROOT_ROUTE_PATH+'maintenance',
	selfSignup: APP_ROOT_ROUTE_PATH+'self-signup',
	NotFound: APP_ROOT_ROUTE_PATH+'404',
	ErrorPage: APP_ROOT_ROUTE_PATH+'500',

	HomeModule: APP_ROOT_ROUTE_PATH+'home',
	AccountModule: APP_ROOT_ROUTE_PATH+'account',
	TacServicesModule: APP_ROOT_ROUTE_PATH+'services',
	PermissionsModule: APP_ROOT_ROUTE_PATH+'permissions',
	RolesModule: APP_ROOT_ROUTE_PATH+'roles',
	UsersModule: APP_ROOT_ROUTE_PATH+'users',
	CustomersModule: APP_ROOT_ROUTE_PATH+'customers',
	WhmModule: APP_ROOT_ROUTE_PATH+'whm',
	NotificationModule: APP_ROOT_ROUTE_PATH+'notifications',
	IpRestrictionsModule: APP_ROOT_ROUTE_PATH+'restrictions',
	PackagesModule: APP_ROOT_ROUTE_PATH+'packages',
	DkProductsModule: APP_ROOT_ROUTE_PATH+'products',
	SourcesModule: APP_ROOT_ROUTE_PATH+'sources',
	EmailLogsModule: APP_ROOT_ROUTE_PATH+'email-logs',
	LoginLogsModule: APP_ROOT_ROUTE_PATH+'login-logs',
	DkPricelistsModule: APP_ROOT_ROUTE_PATH+'pricelists',
};