import { LoadChildrenCallback } from '@angular/router';
import { Dictionary } from '@appRoot/core/interfaces';


export const MODULES: Dictionary<{ id: string, loadChildren: LoadChildrenCallback }> = {
    'PermissionsModule': {
        loadChildren: () => import('src/app/lazy-modules/permissions/permissions.module').then(mod => mod.PermissionsModule),
        id: 'PermissionsLazyModule',
    },
    'RolesModule': {
        loadChildren: () => import('src/app/lazy-modules/roles/roles.module').then(mod => mod.RolesModule),
        id: 'RolesLazyModule'
    },
    'HomeModule': {
        loadChildren: () => import('src/app/lazy-modules/home/home.module').then(mod => mod.HomeModule),
        id: 'HomeModule'
    },
    'AccountModule': {
        loadChildren: () => import('src/app/lazy-modules/account/account.module').then(mod => mod.AccountModule),
        id: 'AccountModule'
    },
    'TacServicesModule': {
        loadChildren: () => import('src/app/lazy-modules/tac-services/tac-services.module').then(mod => mod.TacServicesModule),
        id: 'TacServicesModule'
    },
    'UsersModule': {
        loadChildren: () => import('src/app/lazy-modules/users/users.module').then(mod => mod.UsersModule),
        id: 'UsersLazyModule'
    },
    'CustomersModule': {
        loadChildren: () => import('src/app/lazy-modules/customers/customers.module').then(mod => mod.CustomersModule),
        id: 'CustomersLazyModule'
    },
    'WhmModule': {
        loadChildren: () => import('src/app/lazy-modules/whm-module/whm.module').then(mod => mod.WhmModule),
        id: 'WhmModule'
    },
    'SearchModule': {
        loadChildren: () => import('src/app/lazy-modules/search/search.module').then(mod => mod.SearchModule),
        id: 'SearchLazyModule'
    },
    'NotificationModule': {
        loadChildren: () => import('src/app/lazy-modules/notification/notification.module').then(mod => mod.NotificationModule),
        id: 'NotificationLazyModule'
    },
    'IpRestrictionsModule': {
        loadChildren: () => import('src/app/lazy-modules/ip-restrictions/ip-restrictions.module').then(mod => mod.IpRestrictionsModule),
        id: 'IpRestrictionsLazyModule'
    },
    'PackagesModule': {
        loadChildren: () => import('src/app/lazy-modules/packages/packages.module').then(mod => mod.PackagesModule),
        id: 'PackagesLazyModule'
    },
    'DkProductsModule': {
        loadChildren: () => import('src/app/lazy-modules/dk-products/dk-products.module').then(mod => mod.DkProductsModule),
        id: 'DkProductsLazyModule'
    },
    'SourcesModule': {
        loadChildren: () => import('src/app/lazy-modules/sources/sources.module').then(mod => mod.SourcesModule),
        id: 'SourcesLazyModule'
    },
    'EmailLogsModule': {
        loadChildren: () => import('src/app/lazy-modules/email-logs/email-logs.module').then(mod => mod.EmailLogsModule),
        id: 'EmailLogsLazyModule'
    },
    'SelfSignupModule': {
        loadChildren: () => import('src/app/lazy-modules/self-signup/self-signup.module').then(mod => mod.SelfSignupModule),
        id: 'SelfSignupLazyModule'
    },
    'LoginLogsModule': {
        loadChildren: () => import('src/app/lazy-modules/login-logs/login-logs.module').then(mod => mod.LoginLogsModule),
        id: 'LoginLogsLazyModule'
    },
    'DkPricelistsModule': {
        loadChildren: () => import('src/app/lazy-modules/dk-pricelists/dk-pricelists.module').then(mod => mod.DkPricelistsModule),
        id: 'DkPricelistsLazyModule'
    },
};

