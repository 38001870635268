import { Component, HostBinding, HostListener, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { TranslatorService } from '@appRoot/core/translator/translator.service';
import { SettingsService } from '@appRoot/core/services/settings.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    @HostBinding('class.layout-fixed') get isFixed() { return this.settings.layout.isFixed; }
    @HostBinding('class.aside-collapsed') get isCollapsed() { return this.settings.layout.isCollapsed; }
    @HostBinding('class.layout-boxed') get isBoxed() { return this.settings.layout.isBoxed; }
    @HostBinding('class.layout-fs') get useFullLayout() { return this.settings.layout.useFullLayout; }
    @HostBinding('class.hidden-footer') get hiddenFooter() { return this.settings.layout.hiddenFooter; }
    @HostBinding('class.layout-h') get horizontal() { return this.settings.layout.horizontal; }
    @HostBinding('class.aside-float') get isFloat() { return this.settings.layout.isFloat; }
    @HostBinding('class.offsidebar-open') get offsidebarOpen() { return this.settings.layout.offsidebarOpen; }
    @HostBinding('class.aside-toggled') get asideToggled() { return this.settings.layout.asideToggled; }
    @HostBinding('class.aside-collapsed-text') get isCollapsedText() { return this.settings.layout.isCollapsedText; }

    @HostListener('document:click', ['$event']) onDocumentClick(e: MouseEvent) {
        let target = (<HTMLElement>e.target);
        if (target.getAttribute('href') === '#') {
            e.preventDefault();
        }
    }

    private lang$: Subscription;

    constructor(
        public settings: SettingsService,
        private readonly translate: TranslateService,
        private translator: TranslatorService,
        ) { }

    ngOnInit() {
        this.lang$ = this.translator.getCurrentLanguage().subscribe(lang => {
            this.translate.use(lang);
        });
    }

    ngOnDestroy(){
        this.lang$.unsubscribe();
    }
}
