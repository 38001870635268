import { HttpClient, HttpParams } from '@angular/common/http';
import { IPaginatedResponse } from '@appRoot/core/interfaces';
import { IPermissionResponse, Permission } from '@appRoot/core/roles-permissions/models';
import { SettingsService } from '@appRoot/core/services/settings.service';
import { isBoolean, isNil } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    IOneTimeLinkResponse,
    IWhmAccountCreateParams,
    IWhmAccountDeleteParams,
    IWhmAccountGetLinkParams,
    IWhmAccountGetOneTimeLinkParams,
    IWhmAccountIndexParams,
    IWhmAccountResponse,
    IWhmAccountRestoreParams,
    IWhmAccountRevokeOneTimeLinkParams,
    IWhmAccountLinkForEmail,
    IWhmAccountShowParams,
    IWhmAccountUpdateParams,
    OneTimeLink,
    WhmAccount,
    IWhmAccountGetShared,
    IWhmAccountRemoveShare,
    IWhmAccountShare,
    IWhmAccountSharePermissions,
    IWhmAccountUpdateShare,
    IWhmAccountUpdateAccountEmail,
    IWhmAccountScheduledDeletionParams,
    IWhmAccountChangePass, IWhmAccountUpdatePackageParams
} from '../models';


export abstract class WhmAccountHttpService {

    abstract readonly edge: string;

    constructor(
        protected http: HttpClient,
        protected settings: SettingsService,
    ) {}

    fetch<T extends IWhmAccountIndexParams>(data: T): Observable<IPaginatedResponse<IWhmAccountResponse[]>> {
        let params = this.indexParameterizer(data);

        return this.http.get<{ data: IPaginatedResponse<IWhmAccountResponse[]> }>(`${this.settings.API_PATH}/${this.edge}`, { params: params }).pipe(
            map(response => {
                let accounts = response.data.data.map(item => new WhmAccount(item));
                return response.data;
            }),
        );
    }

    getById<T extends IWhmAccountShowParams>(id: number, data: T): Observable<IWhmAccountResponse> {
        let params = new HttpParams();
        params = params.append('uid', (data.uid).toString());

        return this.http.get<{ data: IWhmAccountResponse }>(`${this.settings.API_PATH}/${this.edge}/${id}`, { params: params }).pipe(
            map(({ data }) => data),
        );
    }

    create<T extends IWhmAccountCreateParams>(data: T): Observable<IWhmAccountResponse> {
        return this.http.post<{ data: IWhmAccountResponse }>(`${this.settings.API_PATH}/${this.edge}`, data).pipe(
            map(({ data }) => data)
        );
    }

    update<T extends IWhmAccountUpdateParams>(id: number, changes: T): Observable<IWhmAccountResponse> {
        return this.http.patch<{ data: IWhmAccountResponse }>(`${this.settings.API_PATH}/${this.edge}/${id}`, changes).pipe(
            map(({ data }) => data)
        );
    }

    updatePackage<T extends IWhmAccountUpdatePackageParams>(id: number, changes: T): Observable<IWhmAccountResponse> {
        return this.http.patch<{ data: IWhmAccountResponse }>(`${this.settings.API_PATH}/${this.edge}/${id}/package-update`, changes).pipe(
            map(({ data }) => data)
        );
    }

    restore<T extends IWhmAccountRestoreParams>(id: number, data: T): Observable<IWhmAccountResponse> {
        return this.http.patch<{ data: IWhmAccountResponse }>(`${this.settings.API_PATH}/${this.edge}/${id}/unsuspend`, data).pipe(
            map(({ data }) => data)
        );
    }

    delete<T extends IWhmAccountDeleteParams>(id: number, data: T): Observable<IWhmAccountResponse> {
        let params = new HttpParams();
        params = params.append('uid', (data.uid).toString());
        params = data.force ? params.append('force', (+data.force).toString()) : params;
        params = data.local ? params.append('local', (+data.local).toString()) : params;
        params = data.notice ? params.append('notice', (data.notice+'')) : params;
        params = data.internal_notice ? params.append('internal_notice', (data.internal_notice+'')) : params;
        params = data.current_customer_id ? params.append("current_customer_id", (data.current_customer_id+'')) : params;
        params = data.scheduled_suspension_date ? params.append("scheduled_suspension_date", (data.scheduled_suspension_date+'')) : params;
        params = data.scheduled_deletion_date ? params.append("scheduled_deletion_date", (data.scheduled_deletion_date+'')) : params;

        return this.http.delete<{ data: IWhmAccountResponse }>(`${this.settings.API_PATH}/${this.edge}/${id}`, { params: params }).pipe(
            map(({ data }) => data)
        );
    }

    scheduledDeletion<T extends IWhmAccountScheduledDeletionParams>(id: number, data: T): Observable<IWhmAccountResponse> {
        let params = new HttpParams();
        params = params.append('uid', (data.uid).toString());
        params = data.scheduled_suspension_date ? params.append("scheduled_suspension_date", (data.scheduled_suspension_date+'')) : params;
        params = data.scheduled_deletion_date ? params.append("scheduled_deletion_date", (data.scheduled_deletion_date+'')) : params;
        params = data.notice ? params.append("notice", (data.notice+'')) : params;
        params = data.internal_notice ? params.append("internal_notice", (data.internal_notice+'')) : params;

        return this.http.patch<{ data: IWhmAccountResponse }>(`${this.settings.API_PATH}/${this.edge}/${id}/scheduled-deletion`, params).pipe(
            map(({ data }) => data)
        );
    }

    getLink<T extends IWhmAccountGetLinkParams>(id: number, data: T): Observable<string> {
        let params = new HttpParams();
        params = params.append('uid', (data.uid).toString());
        params = data.current_customer_id ? params.append('current_customer_id', (data.current_customer_id).toString()) : params;

        return this.http.get<{ data: string }>(`${this.settings.API_PATH}/${this.edge}/${id}/link`, { params: params }).pipe(
            map(({ data }) => data)
        );
    }

    getOneTimeLink<T extends IWhmAccountGetOneTimeLinkParams>(id: number, data: T): Observable<OneTimeLink> {
        let params = new HttpParams();
        params = params.append('uid', (data.uid).toString());
        params = data.current_customer_id ? params.append('current_customer_id', (data.current_customer_id).toString()) : params;

        return this.http.get<{ data: IOneTimeLinkResponse }>(`${this.settings.API_PATH}/${this.edge}/${id}/one-time-link`, { params: params }).pipe(
            map(({ data }) => new OneTimeLink(data))
        );
    }

    getOneTimeLinks<T extends IWhmAccountGetOneTimeLinkParams>(id: number, data: T): Observable<OneTimeLink[]> {
        let params = new HttpParams();
        params = params.append('uid', (data.uid).toString());
        params = data.current_customer_id ? params.append('current_customer_id', (data.current_customer_id).toString()) : params;

        return this.http.get<{ data: IOneTimeLinkResponse[] }>(`${this.settings.API_PATH}/${this.edge}/${id}/one-time-links`, { params: params }).pipe(
            map(({ data }) => data.map(e => new OneTimeLink(e)))
        );
    }

    revokeOneTimeLink<T extends IWhmAccountRevokeOneTimeLinkParams>(id: number, data: T): Observable<boolean> {
        let params = new HttpParams();
        params = params.append('uid', (data.uid).toString());
        params = params.append('token', (data.token).toString());
        params = data.current_customer_id ? params.append('current_customer_id', (data.current_customer_id).toString()) : params;

        return this.http.delete<{ data: boolean }>(`${this.settings.API_PATH}/${this.edge}/${id}/one-time-link`, { params: params }).pipe(
            map(({ data }) => data)
        );
    }

    linkForEmail<T extends IWhmAccountLinkForEmail>(id: number, data: T): Observable<boolean> {
        return this.http.post<{ data: boolean}>(`${this.settings.API_PATH}/${this.edge}/shared-email-link/${id}`, data).pipe(
            map(({ data }) => data)
        );
    }

    shareAccount<T extends IWhmAccountShare>(id: number, data: T): Observable<IWhmAccountResponse> {
        return this.http.post<{ data: IWhmAccountResponse }>(`${this.settings.API_PATH}/${this.edge}/share/${id}`, data).pipe(
            map(({ data }) => data)
        );
    }

    getPermissionsForShareAccount<T extends IWhmAccountSharePermissions>(id: number, data: T): Observable<Permission[]> {
        let params = new HttpParams({ fromObject: <any>{ ...data } });

        return this.http.get<{ data: IPermissionResponse[] }>(`${this.settings.API_PATH}/${this.edge}/share-permissions/${id}`, { params: params }).pipe(
            map(response => {
                return response.data.map(e => new Permission(e));
            }),
        );
    }

    shareUpdateAccount<T extends IWhmAccountUpdateShare>(shared_id: number, data: T): Observable<IWhmAccountResponse> {
        return this.http.patch<{ data: IWhmAccountResponse }>(`${this.settings.API_PATH}/${this.edge}/share/${shared_id}`, data).pipe(
            map(({ data }) => data)
        );
    }

    shareRemoveAccount<T extends IWhmAccountRemoveShare>(shared_id: number, data: T): Observable<IWhmAccountResponse> {
        let params = new HttpParams({ fromObject: <any>{ ...data } });

        return this.http.delete<{ data: IWhmAccountResponse }>(`${this.settings.API_PATH}/${this.edge}/share/${shared_id}`, { params: params }).pipe(
            map(({ data }) => data)
        );
    }

    getSharedAccounts<T extends IWhmAccountGetShared>(data: T): Observable<IWhmAccountResponse[]> {
        let params = new HttpParams({ fromObject: <any>{ ...data } });

        return this.http.get<{ data: IWhmAccountResponse[] }>(`${this.settings.API_PATH}/${this.edge}/shared`, { params: params }).pipe(
            map(response => {
                return response.data;
            }),
        );
    }

    updateAccountEmail<T extends IWhmAccountUpdateAccountEmail>(data: T): Observable<boolean> {
        return this.http.post<{ data: boolean }>(`${this.settings.API_PATH}/${this.edge}/update-contact-mail`, data).pipe(
            map(({ data }) => data)
        );
    }

    changePass<T extends IWhmAccountChangePass>(id: number, data: T): Observable<boolean> {
        return this.http.post<{ data: boolean}>(`${this.settings.API_PATH}/${this.edge}/change-pass/${id}`, data).pipe(
            map(({ data }) => data)
        );
    }

    // noinspection JSMethodCanBeStatic
    protected indexParameterizer(params: IWhmAccountIndexParams): HttpParams {
        let result = new HttpParams();

        result = result.append('uid', <string><any>(params.uid));

        result = params.page ? result.append('page[number]',  <string><any>(params.page)) : result;
        result = params.size ? result.append('page[size]',  <string><any>(params.size)) : result;
        result = params.sort ? result.append('sort', this.getSortableField(params.sort)) : result;
        result = params.order ? result.append('order', params.order) : result;
        result = params.show_all ? result.append('show_all',  <string><any>(+params.show_all)) : result;
        result = params.customer_id ? result.append('customer_id',  <string><any>(params.customer_id)) : result;
        result = params.status ? result.append('status',  <string><any>(params.status)) : result;
        result = params['service'] ? result.append('service',  <string><any>(params['service'])) : result;
        result = params.customer_id ? result.append('current_customer_id',  <string><any>(params.customer_id)) : result;
        result = params.source_id ? result.append('source_id',  <string><any>(params.source_id)) : result;

        if (params.search) {
            for (let prop in params.search) {
                // noinspection JSUnfilteredForInLoop
                let value = isBoolean(params.search[prop]) ? +params.search[prop] : params.search[prop];
                if(!isNil(value) && value !== ''){
                    result = result.append('search[' + prop + ']', value.toString() );
                }
            }
        }

        return result;
    }

    // noinspection JSMethodCanBeStatic
    private getSortableField(field: string): string {
        switch (field) {
            case 'updated':
                return 'updated_at';

            case 'created':
                return 'created_at';

            case 'deleted':
                return 'deleted_at';

            default:
                return field;
        }
    }
}
