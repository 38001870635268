<ng-container *ngIf="userCustomersFlag">
    <div class="row mb-3">
        <div class="col-12 text-center">
            Please select the desired Customer.
        </div>
    </div>

    <div class="list-group" id="list-tab" role="tablist">
        <a *ngFor="let uc of userCustomers"
           class="list-group-item list-group-item-action"
           data-toggle="list"
           href="#"
           (click)="chooseCustomer($event, uc)">
            {{uc.number}} - {{uc.name}}
        </a>
    </div>
</ng-container>

<ng-container *ngIf="userNewCustomerFlag">
    <div class="row mb-3">
        <div class="col-12 text-center">
            Your User is not assigned to any Customer. Please create a new Customer.
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!userCustomersFlag">

    <ng-container *ngIf="registerAction">
        <ng-container *ngIf="!userNewCustomerFlag">
            <div class="row mb-2">
                <div class="col-12 text-right">
                    <a class="text-primary" (click)="switchRegister()" href="#">{{'Already a customer? Please, login' | translate }}</a>
                </div>
            </div>
        </ng-container>

        <app-customer-register-form></app-customer-register-form>
    </ng-container>

    <ng-container *ngIf="!registerAction">
        <ng-container *ngIf="!userNewCustomerFlag">
            <div class="row mb-2">
                <div class="col-12 text-right">
                    <a class="text-primary" (click)="switchRegister()" href="#">{{'Not a Customer yet? Please, register' | translate }}</a>
                </div>
            </div>
        </ng-container>

        <app-login-form></app-login-form>
    </ng-container>

</ng-container>