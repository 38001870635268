import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'bytes',
})

export class BytesPipe implements PipeTransform {

    transform(bytes) {
        const sizes = ["bytes", "KB", "MB", "GB", "TB"];

        if (bytes == 0) {
            return "0 bytes";
        }

        const i = Math.floor(Math.log(bytes) / Math.log(1024));

        if (i == 0) {
            return bytes + " " + sizes[i];
        }

        return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
    }

}