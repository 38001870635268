import { Permission, Role } from '@appRoot/core/roles-permissions/models';
import { Action } from '@ngrx/store';
import { User, UserAccess } from '../../models';


export enum ActionTypes {

    GET_USER_PERMISSIONS = '[Authorization] get user permissions',
    GET_USER_PERMISSIONS_SUCCESS = '[Authorization] get user permissions success',

    GET_ACTIVE_USER_PERMISSIONS = '[Authorization] get ACTIVE user permissions',
    GET_ACTIVE_USER_PERMISSIONS_SUCCESS = '[Authorization] get ACTIVE user permissions success',

    UPDATE_USER_PERMISSIONS = '[Authorization] update user permissions',
    UPDATE_USER_PERMISSIONS_SUCCESS = '[Authorization] update user permissions success',

    UPDATE_USER_ROLES = '[Authorization] update user roles',
    UPDATE_USER_ROLES_SUCCESS = '[Authorization] update user roles success',

    ERROR = '[Authorization] error',
    ERROR_RESET = '[Authorization] error reset',
    RESET = '[Authorization] reset',

    UPSERT_ONE = '[Authorization] upsertOne',
    UPSERT_MANY = '[Authorization] upsertMany',
    REMOVE_MANY = '[Authorization] removeMany',
}

export class GetUserPermissions implements Action {
    readonly type = ActionTypes.GET_USER_PERMISSIONS;
    constructor(readonly payload: {user: User}) { }
}
export class GetUserPermissionsSuccess implements Action {
    readonly type = ActionTypes.GET_USER_PERMISSIONS_SUCCESS;
    constructor(readonly payload: {user: User; permissions: Permission[]}) { }
}
export class GetActiveUserPermissions implements Action {
    readonly type = ActionTypes.GET_ACTIVE_USER_PERMISSIONS;
}
export class GetActiveUserPermissionsSuccess implements Action {
    readonly type = ActionTypes.GET_ACTIVE_USER_PERMISSIONS_SUCCESS;
    constructor(readonly payload: {user: User; permissions: Permission[]}) { }
}

export class UpdateUserPermissions implements Action {
    readonly type = ActionTypes.UPDATE_USER_PERMISSIONS;
    constructor(readonly payload: {user: User; permissions: Permission[]}) { }
}
export class UpdateUserPermissionsSuccess implements Action {
    readonly type = ActionTypes.UPDATE_USER_PERMISSIONS_SUCCESS;
    constructor(readonly payload: {user: User; permissions: Permission[]}) { }
}

export class UpdateUserRoles implements Action {
    readonly type = ActionTypes.UPDATE_USER_ROLES;
    constructor(readonly payload: {user: User; roles: Role[]}) { }
}
export class UpdateUserRolesSuccess implements Action {
    readonly type = ActionTypes.UPDATE_USER_ROLES_SUCCESS;
    constructor(readonly payload: {user: User; roles: Role[]}) { }
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;
    constructor(readonly payload: {error: any, action: Actions}) { }
}
export class ErrorReset implements Action {
    readonly type = ActionTypes.ERROR_RESET;
}

export class Reset implements Action {
    readonly type = ActionTypes.RESET;
}

export class UpsertOne implements Action {
    readonly type = ActionTypes.UPSERT_ONE;
    constructor(readonly payload: UserAccess) {}
}

export class UpsertMany implements Action {
    readonly type = ActionTypes.UPSERT_MANY;
    constructor(readonly payload: UserAccess[]) {}
}

export class RemoveMany implements Action {
    readonly type = ActionTypes.REMOVE_MANY;
    constructor(readonly payload: number[]) {}
}

export type Actions =
    | GetUserPermissions
    | GetUserPermissionsSuccess
    | Error
    | ErrorReset
    | Reset
    | UpdateUserPermissions
    | UpdateUserPermissionsSuccess
    | UpdateUserRoles
    | UpdateUserRolesSuccess
    | GetActiveUserPermissions
    | GetActiveUserPermissionsSuccess
    | UpsertOne
    | UpsertMany
    | RemoveMany
    ;
