<div *ngIf="(user$ | async) as user" class="user-short-info alert alert-light border">

    <div class="item row">
        <span class="col-auto">Name:</span>
        <span class="col-auto">{{ user.name }}</span>
    </div>

    <div class="item row" *ngIf="user.email">
        <span class="col-auto " >Email:</span>
        <span class="col-auto">
            {{ user.email }}
        </span>
    </div>

</div>
