import { NgModule } from '@angular/core';
import { CustomerHttpService } from './services/customer-http.service';
import { CustomerContactsHttpService } from './services/customer-contacts-http.service';
import { CustomerGroupsHttpService } from './services/customer-groups-http.service';
import { CustomerService } from './services/customer.service';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducers } from './ngrx-store/reducers';
import { effects } from './ngrx-store/effects';
import { CustomerMailTemplatesHttpService } from './services/customer-mail-templates-http.service';
import { CustomerExpenseSettingsHttpService } from './services/customer-expense-settings-http.service';


@NgModule({
	declarations: [],
	imports: [
        EffectsModule.forFeature(effects),
        StoreModule.forFeature('Customer', reducers),
	],
	providers: [
		CustomerHttpService,
		CustomerContactsHttpService,
		CustomerGroupsHttpService,
		CustomerService,
		CustomerMailTemplatesHttpService,
		CustomerExpenseSettingsHttpService
	]
})
export class CustomerModule { }
